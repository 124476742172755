//================================================================
//  Component: RatingsDetail
//================================================================

//  Purpose: This component shows the rating and progress bar for a product

//  Properties:
//    - productid = {string - contains the productid}
//    - subscribeStatus - = {string, that holds the users subscription status}  

//  Example:
//    <RatingsDetail
//      productid="string"
//    ></RatingsDetail>    

//================================================================

//Libraries
import React, { useState, useContext, useEffect } from "react";

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts';

//Functions
import CollectionListener from '../../Library/CollectionListener';
import WriteDocument from '../../Library/WriteDocument';
import UsageBar from "../UsageBar/UsageBar";

//Images
import ExpandIcon from '../../Components/Images/Icon_Arrow_Down.svg'; 

//CSS
import './RatingsDetail.css'


export default function RatingsDetail({productid, subscribeStatus}) {
  
  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
  
    //stores the user's rating
    const [userRating, setUserRating] = useState(0);

    //Temporary use state that stores the value of the number of stars the user has hovered on
    const [hover, setHover] = useState(0);

    //This calculates the average rating for a product
    const [averageRating, setAverageRating] = useState(0);

    //This calculates the total sum of rating for product
    const [totalRatings, setTotalRatings] = useState(0);

    //Stores the value of the number of stars the user has clicked on
    const [oneRating, setOneRating] = useState(0);
    const [twoRating, setTwoRating] = useState(0);
    const [threeRating, setThreeRating] = useState(0);
    const [fourRating, setFourRating] = useState(0);
    const [fiveRating, setFiveRating] = useState(0);

    //minimise/expand progress bar
    const [expanded, setExpanded] = useState(false);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //useEffect is triggered everytime user rating changes > Writes rating to Firestore
    useEffect(() => {
      
      //If the user rating is undefined or equal to 0 > Don't write user rating to Firestore
      if(userRating === undefined || userRating === 0) return; 
      if(getUser === undefined) return; 
      if(productid === undefined) return;
      
      //If product does exist, create a temporary template "document"
      const document = {
        'date': new Date(),
        'emailaddress': getUser?.emailaddress,
        'rating': userRating
      }
      
      WriteDocument(`productratings/${productid}/ratings`, getUser?.emailaddress, document, true)
      .catch((error) => {

        console.log(`Failed to update users rating, error: ${error}`);
        setError(`Failed to update users rating, error: ${error}`);

      });

    // eslint-disable-next-line
    }, [userRating])
    
      
    //Collection Listener - Every time the 'productratings' collection changes > Reload the page with the latest metrics 
    useEffect(() => {

      //Conditions
      if (getUser === undefined) return;
      if (productid === undefined) return;

      //This runs when the page is first loaded
      function onLoadChange(documents) {

        //If the rating is 0 (unrated) skip over
        if (documents.length === 0) return;

        //Find the number of people who have rated in each StarRating (e.g. 1,2,3,4,5)
        const oneStarRating = documents.filter(object => object.rating === 1).length
        const twoStarRating = documents.filter(object => object.rating === 2).length
        const threeStarRating = documents.filter(object => object.rating === 3).length
        const fourStarRating = documents.filter(object => object.rating === 4).length
        const fiveStarRating = documents.filter(object => object.rating === 5).length

        //save ratings to useStates
        //For Progress bar * 100
        setOneRating((oneStarRating / documents.length) * 100);
        setTwoRating((twoStarRating / documents.length) * 100);
        setThreeRating((threeStarRating / documents.length) * 100);
        setFourRating((fourStarRating / documents.length) * 100);
        setFiveRating((fiveStarRating / documents.length) * 100);

        //Find the users rating and update
        setUserRating(
          documents.filter(object => object.emailaddress === getUser.emailaddress)[0]?.rating
        );

        //Calculate Average Rating
        //Count the amount of objects in each StarRating array(length), multiple by the StarRating (e.g. 5), add each starRating array and divide by total amount of ratings received
        const ratingCalculation = ((fiveStarRating * 5) + (fourStarRating * 4) + (threeStarRating * 3) + (twoStarRating * 2) + oneStarRating) / documents.length
        setAverageRating(ratingCalculation)

        //Save the number of customers who have rated a product
        const totalRatingsCalculation = documents.length
        setTotalRatings(totalRatingsCalculation);
      }

      //Listens to a collection for any new changes, errors will not be captured
      const unsubscribe = CollectionListener(`productratings/${productid}/ratings`, onLoadChange, onLoadChange);

      return () => {

        unsubscribe();

      }

    }, [productid, getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className="RatingsDetail-Container">
      
      <div className="RatingsDetail-ClickToRate">
      {/*If expanded is true show progress bar */}
        {expanded &&         
          <div className="RatingsProgressBar-Container">

            {/* Ratings Progress Bar */}
            <div className="RatingsProgressBar">
              <p>5</p>
              <UsageBar bgcolor="#76DC99" progress={fiveRating} hidden={false}/>
            </div>

            <div className="RatingsProgressBar">
              <p>4</p>
              <UsageBar bgcolor="#B7EA83" progress={fourRating} hidden={false}/>
            </div>

            <div className="RatingsProgressBar">
              <p>3</p>
              <UsageBar bgcolor="#F6D757" progress={threeRating} hidden={false}/>
            </div>

            <div className="RatingsProgressBar">
              <p>2</p>
              <UsageBar bgcolor="#FBB851" progress={twoRating} hidden={false}/>
            </div>
        
            <div className="RatingsProgressBar">
              <p>1</p>
              <UsageBar bgcolor="#F17A55" progress={oneRating} hidden={false}/>        
            </div>
        
          </div>        
        }
        {/* only allow subscribers to rate a product */}
        {subscribeStatus === "active" ? 
        (
          <div>
            <p>Click to Rate:</p>

            {/* 5 Star Rating Component */}
            <div className="StarRating">
              {/* Output 5 stars by mapping over an array */}
              {[...Array(5)].map((star, index) => {
              index += 1; //Start the array from 1 (user's can only rate product from 1-5)
              return (
                <button
                  type="button"
                  key={index} //Store the value(index) of the star clicked of either "on" or "off"
                  className={index <= (hover || userRating) ? "On" : "Off"} 
                  onClick={() => setUserRating(index)} //When a star is clicked on, set the rating
                  onMouseEnter={() => setHover(index)} //Hover effect that indicates the rating that will be selected onClick
                  onMouseLeave={() => setHover(userRating)}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
              
            </div>
          </div>

        // If the user is not subscribed to product, hide the ability to rate
        )
        : null     
        }

      </div>

      {/* Average Rating and Number of Reviews */}
      <div className="RatingsDetail-AverageRating">   
        <>{averageRating}&nbsp;
            {/* if number of reviews > 1, Write "Reviews". If <1, Write "Review"*/}             
            {totalRatings > 1 ? 
            (
              <a1>({totalRatings} Reviews)</a1>
            )
            : <a1>({totalRatings} Review)</a1>}
        </>
      </div>

      {/* Minimise/Expand Ratings Progress Bar */}
      <div className="RatingsCard-Expanded">
        <img 
          className="Expand-Icon" 
          src={ExpandIcon} 
          // if expanded is true, rotate. Reverse what it is currently doing. E.g. expanded, when clicked on I want it to be minimised
          style={expanded ? ({transform: "rotate(180deg)"}) : null}
          alt="Expand_Icon" 
          onClick={() => setExpanded(!expanded)}
        ></img>
      </div>
    </div>
  )
}


