//------------------------------------------------------
//  Financial Statistics page - tab content
//------------------------------------------------------

//Libraries
import React, {useState, useEffect, useContext, useReducer} from 'react'

//Contexts
import {GetUser, SetError} from '../../../Library/GlobalContexts'

//Functions
import DropDown from '../../../Components/Dropdown/Dropdown';
import QueryDocument from '../../../Library/QueryDocument'
import GetDocument from '../../../Library/GetDocument'
import ProductPane from './ProductPane';

//CSS
import '../FinancialStatistics.css';

export default function FrequencyTab(props) {

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------

  //Frequency for this tab, either 'Monthly' or 'Yearly'
  const frequency = props.frequency
  //Used to update request type if there's a fatal error
  const setParentRequestType = props.setRequestType

  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------
    
  //State used to determine current view on this page > "pending" or "onload"
  const [requestType, setRequestType] = useState('pending');

  //Valid invoice periods
  const [invoicePeriods, setInvoicePeriods] = useState([]);

  //Currently selected period
  const [selectedPeriod, setSelectedPeriod] = useState();

  //List of products
  const [products, setProducts] = useState([]);

  //Summary and subscription level stats
  const [stats, setStats] = useState();

  //Indicates whether a product has been expanded to show subscriptions
  const [rowExpanded, setRowExpanded] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {}
  );

  //Text month to numerical date converter
  const monthConv = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const setError = useContext(SetError);
  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  >>> convertPeriodDate - Convert periodStart and periodEnd from invoice
  //      Outputs date displayname and a sortable version for periodStart
  //------------------------------------------------------

    function convertPeriodDate(invoiceDate) {
      const dateComponents = invoiceDate.split('-')
      return [
        //Display version
        `${dateComponents[0]} ${dateComponents[1]} ${dateComponents[2]}`,
        //Sortable version
        `${dateComponents[2]}${monthConv.indexOf(dateComponents[1])}${dateComponents[0]}`
      ]
    }

  //------------------------------------------------------
  //  >>> getProducts - Get all products the publisher owns
  //------------------------------------------------------

  function getProducts(){

    //Get product documents that publisher owns, which match the billing frequency
    QueryDocument('products', 
      [
        ['publisher', 'array-contains', getUser?.emailaddress],
        ['status', 'in', ['published', 'hidden', 'deleted']],
        ['pricingfrequency', '==', frequency]
      ],
      ['name', 'asc']
    )

    //Success > Save list of products
    .then((results) =>{

      //Save results
      setProducts(results)
      return
    })

    //Error > Show error page
    .catch((error) =>{
      //Submit error to global store > This will be captured by ErrorHandler
      setError(`QueryDocument for products has failed, error: ${error}`)

      //Call failed, so generate error page
      setParentRequestType('error-fatal')
      return
    })
  }

  //------------------------------------------------------
  //  >>> getPeriods - Get valid invoice periods
  //------------------------------------------------------

  function getPeriods(){

    GetDocument('invoices', 'invoicePeriods')

    //Success > Save invoice periods
    .then((results) =>{
      const periodsTemp = results[frequency]
      
      //Sort by oldest to newest
      periodsTemp.sort((a, b) => (a.period > b.period) ? 1 : -1)

      //Save results
      setInvoicePeriods(periodsTemp)

      //Select latest invoice period as current period if not already set
      if (selectedPeriod === undefined) {
        setSelectedPeriod(periodsTemp[periodsTemp.length - 1])
      }

      return
    })

    //Error > Show error page
    .catch((error) =>{
      //Submit error to global store > This will be captured by ErrorHandler
      setError(`GetDocument for invoicePeriods has failed, error: ${error}`)

      //Call failed, so generate error page
      setParentRequestType('error-fatal')
      return
    })
  }
  
  //------------------------------------------------------
  //  >>> getStats - Pull financial stats for each product
  //------------------------------------------------------

  async function getStats(){

    let statsTemp = {} //Temporarily hold stats
    let statsPromises = [] //Holds promises
    let rowExpandedTemp = {} //Temporarily hides all subscription stats

    //Get stats for each product
    for (let product of products) {
      const productid = product.productid

      //By default, collapse all products to hide subscriptions
      rowExpandedTemp[productid] = false
      
      //Start with summary stats
      statsPromises.push(GetDocument(`invoices/productSummary/${selectedPeriod.period}`, productid)

      //Success > Temporarily save summary stats
      .then((productStats) =>{
        productStats.productid = productid
        productStats.subscriptionStats = []
        statsTemp[productid] = (productStats)
      })
      
      .catch((error) =>{
        //No catch, as we'll just skip over the product if there are no stats
      }))
    }

    setRowExpanded(rowExpandedTemp)

    //Make sure summary stats have been retrieved before proceeding
    await Promise.all(statsPromises)

    //Get subscription level stats for each product that has summary stats
    statsPromises = []

    for (let productid in statsTemp) {
      //Retrieve invoice info for relevant months based on product summary
      for (let monthDoc of statsTemp[productid].months) {

        statsPromises.push(QueryDocument(`invoices/lendleaseMarketplace/${monthDoc}`, 
          [
            ['productId', '==', productid]
          ]
        )

        .then((subscriptionResults) =>{

          //Convert periodStart and periodEnd dates to something more readable
          //Then save results to stats object
          for (let i in subscriptionResults) {
            let convertedDate = convertPeriodDate(subscriptionResults[i].periodStart)
            subscriptionResults[i].periodStart = convertedDate[0]
            subscriptionResults[i].datesort = convertedDate[1]
            convertedDate = convertPeriodDate(subscriptionResults[i].periodEnd)
            subscriptionResults[i].periodEnd = convertedDate[0]
            statsTemp[productid].subscriptionStats.push(subscriptionResults[i])
          }
        })

        .catch((error) =>{
          //No catch, as we'll just skip over the product if there are no stats
        }))
      }
    }

    //Make sure subscription stats have been retrieved before proceeding
    await Promise.all(statsPromises)

    //Sort subscriptions by invoice period, then subscriber name
    for (let productid in statsTemp) {
      statsTemp[productid].subscriptionStats.sort((a, b) =>
        (a.datesort.localeCompare(b.datesort) ||
        a.projectOwnerLastName.localeCompare(b.projectOwnerLastName) ||
        a.projectOwnerFirstName.localeCompare(b.projectOwnerFirstName)))
    }

    //Save stats
    setStats(statsTemp)
    return
  }

  //------------------------------------------------------
  //  >>> onPeriodChange - updates invoice period
  //      based on user selection in dropdown
  //------------------------------------------------------

  function onPeriodChange(e){
    setRequestType('pending')
    setSelectedPeriod(invoicePeriods[+e.target.value])
  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
    
  //On first load, get products
  useEffect(() => {

    //Check user exists
    if(getUser === undefined) return;

    //Get list of products
    getProducts()

    // eslint-disable-next-line
  },[getUser])

  //Frequency change or a request type change > get invoice periods
  useEffect(() => {

    //Get list of valid invoice periods for the frequency
    //Plus set the current invoice period to the last month/year if not set
    getPeriods()

  // eslint-disable-next-line
  },[frequency, requestType])

  //Invoice period change or products loaded > get stats
  useEffect(() => {

    //Check user exists and we have a valid invoice period
    //Initially the list of products might be empty ... that's OK
    if(getUser === undefined || selectedPeriod === undefined) return;

    //Get stats for the selected invoice period
    getStats()

  // eslint-disable-next-line 
  },[selectedPeriod, products])

  //Stats loaded > display page
  useEffect(() => {
    if (stats !== undefined) {
      setRequestType('onload')
    }
  },[stats])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='FinancialStatistics-Body'>
      <div className='FinancialStatistics-DropdownRow'>
        {/* Header for invoice period */}
        <div className='Subheading'>
          {selectedPeriod?.displayname}
        </div>
        
        {/* Dropdown to select invoice period */}
        <DropDown
          label='Invoice period:'
          items={invoicePeriods}
          displayProp='displayname'
          defaultValue={selectedPeriod}
          onChangeFunction={onPeriodChange}
          width='250px'
        ></DropDown>
      </div>

      {
        /* If stats aren't ready yet, show loading text */
        requestType === 'pending' ? (
          <div>
            Loading...
          </div>
        ) : (
          <div className='FinancialStatistics-Body'>

            {
              stats === undefined || Object.keys(stats).length === 0 ? (
                /* This invoice period has no stats at all */
                <div>
                  No financial statistics for this invoice period
                </div>
              ) : (
                /* Show stats for each product */
                products.map((product) => 
                  stats[product.productid] === undefined ? (
                    null
                  ) : (
                    <ProductPane
                      frequency={frequency}
                      product={product}
                      stats={stats}
                      rowExpanded={rowExpanded}
                      setRowExpanded={setRowExpanded}
                    ></ProductPane>                  
                  )
                )
              )
            }
          </div>
        )
      }
    </div>
  )
}