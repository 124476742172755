//------------------------------------------------------
//  Publish a Product Page
//------------------------------------------------------

//Libraries
import React, { useState, useEffect, useContext } from 'react'

//Contexts
import {GetUser, SetError} from '../../Library/GlobalContexts'

//Functions
import QueryDocument from '../../Library/QueryDocument'

//Components
import PageResponse from '../../Components/PageResponse/PageResponse.js'
import ProductCard from '../../Components/ProductCard/ProductCard.js'
import PageHeader from '../../Components/PageHeader/PageHeader'

//Styles
import './MyPublishedProducts.css'

export default function MyPublishedProducts() {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
    
    //State used to determine current view on this page > "pending", "onload", "view", "create"
    const [requestType, setRequestType] = useState("pending")

    //State used to store list of all products the user owns
    const [products, setProducts] = useState([]);    
    
    //Products available due to admin access only
    const [productsAdmin, setProductsAdmin] = useState([]);    

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
    
    //When requestType changes > get list of all products
    useEffect(() => {

      //Check user exists > load the page
      if(getUser === undefined) return;

      //Only proceed if page is pending
      if(requestType !== 'pending') return;

      //Get all products I own regardless of status
      QueryDocument('products', [
        ['publisher', 'array-contains', getUser?.emailaddress],
        ['status', 'in', ['published', 'hidden', 'deleted']]
      ])

      //Success
      .then((resultsOwner) =>{

        setProducts(resultsOwner)

        //Get all products as I'm an admin
        if (getUser?.roles?.isAdmin === true) {

          QueryDocument('products', [
            ['status', 'in', ['published', 'hidden', 'deleted']]
          ])

          //Filter products which I'm not an owner for > Load the page
          .then((results) =>{
            setProductsAdmin(results.filter(prod1 => {return !resultsOwner.some(prod2 => {return prod1.productid === prod2.productid})}))
            setRequestType('onload')

          })

          //Error > show error page
          .catch((error) =>{

            setRequestType('error-fatal')

            //Submit error to global store > This will be captured by ErrorHandler
            setError(`GetCollection for products has failed, error: ${error}`)

          })
        
        // Not an admin, so just display products I own
        } else {

          setRequestType('onload')
        
        }

      })

      //Error > show error page
      .catch((error) =>{

        setRequestType('error-fatal')

        //Submit error to global store > This will be captured by ErrorHandler
        setError(`GetCollection for products has failed, error: ${error}`)

      })
      
    // eslint-disable-next-line
    },[requestType, getUser])


  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageHeader={
        <PageHeader
          title={"My Published Products"}
        ></PageHeader>
      }
      pageOnload={
        <div>
            {products.length > 0 || productsAdmin.length > 0 ? 
              (
                //If products found > return list of products
                <div className='Products-Catalogue-Container'>
                  {products.map((object, index) => (
                    <ProductCard
                      key={index}
                      product={object}
                      isPublisher={true}
                    ></ProductCard>
                  ))}
                  {productsAdmin.map((object, index) => (
                    <ProductCard
                      key={index}
                      product={object}
                      isAdmin={true}
                    ></ProductCard>
                  ))}
                </div>
              )
              : 
              (
                // Else > return message
                <div style={{margin: '2%'}}>
                  No products published by {getUser?.emailaddress} was found.
                </div>
              )
            }
        </div>
      }
    ></PageResponse>
  )
  //------------------------------------------------------
}