//================================================================
//  Edit a Product Form
//================================================================

//Libraries
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Form, InputGroup, FormControl, Modal } from 'react-bootstrap';

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts'

//Functions
import BatchWriteDocument from '../../Library/BatchWriteDocument';
import writeDocument from '../../Library/WriteDocument';
import QueryDocument from '../../Library/QueryDocument';
import DocumentListener from '../../Library/DocumentListener';

import {
    reStringAlphaNumSpaceHyphenSlashPara,
    errStringAlphaNumSpaceHyphenSlashPara,
    reString,
    errString,
    isStringEmail,
    reStringUnsignedInteger,
    isString,
    reStringEmail
} from '../../Library/ValidateUserInput';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import QuestionLabel from '../../Components/QuestionLabel/QuestionLabel';
import UploadIcon from '../../Components/UploadIcon/UploadIcon';
import MultiLineDot from '../../Components/MultiLineDot/MultiLineDot';
import RichTextArea from '../../Components/RichTextArea/RichTextArea';
import ProductPricing from './Components/ProductPricing';
import NotificationLabel from '../../Components/NotificationLabel/NotificationLabel';
import ProductAvailability from './Components/ProductAvailability';

//Images 
import AddButton from '../../Components/Images/Icon_Add_Teal.svg';
import AddButtonDisabled from '../../Components/Images/Icon_Add_Grey.svg';
import DeleteIcon from '../../Components/Images/Icon_Delete_Teal.svg';
import DeleteIconDisabled from '../../Components/Images/Icon_Delete_Grey.svg';
import PricingChangeGreen from '../../Components/Images/Icon_PricingChange_Green.svg';
import PricingChangeGray from '../../Components/Images/Icon_PricingChange_Gray.svg';

//CSS
import './ProductsEdit.css';


export default function ProductsEdit({
    isOwner,
    setIsOwner
}) {

    //------------------------------------------------------
    //  UseParams from 'react-router'
    //------------------------------------------------------

    const [searchParams] = useSearchParams();

    //------------------------------------------------------
    //  useContexts & Functions
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

    //------------------------------------------------------
    //  useStates & global variables
    //------------------------------------------------------

    //Page status > 'pending', onload', 'view', 'error-fatal'
    const [requestType, setRequestType] = useState('pending');

    //Product ID > Needs to remain consistent and never changes
    const [productId, setProductId] = useState();
    const [productObject, setProductObject] = useState();

    //Icon Name and URL
    const [iconUrl, setIconUrl] = useState();

    //Holds the array of product owners
    const [productOwnerEmail, setProductOwnerEmail] = useState("");
    const [productOwners, setProductOwners] = useState([getUser?.emailaddress]);
    const [origProductOwners, setOrigProductOwners] = useState([getUser?.emailaddress]);

    const [productOwnerNotAdded, setProductOwnerNotAdded] = useState(false)

    // States for email validation > 'notValid', 'existingUser', 'valid', 'notLendleaseEmail'
    const [emailValidated, setEmailValidated] = useState('notValid');

    //Holds the array of users who can subscribe to a private product
    const [privateUsers, setPrivateUsers] = useState([]);
    const [privateUserEmail, setPrivateUserEmail] = useState("");
    const [privateUserNotAdded, setPrivateUserNotAdded] = useState(false)


    //Holds the nominated mail address for notifications
    const [nominatedMailAddress, setNominatedMailAddress] = useState("");

    //Refs for text area validation
    const subNextStepsRef = useRef(null);
    const unsubNextStepsRef = useRef(null);
    const supportNextStepsRef = useRef(null);
    const subscriberTermsRef = useRef(null);

    //Text area values
    const [subNextSteps, setSubNextSteps] = useState("");
    const [unsubNextSteps, setUnsubNextSteps] = useState("");
    const [supportNextSteps, setSupportNextSteps] = useState("");
    const [subscriberTerms, setSubscriberTerms] = useState("");

    //Assigned Licenses > number of licenses assigned to users for this product
    const [assignedLicenses, setAssignedLicenses] = useState(0);

    //Hold array > How do customers get support?
    const [searchTerms, setSearchTerms] = useState([]);

    // Overview
    const [overview, setOverview] = useState();

    //Used to determine if product owner/nominated mail address error messages are visible
    const [productOwnerErrorHidden, setProductOwnerErrorHidden] = useState(true);
    const [nominatedMailAddressErrorHidden, setNominatedMailAddressErrorHidden] = useState(true);

    // Show the pricing changed section
    const [pricingChange, setPricingChange] = useState(false);

    //Records if the requester is an admin
    const [isAdmin, setIsAdmin] = useState(false)

    // Define what component(s) or function(s) that can raise errors in the submit modal
    const [submitState, setSubmitState] = useState({
        'validated': false,
        'preventSubmit': false,
        'modalVisible': false,
        'firebaseError': undefined,
        'productPricing': undefined,
        'event': undefined,
    });


    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function to add a product owner to the list of product owners
    function addProductOwner(owner) {

        //Validate email address
        owner = owner.toLowerCase()

        if (isStringEmail(owner) && owner.includes('@lendlease.com')) {
            setProductOwnerErrorHidden(true)
        } else {
            setProductOwnerErrorHidden(false)
            return
        };

        // Add to list
        setProductOwners(productOwners => [...productOwners, owner]);

        //Clear input field
        setProductOwnerEmail("");

        setProductOwnerNotAdded(false)
        setEmailValidated("notValid")

    }

    function validateProductOwner(e) {

        e = e.toLowerCase()

        if (productOwners?.includes(e)) {
            setEmailValidated('existingUser')
        } else if (isStringEmail(e) && e.endsWith('@lendlease.com') && (!productOwners?.includes(e))) {
            setEmailValidated('valid')
        } else if (e.endsWith('.com') && !e.endsWith('@lendlease.com')) {
            setEmailValidated('notLendleaseEmail')
        } else {
            setEmailValidated('notValid')
        }

        setProductOwnerNotAdded(false)
    }

    function CheckBoxHandler(value) {

        if (value === 'hidden') {

            return true;

        } else if (value === 'published') {

            return false;

        } else {

            return false;
        }
    }

    //Event handler for field changes to nominated mail address
    function handleNominatedMailAddressChange(input) {

        //Validate the nominated address is not a publisher/product owner address > Show error
        if (productOwners?.filter((ownerEmail) => ownerEmail === input)?.length > 0 ||
            (input.length > 0 && !isStringEmail(input))) {
            setNominatedMailAddressErrorHidden(false)

        } else {
            setNominatedMailAddressErrorHidden(true)

        }

        //Set State
        setNominatedMailAddress(input)

    }

    //Event handler submission validation for text area field changes
    function handleTextAreaChange(input, setValue, ref) {

        //Validate the text area
        if (isString(input)) {
            setValue(input)
            ref.current.setCustomValidity('')
            return false

            // Failed validation, so tag as error and prevent submission
        } else {
            setValue(input)
            ref.current.setCustomValidity('Invalid')
            return true
        }
    }


    //------------------------------------------------------
    //  Event Handler (Form Submission)
    //------------------------------------------------------

    //Submit Form > Write to Firestore
    function handleFormSubmit(e) {

        //Prevent reload, propagation & disable enter key
        e.preventDefault();
        e.stopPropagation();
        if (e.keyCode === 13) {

            return;

        }

        //Create flag for preventing submission until form validated
        let preventSubmit = false;

        if (productOwnerEmail !== "") {
            setProductOwnerNotAdded(true)
            return;
        } else {
            setProductOwnerNotAdded(false)
        }

        if (privateUserEmail !== "") {
            setPrivateUserNotAdded(true)
            return;
        } else {
            setPrivateUserNotAdded(false)
        }

        //Capture submission event
        const event = e.target;

        //Check the nominated mail address is not one of the product owners && nominated mail address given when multiple owners listed 
        if (productOwners?.filter((ownerEmail) => ownerEmail === nominatedMailAddress)?.length > 0 ||
            (productOwners?.length > 1 && nominatedMailAddress?.length === 0) ||
            (nominatedMailAddress?.length > 0 && !isStringEmail(nominatedMailAddress))) {

            setNominatedMailAddressErrorHidden(false);
            preventSubmit = true;

        }

        // Check text area fields
        if (handleTextAreaChange(subNextSteps, setSubNextSteps, subNextStepsRef)) { preventSubmit = true }
        if (handleTextAreaChange(unsubNextSteps, setUnsubNextSteps, unsubNextStepsRef)) { preventSubmit = true }
        if (handleTextAreaChange(supportNextSteps, setSupportNextSteps, supportNextStepsRef)) { preventSubmit = true }
        if (handleTextAreaChange(subscriberTerms, setSubscriberTerms, subscriberTermsRef)) { preventSubmit = true }

        //Validate form inputs > If invalid -> prevent submission
        if (event.checkValidity() === false) {

            submitState.validated = true;
            setSubmitState({ ...submitState });
            return;
        }

        if (submitState.preventSubmit === true) {

            submitState.modalVisible = true;
            preventSubmit = true;

        }

        // Update 'submitState'
        submitState.validated = true;
        submitState.event = e;
        setSubmitState({ ...submitState });

        // Prevent submit of form
        if (preventSubmit) return;

        setNominatedMailAddressErrorHidden(true);

        // If Pricing Changes > Handle results
        let pricingChangedObject;
        if (pricingChange === true) {

            pricingChangedObject = {
                'effectivedate': event.pricingchangedeffectivedate.value,
                'notifycustomer': event.pricingchangednotifycustomer.value,
                'pricingdiscount': event.pricingchangedpricingdiscount.value,
                'pricingamount': event.pricingchangedpricingamount.value,
                'projectcode': event.pricingchangedprojectcode.value,
                'pricingcurrency': event.pricingchangedpricingcurrency.value,
                'tasknumber': event.pricingchangedtasknumber.value,
                'expendituretype': event.pricingchangedexpendituretype.value,
            }

        } else {

            pricingChangedObject = {
                'effectivedate': 'n/a',
                'notifycustomer': '',
                'pricingdiscount': '',
                'pricingamount': '',
                'projectcode': '',
                'pricingcurrency': '',
                'tasknumber': '',
                'expendituretype': '',
            }

        }

        //Handle field: status
        let status = 'published'
        if (event.status.checked) {
            status = 'hidden'
        }

        //Create product document for update
        const document = {
            'productid': productId,
            'publisher': productOwners,
            'publisherstandardsaccepted': productObject?.publisherstandardsaccepted,
            'iconurl': iconUrl,
            'name': event.productname.value,
            'description': event.description.value,
            'overview': overview,
            'nominatedmailaddress': nominatedMailAddress,
            'pricingdiscount': event.pricingdiscount.value,
            'pricingamount': event.pricingamount.value,
            'pricingcurrency': event.pricingcurrency.value,
            'projectcode': event.projectcode.value,
            'tasknumber': event.tasknumber.value,
            'expendituretype': event.expendituretype.value,
            'pricingchanged': pricingChangedObject,
            'totallicenses': event.totallicenses.value?.length > 0 ? Number(event.totallicenses.value) : 0,
            'alertthreshold': Number(event.alertthreshold.value),
            'subnextsteps': event.subnextsteps.value,
            'unsubnextsteps': event.unsubnextsteps.value,
            'supportlink': event.supportlink.value,
            'supportemail': event.supportemail.value,
            'supportnextsteps': event.supportnextsteps.value,
            'subscriberterms': event.subscriberterms.value,
            'labels': searchTerms,
            'producturl': event.producturl.value,
            'createdate': new Date(),
            'edithistory': {
                'userid': getUser.userid,
                'lastmodified': new Date(),
            },
            'privateoffer': {
                'enabled': productObject?.privateoffer?.enabled,
                'users': privateUsers,
            },
            'status': status
        };

        // Compile document updates for batch write (for consistency)
        let docsToWrite = []
        let i = 1

        // Record change to ownership - adds
        for (const user of productOwners.filter(user => { return !origProductOwners.includes(user) })) {

            const docId = `${Date.now()}-${productId}-${(i++).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`

            docsToWrite.push(['product-owner-change', docId, {
                'date': new Date(),
                'docid': docId,
                'modifiedmember': user,
                'productid': productId,
                'productname': document?.name ? document?.name : productObject.name,
                'publisherstandardsaccepted': false,
                'requester': {
                    'emailaddress': getUser.emailaddress,
                    'userid': getUser.userid
                },
                'action': 'add'
            }, false])
        }

        // Record change to ownership + clean up publisherstandardsaccepted in product document - removes
        const removedUsers = origProductOwners.filter(user => { return !productOwners.includes(user) })

        if (removedUsers.length > 0) {

            // Clean up publisherstandardsaccepted in product document
            const updatedDocument = {
                ...document,
                publisherstandardsaccepted: document.publisherstandardsaccepted.filter(user => !removedUsers.includes(user))
            }
            const changeDate = Date.now()

            for (const user of removedUsers) {

                const docId = `${changeDate}-${productId}-${(i++).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`

                docsToWrite.push(['product-owner-change', docId, {
                    'date': new Date(),
                    'docid': docId,
                    'modifiedmember': user,
                    'productid': productId,
                    'productname': document?.name ? document?.name : productObject.name,
                    'requester': {
                        'emailaddress': getUser.emailaddress,
                        'userid': getUser.userid
                    },
                    'action': 'remove'
                }, false])
            }

            // Update product document
            docsToWrite.push(['products', productId, updatedDocument, true])

        } else {
            // Update product document (no owner removals)
            docsToWrite.push(['products', productId, document, true])
        }


        // Commit all database writes
        BatchWriteDocument(docsToWrite).then(() => {

            setRequestType('view');
            setSubmitState({
                'validated': false,
                'preventSubmit': false,
                'modalVisible': false,
                'firebaseError': undefined,
                'productPricing': undefined,
            })

        }).catch((error) => {

            setRequestType('onload');

            submitState.firebaseError = `${error}`;
            submitState.modalVisible = true;
            setSubmitState({ ...submitState });

            //Submit error to global store > This will be captured by ErrorHandler
            setError(`Editing product has failed, error: ${error}`);

        });

    };


    //------------------------------------------------------
    //  Event Handler (Admin form Submission)
    //------------------------------------------------------

    //Submit Form > Write to Firestore
    function handleFormSubmitAdmin(e) {

        //Prevent reload, propagation & disable enter key
        e.preventDefault();
        e.stopPropagation();
        if (e.keyCode === 13) {
            return;
        }

        //Create flag for preventing submission until form validated
        let preventSubmit = false;

        if (productOwnerEmail !== "") {
            setProductOwnerNotAdded(true)
            return;
        } else {
            setProductOwnerNotAdded(false)
        }

        if (privateUserEmail !== "") {
            setPrivateUserNotAdded(true)
            return;
        } else {
            setPrivateUserNotAdded(false)
        }

        //Check the nominated mail address is not one of the product owners && nominated mail address given when multiple owners listed 
        if (productOwners?.filter((ownerEmail) => ownerEmail === nominatedMailAddress)?.length > 0 ||
            (productOwners?.length > 1 && nominatedMailAddress?.length === 0) ||
            (nominatedMailAddress?.length > 0 && !isStringEmail(nominatedMailAddress))) {

            setNominatedMailAddressErrorHidden(false);
            setEmailValidated(true);
            preventSubmit = true;
        }

        //Capture submission event
        const event = e.target;

        //Validate form inputs > If invalid -> prevent submission
        if (event.checkValidity() === false) {

            submitState.validated = true;
            setSubmitState({ ...submitState });
            return;
        }

        // Update 'submitState'
        submitState.validated = true;
        setSubmitState({ ...submitState });

        // Prevent submit of form
        if (preventSubmit) return;

        setNominatedMailAddressErrorHidden(true);

        //Submit Form
        writeDocument('products', productId, {
            'publisher': productOwners,
            'publisherstandardsaccepted': productObject?.publisherstandardsaccepted
        }, true).then(() => {

            setRequestType('view');
            setSubmitState({
                'validated': false,
                'preventSubmit': false,
                'modalVisible': false,
                'firebaseError': undefined,
                'productPricing': undefined,
            })

        }).catch((error) => {

            setRequestType('onload');

            submitState.firebaseError = `${error}`;
            submitState.modalVisible = true;
            setSubmitState({ ...submitState });

            //Submit error to global store > This will be captured by ErrorHandler
            setError(`Editing product has failed, error: ${error}`);

        });
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onLoad > Extract productid from url and get information from the database
    useEffect(() => {

        if (searchParams.get('id') === undefined) return;

        // Onload function to get initial state of the document
        function onLoad(document) {

            //Set product ID and object
            setProductId(searchParams.get('id'));
            setProductObject(document);
            setNominatedMailAddress(document?.nominatedmailaddress);
            setProductOwners(document?.publisher);
            setOrigProductOwners(document?.publisher);
            setPrivateUsers(document?.privateoffer?.users);
            setSubNextSteps(document?.subnextsteps);
            setUnsubNextSteps(document?.unsubnextsteps);
            setSupportNextSteps(document?.supportnextsteps);
            setSubscriberTerms(document?.subscriberterms);

            if (getUser !== undefined) {

                setIsOwner(document.publisher?.filter((ownerEmail) => ownerEmail === getUser.emailaddress)?.length > 0)
                setIsAdmin(getUser?.roles?.isAdmin)

            }

            if (document?.pricingchanged?.effectivedate !== 'n/a' && document?.pricingamount !== '0') {

                setPricingChange(true);

            }

        }

        // Handle changes in the products document
        function onChange(document) {

            setProductObject(document);
            setProductOwners(document?.publisher);
            setOrigProductOwners(document?.publisher);
            setPrivateUsers(document?.privateoffer?.users);

            if (document?.pricingchanged?.effectivedate !== 'n/a' && document?.pricingamount !== '0') {

                setPricingChange(true);

            }

        }

        // Handle errors
        function onError(error) {

            setRequestType('error-fatal');
            setError(`ProductsEdit - Failed to find product id, error ${error}`);

        }

        const unsubscribe = DocumentListener('products', searchParams.get('id'), onLoad, onChange, onError);

        // Remove document listener if the component 'unMounts'
        return () => {

            unsubscribe();

        }

        // eslint-disable-next-line
    }, [searchParams, setError, getUser]);


    // onLoad > Once the product is available, lookup the number of 'active' subscriptions
    useEffect(() => {

        if (productId === undefined) return;

        //If the product is for a non-owner, no licensing checks required
        if (!isOwner) {

            setRequestType('onload');
            return;

        };

        //Get 'active' subscriptions' 
        QueryDocument('subscriptions',
            [
                ['productid', '==', productId],
                ['status', '==', "active"]
            ]
        )
            .then((subscriptions) => {

                //Set assigned licenses to total 'active' subscriptions for this product
                setAssignedLicenses(subscriptions?.length > 0 ? subscriptions.length : 0);

                //Load the page
                setRequestType('onload');

            })
            .catch((error) => {

                setRequestType('error-fatal');
                setError(`ProductsEdit - Failed to find active subscriptions for this product, error ${error}`)

            });


        // eslint-disable-next-line
    }, [productId]);

    // When returning to the edit page (ie. onload), check ownership to determine form to load
    useEffect(() => {

        if (requestType === 'onload') setIsOwner(productOwners?.filter((ownerEmail) => ownerEmail === getUser.emailaddress)?.length > 0)

        // eslint-disable-next-line
    }, [requestType]);


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (isOwner) {
        //Full form for product owner
        return (
            <PageResponse
                requestType={requestType}
                pageOnload={

                    <div className='ProductsEditForm-Container'>

                        {/* ================================================================= */}
                        {/*                                                                   */}
                        {/*                     Product Edit Form                             */}
                        {/*                                                                   */}
                        {/* ================================================================= */}

                        <Form noValidate validated={submitState.validated} onSubmit={(e) => handleFormSubmit(e)}>

                            {/* Product Name */}
                            <QuestionLabel question='Product Name *'></QuestionLabel>
                            <div style={{ minWidth: '400px', maxWidth: '50%' }}>
                                <Form.Group className='mb-3' controlId='productname'>
                                    <Form.Control
                                        required
                                        type='text'
                                        defaultValue={productObject?.name}
                                        pattern={reStringAlphaNumSpaceHyphenSlashPara}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a product name. Must contain a combination of {errStringAlphaNumSpaceHyphenSlashPara}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            {/* Product Url */}
                            <QuestionLabel question='Product URL' helptext='<Optional> Allows customers to launch the application from the Marketplace store'></QuestionLabel>
                            <div style={{ minWidth: '400px', maxWidth: '50%' }}>
                                <Form.Group className='mb-3' controlId='producturl'>
                                    <Form.Control
                                        type='text'
                                        defaultValue={productObject?.producturl}
                                        pattern={reString}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid product URL. Must contain a combination of {errString}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            {/* Icon */}
                            <QuestionLabel question='Product Icon' helptext='New icons are uploaded in real time'></QuestionLabel>
                            <UploadIcon
                                productid={productId}
                                path={`products/${productId}`}
                                setIconUrl={setIconUrl}
                                defaultValue={productObject}
                                required={true}
                            ></UploadIcon>

                            {/* Short description */}
                            <QuestionLabel question='Short Description *'></QuestionLabel>
                            <Form.Group className='mb-3' controlId='description'>
                                <Form.Control
                                    required
                                    type='text'
                                    maxLength={"250"}
                                    defaultValue={productObject?.description}
                                    pattern={reString}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a short description. Must contain a combination of {errString}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Overview */}
                            <RichTextArea
                                readOnly={false}
                                title='Overview *'
                                onLoadContent={productObject?.overview}
                                setContent={setOverview}
                            ></RichTextArea>

                            {/* Product Owner(s) */}
                            <QuestionLabel question='Product Owner(s) *' helptext='Enter the email address of the owner(s) of this product'></QuestionLabel>
                        <div className='PublishForm-ProductOwner-Input-Container'>
                                <div 
                                style={{width: '50%'}}
                                >
                                    {/* Input Field */}
                                    <input
                                        className={productOwnerNotAdded ? 'PublishForm-Input-Field-Error' : 'PublishForm-Input-Field'}
                                        type="text"
                                        placeholder="Email address"
                                        value={productOwnerEmail}
                                        onChange={e => {
                                            setProductOwnerEmail(e.target.value)
                                            validateProductOwner(e.target.value)
                                        }}
                                    />

                                    {/* Error Message */}
                                    {
                                        productOwnerNotAdded === true || productOwnerErrorHidden === false ? 
                                        (
                                            <div className='PublishForm-ErrorMessage'>
                                                Please enter a valid (@lendlease.com) email address and click the '+' button to confirm.
                                            </div>
                                        ) : null
                                    }

                                    {/* Warning Message */}
                                    {
                                        emailValidated === 'valid' ? 
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                Click the '+' button to add user
                                            </div>                                        
                                        ) 
                                        :
                                        emailValidated === 'existingUser' ? 
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                This user already exists, please try again
                                            </div>                                        
                                        )
                                        :
                                        emailValidated === 'notLendleaseEmail' ?
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                Please enter a valid (@lendlease.com) email address
                                            </div>
                                        )
                                        : null
                                    }
                                </div>
                                
                                {/* Add Button*/}
                                <div>
                                    <button style={{border: 'none', backgroundColor: 'white', padding:  'none'}} disabled={emailValidated !== 'valid' ? true : false} onClick={() => addProductOwner(productOwnerEmail)}>
                                        <img className='PublishForm-ProductOwner-Input-Container-Button' alt="Add Button" src={emailValidated !== 'valid' ? AddButtonDisabled : AddButton} ></img>
                                    </button>
                                </div>
                        </div>

                            {/* Product Owner List */}
                            <div className='PublishForm-ProductOwner-Container'>
                                {
                                    productOwners?.map((owner, index) =>
                                        <div className='PublishForm-ProductOwner-Label-Container' key={index}>
                                            {productOwners?.length <= 1 || (owner === getUser?.emailaddress && !isAdmin) ?
                                                <>
                                                    <label className='PublishForm-ProductOwner-Label-Disabled'>{owner}</label>
                                                    <img src={DeleteIconDisabled} alt="Delete Icon"></img>
                                                </>
                                                :
                                                <>
                                                    <label className='PublishForm-ProductOwner-Label'>{owner}</label>
                                                    <img style={{ cursor: "pointer" }} src={DeleteIcon} alt="Delete Icon" onClick={() => setProductOwners((productOwners) => productOwners.filter((ownerEmail) => ownerEmail !== productOwners[index]))}></img>
                                                </>
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            {/* Nominated Mailbox */}
                            {/* Note* - This is required if we have more than 1 product owner */}
                            <QuestionLabel question='Please nominate an email address for receiving notifications below.*' helptext='This email address will receive notifications for new subscription requests for this product. You may choose to enter a distribution list, a teams channel or a shared mailbox to manage subscription requests in a team.'></QuestionLabel>
                            <input
                                className={nominatedMailAddressErrorHidden ? 'PublishForm-Input-Field' : 'PublishForm-Input-Field-Error'}
                                style={{ width: "50%" }}
                                type='email'
                                placeholder='e.g.DLAppTeam@lendlease.com'
                                required={productOwners?.length > 1 ? true : false}
                                value={nominatedMailAddress}
                                onChange={e => handleNominatedMailAddressChange(e.target.value)}
                            />
                            <div hidden={nominatedMailAddressErrorHidden} className='PublishForm-ErrorMessage'>
                                Please provide a valid email address that does not belong to one of the owners listed above.
                            </div>
                            <p style={{ fontSize: "12px", color: "grey" }}>
                                Note: If you enter a distribution list, members in this group cannot approve/reject subscription requests unless they are also listed as an owner of this product above.
                            </p>

                            {/* Availability */}
                            <ProductAvailability
                                privateEnabled={productObject?.privateoffer?.enabled}
                                privateUsers={privateUsers}
                                setPrivateUsers={setPrivateUsers}
                                privateUserEmail={privateUserEmail}
                                setPrivateUserEmail={setPrivateUserEmail}
                                privateUserNotAdded={privateUserNotAdded}
                                setPrivateUserNotAdded={setPrivateUserNotAdded}
                            ></ProductAvailability>

                            {/* Pricing -- Read Only */}
                            <ProductPricing
                                productObject={productObject}
                                setRequestType={setRequestType}
                                submitState={submitState}
                                readOnly={true}
                            ></ProductPricing>

                            {/* Pricing -- Request a pricing change*/}
                            {
                                // Benno ~ FREE PRODUCTS are excluded!!
                                productObject?.pricingamount === '0' ? (
                                    <NotificationLabel
                                        message='Free products are unable to change pricing.'
                                    ></NotificationLabel>
                                ) : (

                                    pricingChange === false ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ cursor: 'pointer', height: '35px' }} alt='Pricing-Change-Icon' src={PricingChangeGreen} onClick={() => setPricingChange(true)}></img>
                                        </div>
                                    ) :
                                        (
                                            <>
                                                <ProductPricing
                                                    productObject={productObject}
                                                    setRequestType={setRequestType}
                                                    submitState={submitState}
                                                    readOnly={false}
                                                ></ProductPricing>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{ cursor: 'pointer', height: '35px' }} alt='Pricing-Change-Icon' src={PricingChangeGray} onClick={() => setPricingChange(false)}></img>
                                                </div>
                                            </>
                                        )
                                )
                            }

                            {/* Licenses */}
                            <QuestionLabel question='Licenses' helptext='(Optional) Specify the total product licenses you can assign to users and receive alerts when a percentage of these licenses have been assigned.'></QuestionLabel>
                            <div className='ProductsEditForm-Licenses-Container'>
                                {/* Total Licenses */}
                                <Form.Group className='mb-3' controlId='totallicenses'>
                                    <QuestionLabel question='Total Licenses' helptext='(Optional) Set the maximum number of licenses to assign for your product. If left blank, there will be no limit on the number of users that can subscribe to your product.'></QuestionLabel>
                                    <Form.Control
                                        type='number'
                                        min={productObject?.totallicenses > 0 ? productObject?.assignedlicenses : 0}
                                        defaultValue={productObject?.totallicenses > 0 ? productObject?.totallicenses : 0}
                                        pattern={reStringUnsignedInteger}
                                        style={{ marginTop: "10px" }}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                        Please enter the total number of licenses, greater than {productObject?.assignedlicenses}.
                                    </Form.Control.Feedback>

                                    {/* Show label with assigned licenses if totalicenses > 0 */}
                                    {
                                        productObject?.totallicenses > 0 ?
                                            (
                                                <Form.Text muted style={{ fontWeight: "normal" }}>
                                                    {assignedLicenses} out of {productObject?.totallicenses} licenses used
                                                </Form.Text>
                                            )
                                            :
                                            (null)
                                    }
                                </Form.Group>

                                {/* Alert Threshold */}
                                <QuestionLabel question='Set alert threshold rules' helptext='(Optional) Set an alert threshold for receiving alerts when a percentage of your total licenses have been assigned. If left blank, this will be set to 0% and no alerts will be issued.'></QuestionLabel>

                                <Form.Text muted style={{ fontWeight: "normal", marginBottom: "5px" }}>
                                    Receive email alert notifications when a percentage of your total licenses have been assigned to users.
                                </Form.Text>

                                <InputGroup className="mb-3" controlId="alertthreshold" style={{ width: "30%" }}>
                                    <FormControl
                                        required
                                        id="alertthreshold"
                                        type='text'
                                        pattern='^[0-9]$|^[1-9][0-9]$|^(100)$'
                                        defaultValue={productObject?.alertthreshold ? productObject?.alertthreshold : 0}
                                    />
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                        Please enter an alert threshold.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>

                            {/* What happens after you become a subscriber? */}
                            <div className='ProductsEditForm-Body-Items'>
                                <QuestionLabel question='What happens after you become a subscriber? *'></QuestionLabel>
                                <div style={{ margin: '10px 0px 20px' }}>
                                    When a customer subscribes to a new product, IT Marketplace enables publishers to provide customers step-by-step of instructions. The below steps will be provided to the customer in a email and in the marketplace.
                                </div>
                                <div>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows={7}
                                        name='subnextsteps'
                                        defaultValue={subNextSteps}
                                        ref={subNextStepsRef}
                                        onChange={e => handleTextAreaChange(e.target.value, setSubNextSteps, subNextStepsRef)}
                                    />
                                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                        Please provide a list of steps for your customer to follow when they have subscribed to your product. Must contain a combination of {errString}
                                    </Form.Control.Feedback>
                                </div>
                            </div>

                            {/* What happens after a customer unsubscribes? */}
                            <div className='ProductsEditForm-Body-Items'>
                                <QuestionLabel question='What happens after a customer unsubscribes? *'></QuestionLabel>
                                <div style={{ margin: '10px 0px 20px' }}>
                                    When a customer unsubscribes from a product, IT Marketplace enables publishers to provide customers step-by-step instructions. The below steps will be provided to the customer in a email and in the marketplace.
                                </div>
                                <div>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows={7}
                                        name='unsubnextsteps'
                                        defaultValue={unsubNextSteps}
                                        ref={unsubNextStepsRef}
                                        onChange={e => handleTextAreaChange(e.target.value, setUnsubNextSteps, unsubNextStepsRef)}
                                    />
                                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                        Please provide a list of steps for your customer to follow when they have unsubscribed to your product. Must contain a combination of {errString}
                                    </Form.Control.Feedback>
                                </div>
                            </div>

                            {/* How do customers get support?  */}
                            <div className='ProductsEditForm-Body-Items'>
                                <QuestionLabel question='How do customers get support? *'></QuestionLabel>
                                <div className='ProductsEditForm-Support-Container'>
                                    <div style={{ margin: '10px 0px' }}>
                                        Link
                                        <Form.Group className='mb-3' controlId='supportlink' style={{ margin: '10px 0px' }}>
                                            <Form.Control
                                                required
                                                type='text'
                                                defaultValue={productObject?.supportlink}
                                                pattern={reString}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                                Please provide a valid support link.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div style={{ margin: '10px 0px' }}>
                                        Email Address
                                        <Form.Group className='mb-3' controlId='supportemail' style={{ margin: '10px 0px' }}>
                                            <Form.Control
                                                required
                                                type='text'
                                                defaultValue={productObject?.supportemail}
                                                pattern={reStringEmail}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                                Please provide a valid support email address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        rows={7}
                                        name='supportnextsteps'
                                        defaultValue={supportNextSteps}
                                        ref={supportNextStepsRef}
                                        onChange={e => handleTextAreaChange(e.target.value, setSupportNextSteps, supportNextStepsRef)}
                                    />
                                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                        Please provide instructions on how your customers can get support for your product. Must contain a combination of {errString}
                                    </Form.Control.Feedback>
                                </div>
                            </div>

                            {/* Your Products Terms & Conditions? */}
                            <QuestionLabel question="What are your product's terms & conditions?"></QuestionLabel>
                            <div>
                                When a customer subscribes to a product, IT Marketplace allows publishers to provide terms and conditions. These conditions will be displayed to the customer before they subscribe to your product.
                            </div>
                            <div className='ProductsEditForm-Body-Items'>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={7}
                                    name='subscriberterms'
                                    defaultValue={subscriberTerms}
                                    ref={subscriberTermsRef}
                                    onChange={e => handleTextAreaChange(e.target.value, setSubscriberTerms, subscriberTermsRef)}
                                />
                                <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                    Please provide a list of subscriber terms and conditions for your product. Must contain a combination of {errString}
                                </Form.Control.Feedback>
                            </div>

                            {/* Tags */}
                            <div className='ProductsEditForm-Body-Items'>
                                <QuestionLabel question='Tags'></QuestionLabel>
                                <div style={{ margin: '10px 0px' }}>
                                    IT Marketplace offers publishers the ability to tag a product with key words. These words are used by the IT Marketplace search.
                                </div>
                                <div>
                                    <MultiLineDot
                                        setState={setSearchTerms}
                                        setPlaceHolder='e.g. Design'
                                        setDefaultValue={productObject?.labels}
                                    ></MultiLineDot>
                                </div>
                            </div>

                            {/* Other settings */}
                            <div className='ProductsEditForm-Body-Items'>
                                <QuestionLabel question='Other settings' style={{ marginBottom: "10px" }}></QuestionLabel>
                                <Form.Check
                                    type="switch"
                                    id="status"
                                    label="Hide this product"
                                    defaultChecked={CheckBoxHandler(productObject?.status)}
                                />
                            </div>

                            {/* Buttons */}
                            <div className='ProductsEditForm-Buttons'>
                                <button className='Primary-Button' type='submit'>
                                    Save
                                </button>
                                <Link className='Secondary-Button' to="/products" style={{ textDecoration: "none" }}>Discard</Link>
                            </div>

                            {/* ================================================================= */}
                            {/*                                                                   */}
                            {/*                     Submit Handler                                */}
                            {/*                                                                   */}
                            {/* ================================================================= */}

                            {/* Invalid PTE/currency modal */}
                            <Modal show={submitState.modalVisible} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Warning
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    {/*  Firebase errors */}
                                    <NotificationLabel
                                        type={'error'}
                                        message={submitState.firebaseError}
                                        hidden={submitState.firebaseError === undefined ? (true) : (false)}
                                    ></NotificationLabel>

                                    {/* ProductPricing.js errors */}
                                    <NotificationLabel
                                        type={'warning'}
                                        message={submitState.productPricing}
                                        hidden={submitState.productPricing === undefined ? (true) : (false)}
                                    ></NotificationLabel>

                                    {/* Toggle between try again and continue */}
                                    {
                                        submitState.firebaseError === undefined ? (
                                            <p>
                                                If you wish to continue regardless, click <b>Continue</b>.
                                            </p>
                                        ) : (
                                            <p>
                                                Publishing product has failed, click <b>Try again</b> and if this issue persists contact support.
                                            </p>
                                        )
                                    }
                                    <p>
                                        If you would like to go back without saving your changes, click <b>Cancel</b>.
                                    </p>

                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='Primary-Button' onClick={() => {

                                        submitState.modalVisible = false;
                                        submitState.preventSubmit = false;
                                        setSubmitState({ ...submitState });
                                        handleFormSubmit(submitState.event);

                                    }}>
                                        {
                                            submitState.firebaseError === undefined ? (
                                                <>
                                                    Continue
                                                </>
                                            ) : (
                                                <>
                                                    Try again
                                                </>
                                            )
                                        }
                                    </button>
                                    <button className='Secondary-Button' variant="secondary" onClick={() => {
                                        submitState.modalVisible = false
                                        setSubmitState({ ...submitState })
                                    }}>
                                        Cancel
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    </div>
                }

                // Successfully submitted form
                pageView={
                    <div className='ProductsEditForm-View'>
                        Your edits have been saved!
                        <br></br>
                        <button className='Primary-Button' style={{ marginTop: '20px' }} onClick={() => setRequestType('onload')}>Return to Overview</button>
                    </div>
                }
            ></PageResponse>
        );

    } else {
        //Product owner related fields only if admin but not product owner
        return (
            <PageResponse
                requestType={requestType}
                pageOnload={

                    <div className='ProductsEditForm-Container'>

                        <Form noValidate validated={submitState.validated} onSubmit={(e) => handleFormSubmitAdmin(e)}>

                            {/* Product Owner(s) */}
                            <QuestionLabel question='Product Owner(s) *' helptext='Enter the email address of the owner(s) of this product'></QuestionLabel>
                        <div className='PublishForm-ProductOwner-Input-Container'>
                                <div 
                                style={{width: '50%'}}
                                >
                                    {/* Input Field */}
                                    <input
                                        className={productOwnerNotAdded ? 'PublishForm-Input-Field-Error' : 'PublishForm-Input-Field'}
                                        type="text"
                                        placeholder="Email address"
                                        value={productOwnerEmail}
                                        onChange={e => {
                                            setProductOwnerEmail(e.target.value)
                                            validateProductOwner(e.target.value)
                                        }}
                                    />

                                    {/* Error Message */}
                                    {
                                        productOwnerNotAdded &&
                                        (
                                            <div className='PublishForm-ErrorMessage'>
                                                Please enter a valid (@lendlease.com) email address and click the '+' button to confirm.
                                            </div>
                                        ) 
                                    }

                                    {/* Warning Message */}
                                    {
                                        emailValidated === 'valid' ? 
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                Click the '+' button to add user
                                            </div>                                        
                                        ) 
                                        :
                                        emailValidated === 'existingUser' ? 
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                This user already exists, please try again
                                            </div>                                        
                                        )
                                        :
                                        emailValidated === 'notLendleaseEmail' ?
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                Please enter a valid (@lendlease.com) email address
                                            </div>
                                        )
                                        :
                                        null
                                    }
                                </div>
                                
                                {/* Add Button*/}
                                <div>
                                    <button style={{border: 'none', backgroundColor: 'white', padding:  'none'}} disabled={emailValidated !== 'valid' ? true : false} onClick={() => addProductOwner(productOwnerEmail)}>
                                        <img className='PublishForm-ProductOwner-Input-Container-Button' alt="Add Button" src={emailValidated !== 'valid' ? AddButtonDisabled : AddButton} ></img>
                                    </button>
                                </div>
                        </div>

                            {/* Product Owner List */}
                            <div className='PublishForm-ProductOwner-Container'>
                                {
                                    productOwners?.map((owner, index) =>
                                        <div className='PublishForm-ProductOwner-Label-Container' key={index}>
                                            {productOwners?.length <= 1 ?
                                                <>
                                                    <label className='PublishForm-ProductOwner-Label-Disabled'>{owner}</label>
                                                    <img src={DeleteIconDisabled} alt="Delete Icon"></img>
                                                </>
                                                :
                                                <>
                                                    <label className='PublishForm-ProductOwner-Label'>{owner}</label>
                                                    <img style={{ cursor: "pointer" }} src={DeleteIcon} alt="Delete Icon" onClick={() => setProductOwners((productOwners) => productOwners.filter((ownerEmail) => ownerEmail !== productOwners[index]))}></img>
                                                </>
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            {/* Nominated Mailbox */}
                            {/* Note* - This is required if we have more than 1 product owner */}
                            <QuestionLabel question='Please nominate an email address for receiving notifications below.*' helptext='This email address will receive notifications for new subscription requests for this product. You may choose to enter a distribution list, a teams channel or a shared mailbox to manage subscription requests in a team.'></QuestionLabel>
                            <input
                                className={nominatedMailAddressErrorHidden ? 'PublishForm-Input-Field' : 'PublishForm-Input-Field-Error'}
                                style={{ width: "50%" }}
                                type='email'
                                placeholder='e.g.DLAppTeam@lendlease.com'
                                required={productOwners?.length > 1 ? true : false}
                                value={nominatedMailAddress}
                                onChange={e => handleNominatedMailAddressChange(e.target.value)}
                            />
                            <div hidden={nominatedMailAddressErrorHidden} className='PublishForm-ErrorMessage'>
                                Please provide a valid email address that does not belong to one of the owners listed above.
                            </div>
                            <p style={{ fontSize: "12px", color: "grey" }}>
                                Note: If you enter a distribution list, members in this group cannot approve/reject subscription requests unless they are also listed as an owner of this product above.
                            </p>

                            {/* Buttons */}
                            <div className='ProductsEditForm-Buttons'>
                                <button className='Primary-Button' type='submit'>
                                    Save
                                </button>
                                <Link className='Secondary-Button' to="/products" style={{ textDecoration: "none" }}>Discard</Link>
                            </div>
                        </Form>
                    </div>
                }
                // Successfully submitted form
                pageView={
                    <div className='ProductsEditForm-View'>
                        Your edits have been saved!
                        <br></br>
                        <button className='Primary-Button' style={{ marginTop: '20px' }} onClick={() => setRequestType('onload')}>Return to Overview</button>
                    </div>
                }
            ></PageResponse>
        );
    }
}