//=====================================================
//  Component: Page Response 
//  Description: Displays location details
//=====================================================

//  Properties:
//    - requestType = {useState used to determine current page view}
//    - pageOnload = {HTML content display when page is first loaded}
//    - pageView = {HTML content display when page request type is 'view'}
//    - pageCreate = {HTML content display when page request type is 'create'}
//    - pageDelete = {HTML content display when page request type is 'delete'}
//    - pageSuccess = {HTML content display when page request type is 'success'}
//    - pageFailed = Standard 'ErrorHandling' component

//  Example:
//    <PageResponse
//      requestType={requestType}
//      pageOnload={
//        <div>
//        </div>
//      }
//      pageView={
//        <MapView
//          location={locations[Object.keys(locations).find(key => locations[key] === selectedLocation)}
//          formStatus={requestType}
//        ></MapView>
//      }
//      pageCreate={
//        <CreateBooking
//          locations={locations}
//          selectedLocation={selectedLocation}
//          setRequestType={setRequestType}
//        ></CreateBooking>        
//      }
//    ></PageResponse>

//=====================================================


//Libraries
import React, {useEffect, useId} from 'react';

//Components
import ErrorHandler from '../ErrorHandler/ErrorHandler'

//Images
import LoadingIcon from '../Images/Image_Loading_Ripple.svg'

//CSS
import './PageResponse.css'

function PageResponse(props){


  const id = useId()

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------
  
    const requestType = props.requestType
    const pageOnload = props.pageOnload
    const pageView = props.pageView
    const pageCreate = props.pageCreate
    const pageEdit = props.pageEdit
    const pageSuccess = props.pageSuccess
    const pageDelete = props.pageDelete
    const pageHeader = props.pageHeader

  //------------------------------------------------------
  //  Define Functions 
  //------------------------------------------------------

    function handleRequestType(requestType) {

      if(requestType === 'pending'){
        return (
          <div>
            <img className='PageResponse-Container-Pending-Img' alt="loading-circle-icon" src={LoadingIcon}></img>
            <p className='PageResponse-Pending'>Loading...</p>
          </div>
        )
      }

      else if(requestType === 'onload') {
        return (
          <div id={id}>
            {pageOnload}
          </div>

        )
      }

      else if(requestType === 'view'){
        return pageView
      }

      else if(requestType === 'create'){
        return pageCreate
      }

      else if(requestType === 'edit'){
        return pageEdit
      }

      else if(requestType === 'success'){
        return pageSuccess
      }

      else if(requestType === 'delete'){
        return pageDelete
      }

    //------------------------------------------------------
    //  user generated error
    //------------------------------------------------------
      else if(requestType === 'error-invalid'){
        return (
          <ErrorHandler
            requestType={requestType}
          ></ErrorHandler>
        );
      }

    //------------------------------------------------------
    //  known error
    //------------------------------------------------------

      else if(requestType === 'error-fatal'){
        console.log('Fatal triggered: ', requestType)
        return (
          <ErrorHandler
            requestType={requestType}
          ></ErrorHandler>
        );
      }

    //------------------------------------------------------
    //  Catch-all
    //  Generic > raise SD ticket
    //------------------------------------------------------
      else {
        return (
          <ErrorHandler
            requestType={requestType}
          ></ErrorHandler>
        );
      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Load the page whenever the requestType changes 
    useEffect(() => {
      //Auto-scrolls to the top
      window.scrollTo(0, 0)

    },[requestType])

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
    return (
      <div className="PageResponse-Container">
        <div className='PageResponse-Header'>
          {pageHeader ? pageHeader : null}
        </div>

        <div className='PageResponse-Body'>
          {handleRequestType(requestType)}
        </div>

      </div>
    ) 
}

export default PageResponse;