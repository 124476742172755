//================================================================
//  Page: Market Place Unsubscribe
//================================================================

//  Purpose: Unsubscribe page, accessed through 'My Subscriptions'

//  Properties:

//  Example:
//    <Unsubscribe></Unsubscribe>    

//================================================================


//Libraries
import React, {useContext, useEffect, useState} from 'react'
import {useSearchParams, Link} from 'react-router-dom';
import {Breadcrumb} from "react-bootstrap";

//Contexts
import { SetError } from '../../Library/GlobalContexts';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import PageHeader from '../../Components/PageHeader/PageHeader';

//Functions
import GetDocument from '../../Library/GetDocument';
import WriteDocument from '../../Library/WriteDocument';

//Images
import SuccessPlane from '../../Components/Images/Image_Success_Plane.svg'

//CSS
import './Unsubscribe.css'


export default function Unsubscribe() {

  //------------------------------------------------------
  //  UseParams from 'react-router'
  //------------------------------------------------------

    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Handles the pages status > 'pending', 'onload', 'success', 'error-fatal'
    const [requestType, setRequestType] = useState('pending');

    //Hold the product information
    const [productId, setProductId] = useState();
    const [productObject, setProductObject] = useState();

    //Has the user subscribed to this product > 'unsubcribed', 'pending', 'subscribed'
    const [subscriptionId, setSubscriptionId] = useState();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function UnsubscribeUser(){

      //Conditions
      if (subscriptionId === undefined) return setRequestType('error-fatal');

      //Show the pending screen
      setRequestType('pending');

      // Basis of our new end date is the start date
      let enddate = new Date();

      //Write the document changes to the database
      const document = {
        'status': 'cancelled',
        'cancelreason': 'Cancelled by subscriber',
        'enddate': enddate,
      }

      WriteDocument('subscriptions', subscriptionId, document, true).then(() =>{

        setRequestType('success');

      }).catch((error) =>{

        setRequestType('error-fatal');

        //Submit error to global store > This will be captured by ErrorHandler
        setError(`Unsubscribing from a product has failed, error: ${error}`);

      });

    };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Extract 'productid' and 'subscriptionid' from url
    useEffect(()=>{

      //Conditions
      if (searchParams === undefined) return;

      setProductId(searchParams.get('productid'));
      setSubscriptionId(searchParams.get('subscriptionid'));

    }, [searchParams]);

    //Get product information
    useEffect(()=>{

      //Conditions
      if (productId === undefined || subscriptionId === undefined) return;

      //Get Product information
      GetDocument('products', productId).then((results) =>{

        setProductObject(results);

        //Get Subscription information
        return GetDocument('subscriptions', subscriptionId)
        .then((results) => {

          setRequestType('onload');
  
        });
      
        }).catch((error) =>{
    
          setRequestType('error-fatal');
          setError(`Failed to get project or subscriber information, error: ${error}`);

        });

    }, [productId, setProductObject, subscriptionId, setError])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageOnload={
        <div className='Unsubscribe-Onload-Container'>
          
          {/* Page Header */}
          <PageHeader
            title={productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}
            icon={productObject?.iconurl}
            breadcrumbs={
              <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">
                        Marketplace
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}</Breadcrumb.Item>
              </Breadcrumb>
            }
          ></PageHeader>

          {/* Confirmation Message */}
          <div>
            Do you want to unsubscribe from <strong>{productObject?.name}</strong>?
            <br></br>
            <br></br>
            By unsubscribing, you may lose access to this product and all future recurring charges will be cancelled.
          </div>

          {/* Buttons */}
          <div className='Unsubscribe-Body'>
            <button className="Primary-Button" onClick={() => UnsubscribeUser()}>Confirm</button>
            <Link to="/subscriptions?view=history">
              <button className="Secondary-Button">Cancel</button>
            </Link>
          </div>

        </div>
      }
      pageSuccess={
        <div>

          {/* Page Header */}
          <PageHeader
            title={"We are processing your request!"}
            icon={SuccessPlane}
            breadcrumbs={
              <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">
                        Marketplace
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}</Breadcrumb.Item>
              </Breadcrumb>
            }
          ></PageHeader>

          {/* Body */}
          <div className='Unsubscribe-Success-Body'>
            Next steps:
            <textarea className='Unsubscribe-Textarea' readOnly value={productObject?.unsubnextsteps}></textarea>
            Request ID: 
            <p style={{fontWeight: "normal"}}>{subscriptionId}</p>
            <Link to="/subscriptions?view=history" style={{marginTop: "15px"}}>
              <button className="Primary-Button">
                View Subscriptions
              </button>
            </Link>
          </div>

        </div>

      }
    ></PageResponse>
  );

}
