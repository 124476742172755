//================================================================
//  LoginHandler
//  Created by Mark Bennett
//================================================================

//  Purpose: 
//    1. Handles all authentication status for the application
//    2. Presents to the user in a meaningful way
//    3. Part of index.js

//  Example: 
//    ReactDOM.render(

//      <GlobalContexts>
//        <AuthProvider>
//            <LoginHandler></LoginHandler>
//        </AuthProvider>
//      </GlobalContexts>,

//    document.getElementById("root")
//    );

//================================================================

//------------------------------------------------------
//  Handler for all login events
//  Designed to handle the login process, handle failures and if success give a user an getAccessToken
//  The 'LoginHandlerComponent' is built to handle both 'authState'
//    'authenticated'
//    'unauthenticated'
//------------------------------------------------------


//Libraries
import React, { useContext } from 'react';
import App from "../App";
import { BrowserRouter } from 'react-router-dom';
import { getAuth} from "firebase/auth";
import {GetAppStatus, GetAppErrors} from './GlobalContexts';

//Components
import Login from './Login';
import Logout from './Logout';

//Images
import LoadingIcon from '../Components/Images/Image_Loading_Ripple.svg';
import ImageLlLogo from '../Components/Images/Image_LL_Logo_101x72.png';
import ImageStoreFront from '../Components/Images/Image_Login_Storefront.svg';
import IconBilling from '../Components/Images/Icon_Login_Billing.svg';
import IconProduct from '../Components/Images/Icon_Login_Product.svg';
import IconSso from '../Components/Images/Icon_Login_SSO.svg';
import GrumpyCatSigninPrompt from '../Components/Images/Icon_GrumpyCat_Signin.svg';

//Styling for login page
import './LoginHandler.css';


function LoginHandler() {

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
  //------------------------------------------------------

    // eslint-disable-next-line
    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getAppStatus = useContext(GetAppStatus);
    const getAppErrors = useContext(GetAppErrors);

  //------------------------------------------------------
  //  Authorised Users
  //------------------------------------------------------

    if (getAppStatus === "authenticated"){

      //Return the App
      return (
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      )
    }

  //------------------------------------------------------
  //  Expired
  //------------------------------------------------------

    else if (getAppStatus === 'expired'){
            
      return (
        <div className='LoginHandler-Failed-Container'>

          <img className='LoginHandler-Failed-Img' alt='logo' src={ImageStoreFront}></img>
          
          <h2 className='LoginHandler-Failed-Title'>Sorry, we logged you out!</h2>

          <div className='LoginHandler-Failed-Solution'>
            One hour session limit was reached. Click 'return to the sign in page' or close this window.
          </div>

          <div className='LoginHandler-Failed-Button'>
            <Logout title='Return to sign in page'></Logout>
          </div>

        </div>
      )
    }

  //------------------------------------------------------
  //  Pending
  //------------------------------------------------------

    else if (getAppStatus === "pending"){

      return (
        <div className='LoginHandler-Pending'>
          <img alt="loading-circle-icon" src={LoadingIcon}></img>
          <Logout title="Cancel"></Logout>
        </div>
      )
    }

  //------------------------------------------------------
  //  Failed
  //------------------------------------------------------

    else if (getAppStatus === 'failed'){

    //------------------------------------------------------
    //  Handles each known error message, with a generic catch all response!
    //------------------------------------------------------

      function HandleErrorMessage(message){

        //General catch, to ensure something exists
        if (message === undefined) return;

        //Allow third-party cookies
        if (message.includes('web-storage-unsupported')){
          return(
            <div>
              Third party cookies are disabled. Enable <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'>third-party cookies</a> in your browser and try again.
            </div>
          )
        }

        //Failed to verify the users credenitals, user must close tab and sign in again
        if (message.includes('auth/invalid-credential')){
          return(
            <div>
              Failed verify your credentials. Close the browser and try again.
            </div>
          )
        }

        //Failed to verify the users credenitals, user must close tab and sign in again
        if (message.includes('auth/popup-closed-by-user')){
          return(
            <div>
              You closed the sign in pop up. Click 'return to the sign in page' and try again.
            </div>
          )
        }

        //User is blocking popups
        if (message.includes('auth/popup-blocked')){
          return(
            <div>
              Browser is blocking pop ups. Allow <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'>pop ups</a> and try again.
            </div>
          )
        }

        //There is an existing Firebase account with this users UPN 
        if (message.includes('auth/account-exists-with-different-credential')){
          return(
            <div>
              Found an existing account with the same email address. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
            </div>
          )
        }

        //General catch all
        else{
          return(
            <div>
              Unknown issue has occured. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
            </div>
          )
        }
      }

    //------------------------------------------------------
    //  Show 'Oops, something went wrong!' page 
    //------------------------------------------------------

      return (
        <div className='LoginHandler-Failed-Container'>

          <img className='LoginHandler-Failed-Img' alt='Logo' src={ImageStoreFront}></img>
        
          <h2 className='LoginHandler-Failed-Title'>Oops, something went wrong!</h2>

          <div className='LoginHandler-Failed-Solution'>
            {HandleErrorMessage(getAppErrors)}
          </div>

          <div className='LoginHandler-Failed-Button'>
            <Logout title='Return to sign in page'></Logout>
          </div>

          <div className='LoginHandler-Failed-Error'>
            <h3>Error message</h3>
            {getAppErrors}
          </div>

        </div>
      )
    }

  //------------------------------------------------------
  //  Pending Signin
  //------------------------------------------------------

    else if (getAppStatus === 'pending-signin'){
      return (
        <div className='App'>
          <div className='LoginHandler-Pending'>
            <img alt='Session Timed Out!' src={GrumpyCatSigninPrompt}></img>
          </div>
        </div>
      )
    }

  //------------------------------------------------------
  //  Un-Authorised Users
  //------------------------------------------------------

    else if (getAppStatus === 'unauthenticated'){
      return (
        <div className='App'>
          <div className='LoginHandler-Container'>

            <div className='LoginHandler-Header'>
              <img className='LoginHandler-Header-Logo' src={ImageLlLogo} alt='Lendlease-Logo'></img>
              <h4 style={{color: "#FDFDFD"}}>IT Marketplace</h4>
            </div>

            {/* Login Pane */}
            <div className='LoginHandler-Body'>
              <div className='LoginHandler-Content'>
                <h1 style={{textAlign: 'center'}}>A self-service way to broker technology between product teams and their customers</h1>

                {/* Login Graphic */}
                <div>
                  <img className='LoginHandler-Graphic' src={ImageStoreFront} alt='Login-Graphic'></img>
                </div>
                  
                {/* Login & Getting Started Button */}
                <div className="LoginHandler-Button-Container">
                  <Login></Login>
                  <div className="LoginHandler-Button-Dropdown">
                    Getting Started Guides 
                    <div className="LoginHandler-Button-Dropdown-Content">
                      <a href="https://lendlease.sharepoint.com/:b:/r/sites/CloudGuild/Shared%20Documents/Marketplace%20Docs/Marketplace%20Publisher%20Onboarding%20Guide.pdf?csf=1&web=1&e=ikPl0j" target="_blank" rel="noreferrer">Publisher Onboarding Guide</a>
                      <a href="https://lendlease.sharepoint.com/:b:/r/sites/CloudGuild/Shared%20Documents/Marketplace%20Docs/Marketplace%20Publisher%20Standards.pdf?csf=1&web=1&e=mEW2iG" target="_blank" rel="noreferrer">Publisher Standards</a>
                      <a href="https://lendlease.sharepoint.com/:b:/r/sites/CloudGuild/Shared%20Documents/Marketplace%20Docs/Marketplace%20Publisher%20Terms%20and%20Conditions.pdf?csf=1&web=1&e=TTKL2m" target="_blank" rel="noreferrer">Publisher Terms and Conditions</a>
                      <a href="https://lendlease.sharepoint.com/:b:/r/sites/CloudGuild/Shared%20Documents/Marketplace%20Docs/Marketplace%20Subscriber%20Terms%20and%20Conditions.pdf?csf=1&web=1&e=wUreNp" target="_blank" rel="noreferrer">Subscriber Terms and Conditions</a>
                    </div>  
                  </div> 
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className='LoginHandler-Footer'>
              <div className='LoginHandler-Footer-Feature'>
                <img className='LoginHandler-Footer-Feature-Icon' src={IconProduct} alt='Product-Icon'></img>
                <h5 className='LoginHandler-Footer-Feature-Title'>Product Customizations</h5>
                <p className='LoginHandler-Footer-Feature-Text'>Publish or consume Products the way you want it.</p>
              </div>
              <div className='LoginHandler-Footer-Feature'>
                <img className='LoginHandler-Footer-Feature-Icon' src={IconBilling} alt='Billing-Icon'></img>
                <h5 className='LoginHandler-Footer-Feature-Title'>Integrated Billing</h5>
                <p className='LoginHandler-Footer-Feature-Text'>Billing is part of the platform, so you can focus on developing your Products.</p>
              </div>
              <div className='LoginHandler-Footer-Feature'>
                <img className='LoginHandler-Footer-Feature-Icon' src={IconSso} alt='SSO-Icon'></img>
                <h5 className='LoginHandler-Footer-Feature-Title'>Seamless SSO</h5>
                <p className='LoginHandler-Footer-Feature-Text'>Login using authentication you’re already familiar with.</p>
              </div>
            </div>
          </div>
        </div>
      )
    }

  //------------------------------------------------------
}

export default LoginHandler;