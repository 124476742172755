//------------------------------------------------------
//  Financial Statistics page - subscription table
//------------------------------------------------------

//Libraries
import React from 'react'
import Tooltip from '../../../Components/Tooltip/Tooltip';

export default function SubscriptionTable(props) {

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------

  const stats = props.stats //Subscription stats
  const toolTipText = props.toolTipText //Table header tooltips
  const frequency = props.frequency //Billing period frequency (for tooltip selection)

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  >>> handleStatus - Generates a label based on auto-recharge status
  //------------------------------------------------------

  function handleStatus(status) {

    if(status === 'To be submitted'){
      return (
        <label className='Status-Label' style={{backgroundColor: "#CADDF4", color: "#4B7BB5"}}>
          {status}
        </label>
      )
    }

    if(status === 'Pending finance'){
      return (
        <label className='Status-Label' style={{backgroundColor: "#FAEAC3", color: "#A1833D"}}>
          {status}
        </label>
      )
    }

    if(status === 'Completed'){
      return (
        <label className='Status-Label' style={{backgroundColor: "#DEF8DC", color: "#6AAA6E"}}>
          {status}
        </label>
      )
    }

    else {
      //ie. the N/A status
      return (
        <label className='Status-Label' style={{backgroundColor: "#E9E9E9", color: "#394E54"}}>
          {status}
        </label>
      )
    }
  }

  //------------------------------------------------------
  //  >>> numFmt - Add thousand comma separators to numbers
  //------------------------------------------------------

  function numFmt(number) {
    return Number(number).toLocaleString('en-US')
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <table className="DataTable-Container">
      <thead>
        {/* Table headers with tooltips */}
        <tr>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Invoice Period
            <Tooltip
              helpText={frequency === 'Monthly' ? toolTipText.InvoicePeriodMonthly : toolTipText.InvoicePeriodYearly}
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Subscriber
            <Tooltip
              helpText={toolTipText.Subscriber}
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Auto-recharge Status
            <Tooltip
              helpText={toolTipText.AutoRechargeStatusSubscription}
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Subscriber Currency
            <Tooltip
              helpText={toolTipText.SubscriberCurrency}
              h='right'
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Discount
            <Tooltip
              helpText={toolTipText.Discount}
              h='right'
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Amount
            <Tooltip
              helpText={toolTipText.Amount}
              h='right'
              v='bottom'
            ></Tooltip>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          /* Show subscription level stats */
          stats.map((object) =>
            <tr>
              <td>{object.periodStart} - {object.periodEnd}</td>
              <td>{object.projectOwnerFirstName} {object.projectOwnerLastName}</td>
              <td>{
                object.journalStatus.length === 0 ? (
                  handleStatus('N/A')
                ):(
                  handleStatus(object.journalStatus)
                )
              }</td>
              <td>{object.Totals.projectCurrencyCode}</td>
              <td>
                {
                  object.Totals.discountAmount === undefined ? (
                    <>0</>
                  ): (
                    <>${object.Totals.discountAmount}</>
                  )
                }
              </td>
              <td>{numFmt(object.Totals.invoiceTotal)} {object.invoiceCurrency}</td>
            </tr>
          )
        }
      </tbody>
    </table>
  )
}
