//================================================================
//  Component: ActiveTable component
//================================================================

//  Purpose: This component is used by 'My Customers' page

//  Properties:
//    - projectid = {A string, the unique product id}

//  Example:
//    <ActiveTable
//      projectid={"prd-1234567890"}
//    ></ActiveTable>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react'
import { CSVLink } from "react-csv";


//Components
import Tooltip from '../../Components/Tooltip/Tooltip';

//Functions
import QueryListener from '../../Library/QueryListener';
import ConvertDate from '../../Library/ConvertDate';

//CSS
import './MyCustomers.css'


export default function ActiveTable({
  productid
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Handles the component status > 'pending', 'onload', 'success', 'error'
    const [requestType, setRequestType] = useState('pending');
    const [subscribers, setSubscribers] = useState();

    //Stores Excel Data
    const [exportedData, setExportedData] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() =>{

      //Conditions
      if (productid === undefined) return;

      function onLoadChange(subscriptions){

        if (subscriptions.length > 0){

          setSubscribers(subscriptions);
                    
          //Takes subscriptions array and converts fields to readable dates and the subscriber array to just an email address
          const data = subscriptions?.map((o) => 
          ({ 
            "subscriber": o?.subscriber?.emailaddress, 
            "startdate": o?.startdate?.toDate()?.toLocaleDateString("en-AU"),
            "createdate": o?.createdate?.toDate()?.toLocaleDateString("en-AU"),
          }));

          setExportedData(data);
          setRequestType('success');

        } else {

          setSubscribers([]);
          setRequestType('onload');

        }

      }

      function onError(error){

        console.log(`Unable to get subscriber information, error ${error}`)
        setSubscribers([]);
        setRequestType('error');

      }

      //Get Subscribers
      const unsubscribe = QueryListener('subscriptions', [
        ['productid', '==', productid],
        ['status', '==', 'active']
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };

    }, [productid]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='Table-Pane'>

        {/* Table Heading */}
        <div className="Table-Title" style={{justifyContent:"space-between", alignItems:"center"}}>
          <div className='MyCustomers-Active-Customers'>
            Active Customers
            <Tooltip
              helpText="Customers that are subscribed and have access to your product."
            ></Tooltip> 
          </div>
          {
            requestType === 'success' &&
            <CSVLink data={exportedData} style={{width: 'fit-content'}}>
              <button className='Primary-Button'>
                Download
              </button>
            </CSVLink>
          }

        </div>  

        {/* Table Content */}
        {
          // Loading screen
          requestType === 'pending' ? (

            <table className='DataTable-Container'>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Subscription ID</th>
                  <th>Subscription Start Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    Loading...
                  </td>
                </tr>
              </tbody>
            </table>
          
          // No results
          ): requestType === 'onload' ? (
            <div>
              No records found...
            </div>
          ):

          // Failed to get results
          requestType === 'error' ? (
            
            <table className='DataTable-Container'>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Subscription ID</th>
                  <th>Subscription Start Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    Failed to retrieve records!
                  </td>
                </tr>
              </tbody>
            </table>
          ): 
          
          // Success > show table rows
          (
            <table className='DataTable-Container'>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Subscription ID</th>
                  <th>Subscription Start Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  subscribers?.map((object, index) => (
                    <tr key={index}>
                      <td>{object?.subscriber?.givenname}</td>
                      <td>{object?.subscriber?.surname}</td>
                      <td>{object?.subscriber?.emailaddress}</td>
                      <td>{object?.subscriptionid}</td>
                      <td>{ConvertDate(object?.startdate?.toDate())}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          )
        }
      </div>
    )

}