//------------------------------------------------------
//  Financial Statistics page - product pane containing tables for each product
//------------------------------------------------------

//Libraries
import React from 'react'

//Functions
import GetImage from '../../../Components/GetImage/GetImage';
import SummaryTable from './SummaryTable'
import SubscriptionTable from './SubscriptionTable'

//Images
import ExpandIcon from '../../../Components/Images/Icon_Expand_Black.svg'

//CSS
import '../FinancialStatistics.css';

export default function ProductPane(props) {

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------

  const frequency = props.frequency //Billing cycle frequency
  const product = props.product //Product details
  const stats = props.stats //Product financial stats
  const rowExpanded = props.rowExpanded //Whether the subscription table should be shown
  const setRowExpanded = props.setRowExpanded

  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------
    
  //Text for all tooltips used in table headers
  const toolTipText = {
    SubscribersInvoiced:
      <>
        Number of subscribers that have been invoiced.<br></br><br></br>
        Typically this is all subscribers of a product, but may increase over time as new subscribers come onboard during the invoice period.
      </>,
    Price:
      <>
        Cost per subscription.<br></br><br></br>
        There may be more than one price/currency if the price and/or currency for the product was changed during the invoice period.
      </>,
    AutoRechargeStatus:
      <>
        Percentage of subscriptions recharged successfully out of the total number of subscriptions that can be recharged.<br></br><br></br>
        A recharge is complete once the Finance team has completed processing and costs have been transferred from your
        cost centre to the subscriber's cost centre.<br></br><br></br>
        Recharges can only occur with subscriptions where the subscriber's cost centre currency matches your product's currency.
        Any subscriptions not matching this requirement are not represented in this column.
      </>,
    AutoRechargeTotal:
      <>
        Total amount that is expected to be automatically recharged for eligible invoices in this invoice period.<br></br><br></br>
        Also shown in this column is the number of subscriptions that is expected to be automatically recharged out of the total
        number of subscriptions. These are subscriptions where the subscriber's cost centre currency matches your product's currency.<br></br><br></br>
        Most recharges will be submitted in the same month the subscriber was invoiced, however for new subscribers,
        the invoice may be recharged in the next month's run instead.
      </>,
    TotalAmountDue:
      <>
        Total amount payable by all invoices for this product, regardless of whether they can be automatically recharged or not.
      </>,
    InvoicePeriodMonthly:
      <>
        Period the subscription invoice covers.<br></br><br></br>
        For a renewing subscription, it will cover the entire calendar month. For a new subscription, it'll be from the date the
        subscription starts until the end of the month.
      </>,
    InvoicePeriodYearly:
      <>
        Period the subscription invoice covers.<br></br><br></br>
        For a renewing subscription, it will cover the 12 months from the true-up month selected by the publisher, to the same date
        in the next year. For a new subscription, it'll be from the date the subscription starts until the selected true-up month
        in the next year.
      </>,
    Subscriber:
      <>
        Name of the subscriber that has been invoiced.
      </>,
    AutoRechargeStatusSubscription:
      <>
        Indicates the recharge status for this particular subscription in this invoice period.<br></br><br></br>
        It can be one of the following:<br></br><br></br>
        <ul>
          <li>
            To be submitted = Not yet submitted to the Finance team. Refer to the 'next recharge submission' date at the top
            of this page for the estimated date when submission will occur<br></br><br></br>
          </li>
          <li>
            Pending finance = Submitted to the Finance team but awaiting processing<br></br><br></br>
          </li>
          <li>
            Completed = Finance has completed processing and costs have been transferred from your cost centre to the
            subscriber's cost centre<br></br><br></br>
          </li>
          <li>
            N/A = Not eligible for automatic recharge, as the subscriber's cost centre currency does not match your product's currency
          </li>
        </ul>
      </>,
    SubscriberCurrency:
      <>
        The subscriber's currency based on their cost centre information.<br></br><br></br>
        
        If this does not match the product's currency when the subscription was created/renewed/changed (whichever was later),
        it is not eligible for automatic recharging.
      </>,
    Discount:
    <>
      Discount percentage applied to each subscriber.
    </>,
    Amount:
      <>
        Amount payable by this subscription, regardless of whether it can be automatically recharged or not.
      </>
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='FinancialStatistics-Body'>
      {
        rowExpanded[product.productid] === false ? (
          /* Show only the summary stats */
          <div className='Table-Pane'>
            <div className='FinancialStatistics-ProductTitle'>
              {/* Toggle for showing/hiding subscription stats */}
              <img className="Expand-Icon" src={ExpandIcon} alt="Expand_Icon" onClick={() => setRowExpanded({ [product.productid]: true })}></img>
              <div className='MySubscriptions-Pending-Logo'>
                <GetImage iconurl={product.iconurl}></GetImage>
              </div>
              {product.name}
            </div>
            {
              <div>
                <SummaryTable
                  stats = {stats[product.productid]?.stats}
                  toolTipText={toolTipText}
                ></SummaryTable>
              </div>
            }
          </div>
        ) : (
          /* Show both summary and subscription stats */
          <div className='Table-Pane'>
            <div className='FinancialStatistics-ProductTitle'>
              {/* Toggle for showing/hiding subscription stats */}
              <img className="Expand-Icon" src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon" onClick={() => setRowExpanded({ [product.productid]: false })}></img>
              <div className='MySubscriptions-Pending-Logo'>
                <GetImage iconurl={product.iconurl}></GetImage>
              </div>
              {product.name}
            </div>
            {
              <div>
                <SummaryTable
                  stats = {stats[product.productid]?.stats}
                  toolTipText={toolTipText}
                ></SummaryTable>
                {
                  stats[product.productid]?.subscriptionStats.length === 0 ? (
                    <div className='Table-Subheading'>
                      No subscription statistics available
                    </div>
                  ) : (
                    <div>
                      <div className='Table-Subheading'>
                        Subscriptions
                      </div>
                      <SubscriptionTable
                        frequency={frequency}
                        stats = {stats[product.productid]?.subscriptionStats}
                        toolTipText={toolTipText}
                      ></SubscriptionTable>
                    </div>
                  )
                }
              </div>
            }
          </div>
        )
      }
    </div>
  )
}