//================================================================
//  Component: Notification Label 
//================================================================

//  Purpose: This is used to display different types of notifications, e.g. success, warning, error

//  Properties:
//    - type = {A string - success/error/warning}
//    - message = {A string - the notification message}
//    - hidden = {Boolen, hides the component}

//  Example:
//    <NotificationLabel
//      type={"warning"}
//      message={"There are currently no licenses available for this product therefore it may take longer than usual to be granted access to this product."}
//      hidden={true}
//    ></NotificationLabel>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react'

//Images
import SuccessIcon from '../Images/Icon_Success_Green.svg'
import WarningIcon from '../Images/Icon_Warning_Yellow.svg'
import ErrorIcon from '../Images/Icon_Error_Red.svg'
import InfoIcon from '../Images/Icon_Info_Blue.svg'

//CSS
import './NotificationLabel.css'


export default function NotificationLabel({
  type,
  message,
  hidden,
}) {

  //------------------------------------------------------
  // Component variables
  //------------------------------------------------------

    if (hidden === undefined) {

      hidden = false;
    
    }

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [labelHidden, setLabelHidden] = useState(hidden)

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to toggle styles for different types of notifications
    function changeStyle(type){

      if(type === 'success') {
        return {
          backgroundColor: "#DCF3D7",
          border: "1px solid #637459",
        }
      }

      else if(type === 'warning') {
        return {
          backgroundColor: "#fffbca",
          border: "1px solid #D6BA83",
        }
      }

      else if(type === 'error') {
        return {
          backgroundColor: "#FFE5E3",
          border: "1px solid #A52921",
        }
      }

      else {
        return {
          backgroundColor: "#E8F7FF",
          border: "1px solid var(--teal)",
        }
      }

    }

    //Used to toggle icons for different types of notifications
    function changeIcon(type){
      if(type === 'success') {
        return SuccessIcon
      }

      else if(type === 'warning') {
        return WarningIcon
      }

      else if(type === 'error') {
        return ErrorIcon
      }

      else {
        return InfoIcon

      }
    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    useEffect(() => {

      if (hidden === undefined) return;

      setLabelHidden(hidden)

    }, [hidden]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //Check if the labelHidden is false > Show Label
    if(!labelHidden) {
      return(
        <div className="Notification-Container" style={changeStyle(type)}>
  
          {/* Icon */}
          <div className="Notification-Icon">
            <img src={changeIcon(type)} alt="Warning-Icon"></img>
          </div>
  
          {/* Message */}
          <div className="Notification-Message">
              {message}
          </div>

          {/* Close Button */}
          <div className="Notification-Close" onClick={() => setLabelHidden(true)}>
            x
          </div>
  
        </div>
        )
    } 

    //Else > Hide Label
    else return null;

}
