//================================================================
//  Component: Page Header
//================================================================

//  Purpose: Standard component for all page headers 

//  Properties:
//    - title = {A string, page title}
//    - description = {A string, page description (optional)}
//    - icon = {A string, page icon (optional)}
//    - breadcrumbs = {A string, page breadcrumbs (optional)}

//  Example:
//    <PageHeader
//      title={"<My Customers>"}
//      description={"These all your active and inactive customers"}
//      icon={<div><div/>}
//      breadcrumbs={<div><div/>}
//    ></PageHeader>    

//================================================================


//Libraries
import React from 'react'

//CSS
import './PageHeader.css'


export default function PageHeader(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

    const title = props.title
    const description = props.description
    const icon = props.icon
    const breadcrumbs = props.breadcrumbs

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Icon Exists
    //------------------------------------------------------

        if(icon) {
            return (
                <div className='PageHeader-Container'>
    
                    {/* Breadcrumbs */}
                    <div className='PageHeader-Breadcrumbs'>
                        {breadcrumbs}
                    </div>

                    {/* Page Heading with Icon */}
                    <div className="PageHeaderWithIcon-Container">

                        {/* Icon */}
                        <div className="PageHeader-Icon">
                            <img className="Page-Icon" src={icon} alt="Product Icon"></img>
                        </div>

                        {/* Heading and Text */}
                        <div className="PageHeader-Text">
                            <div className='PageHeader-Heading'>
                                {title}
                            </div>
    
                            <div className='PageHeader-Description'>
                                {description}
                            </div>
                        </div>

                    </div>
    
                    {/* Divider */}
                    <div className='Divider'></div>
    
                </div>
            )
        }

    //------------------------------------------------------
    //  No Icon
    //------------------------------------------------------

        else {
            return (
                <div className='PageHeader-Container'>
    
                    {/* Breadcrumbs */}
                    <div className='PageHeader-Breadcrumbs'>
                        {breadcrumbs}
                    </div>
                  
                    {/* Heading */}
                    <div className='PageHeader-Heading'>
                        {title}
                    </div>

                    {/* Description */}
                    <div className='PageHeader-Description'>
                        {description}
                    </div>

                    {/* Divider */}
                    <div className='Divider'></div>
    
                </div>
            )
        } 
}


