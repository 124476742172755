//================================================================
//  Publish a Product Form 
//================================================================

//Libraries
import React, { useContext, useState, useRef } from 'react'
import { Link } from "react-router-dom";
import { Form, InputGroup, FormControl, Modal } from 'react-bootstrap'

//Contexts
import { GetUser, SetError, SetNavUpdate } from '../../Library/GlobalContexts'

//Functions
import WriteDocument from '../../Library/WriteDocument';

import {
    reStringAlphaNumSpaceHyphenSlashPara,
    errStringAlphaNumSpaceHyphenSlashPara,
    reString,
    errString,
    isStringEmail,
    reStringUnsignedInteger,
    isString,
    reStringEmail
} from '../../Library/ValidateUserInput';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import QuestionLabel from '../../Components/QuestionLabel/QuestionLabel';
import UploadIcon from '../../Components/UploadIcon/UploadIcon';
import MultiLineDot from '../../Components/MultiLineDot/MultiLineDot';
import RichTextArea from '../../Components/RichTextArea/RichTextArea';
import NotificationLabel from '../../Components/NotificationLabel/NotificationLabel';
import ProductPricing from './Components/ProductPricing';
import ProductAvailability from './Components/ProductAvailability';

//Images 
import AddButton from '../../Components/Images/Icon_Add_Teal.svg';
import AddButtonDisabled from '../../Components/Images/Icon_Add_Grey.svg';
import DeleteIcon from '../../Components/Images/Icon_Delete_Teal.svg';
import DeleteIconDisabled from '../../Components/Images/Icon_Delete_Grey.svg';

//CSS
import './Publish.css';


export default function PublishForm() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setNavUpdate = useContext(SetNavUpdate);
    const setError = useContext(SetError);

    //------------------------------------------------------
    //  useStates & global variables
    //------------------------------------------------------

    //Page status > 'onload', 'view', 'error-fatal'
    const [requestType, setRequestType] = useState('onload');

    //ProductId > Needs to remain consistent and never changes
    const productId = useRef(`prd-${Date.now()}`);

    //Icon name and url
    const [iconUrl, setIconUrl] = useState();

    //Holds the array of product owners
    const [productOwnerEmail, setProductOwnerEmail] = useState("");
    const [productOwners, setProductOwners] = useState([getUser?.emailaddress]);

    // States for email validation > 'notValid', 'existingUser', 'valid', 'notLendleaseEmail'
    const [emailValidated, setEmailValidated] = useState('notValid');

    const [productOwnerNotAdded, setProductOwnerNotAdded] = useState(false)

    //Holds the array of users who can subscribe to a private product
    const [privateUsers, setPrivateUsers] = useState([]);
    const [privateUserEmail, setPrivateUserEmail] = useState("");

    const [privateUserNotAdded, setPrivateUserNotAdded] = useState(false)

    //Holds the nominated mail address for notifications
    const [nominatedMailAddress, setNominatedMailAddress] = useState("");

    //Refs for text area validation
    const subNextStepsRef = useRef(null);
    const unsubNextStepsRef = useRef(null);
    const supportNextStepsRef = useRef(null);
    const subscriberTermsRef = useRef(null);

    //Text area values
    const [subNextSteps, setSubNextSteps] = useState(true);
    const [unsubNextSteps, setUnsubNextSteps] = useState(true);
    const [supportNextSteps, setSupportNextSteps] = useState(true);
    const [subscriberTerms, setSubscriberTerms] = useState(true);

    //Hold array > How do customers get support?
    const [searchTerms, setSearchTerms] = useState([])

    const [overview, setOverview] = useState();

    //Used to determine if product owner/nominated mail address error messages are visible
    const [productOwnerErrorHidden, setProductOwnerErrorHidden] = useState(true);
    const [nominatedMailAddressErrorHidden, setNominatedMailAddressErrorHidden] = useState(true);

    // Define what component(s) or function(s) that can raise errors in the submit modal
    const [submitState, setSubmitState] = useState({
        'validated': false,
        'preventSubmit': false,
        'modalVisible': false,
        'fatalError': undefined,
        'firebaseError': undefined,
        'productPricing': undefined,
        'event': undefined,
    });


    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    //Function to add a product owner to the list of product owners
    function addProductOwner(owner) {

        //Validate email address
        owner = owner.toLowerCase()

        if (isStringEmail(owner) && owner.includes('@lendlease.com')) {
            setProductOwnerErrorHidden(true)
        } else {
            setProductOwnerErrorHidden(false)
            return
        };

        // Add to list
        setProductOwners(productOwners => [...productOwners, owner]);

        //Clear input field
        setProductOwnerEmail("");

        setProductOwnerNotAdded(false)
        setEmailValidated("notValid")

    }


    function validateProductOwner(e) {

        e = e.toLowerCase()

        if (productOwners?.includes(e)) {
            setEmailValidated('existingUser')
        } else if (isStringEmail(e) && e.endsWith('@lendlease.com') && (!productOwners?.includes(e))) {
            setEmailValidated('valid')
        } else if (e.endsWith('.com') && !e.endsWith('@lendlease.com')) {
            setEmailValidated('notLendleaseEmail')
        } else {
            setEmailValidated('notValid')
        }

        setProductOwnerNotAdded(false);

    }


    //Event handler for field changes to nominated mail address
    function handleNominatedMailAddressChange(input, event) {

        //Validate the nominated address is not a publisher/product owner address > Show error
        if (productOwners?.filter((ownerEmail) => ownerEmail === input)?.length > 0 || !event.target.validity.valid ||
            (input.length > 0 && !isStringEmail(input))) {
            setNominatedMailAddressErrorHidden(false)

        } else {
            setNominatedMailAddressErrorHidden(true)

        }

        //Set State
        setNominatedMailAddress(input)

    }

    //Event handler submission validation for text area field changes
    function handleTextAreaChange(input, setValue, ref) {

        //Validate the text area
        if (isString(input)) {
            setValue(input)
            ref.current.setCustomValidity('')
            return false

            // Failed validation, so tag as error and prevent submission
        } else {
            setValue(input)
            ref.current.setCustomValidity('Invalid')
            return true
        }
    }


    //------------------------------------------------------
    //  Event Handler (Form Submission)
    //------------------------------------------------------

    //Submit Form > Write to Firestore
    function handleFormSubmit(e) {

        //Prevent reload, propagation & disable enter key
        e.preventDefault();
        e.stopPropagation();
        if (e.keyCode === 13) {
            return;
        }

        //Create flag for preventing submission until form validated
        let preventSubmit = false;

        if (productOwnerEmail !== "") {
            setProductOwnerNotAdded(true)
            return;
        } else {
            setProductOwnerNotAdded(false)
        }

        if (privateUserEmail !== "") {
            setPrivateUserNotAdded(true)
            return;
        } else {
            setPrivateUserNotAdded(false)
        }

        //Holds renewal month value
        let submitRenewalMonth = '-'

        //Capture submission event
        const event = e.target;

        //Check the nominated mail address is not one of the product owners && nominated mail address given when multiple owners listed 
        if (productOwners?.filter((ownerEmail) => ownerEmail === nominatedMailAddress)?.length > 0 ||
            (productOwners?.length > 1 && nominatedMailAddress?.length === 0) ||
            (nominatedMailAddress?.length > 0 && !isStringEmail(nominatedMailAddress))) {

            setNominatedMailAddressErrorHidden(false);
            preventSubmit = true;

        }

        // Check text area fields
        if (handleTextAreaChange(subNextSteps, setSubNextSteps, subNextStepsRef)) { preventSubmit = true }
        if (handleTextAreaChange(unsubNextSteps, setUnsubNextSteps, unsubNextStepsRef)) { preventSubmit = true }
        if (handleTextAreaChange(supportNextSteps, setSupportNextSteps, supportNextStepsRef)) { preventSubmit = true }
        if (handleTextAreaChange(subscriberTerms, setSubscriberTerms, subscriberTermsRef)) { preventSubmit = true }

        //Validate form inputs > If invalid -> prevent submission
        if (event.checkValidity() === false) {

            submitState.validated = true;
            setSubmitState({ ...submitState });
            return;
        }

        if (submitState.preventSubmit === true) {

            submitState.modalVisible = true;
            preventSubmit = true;

        }

        // Update 'submitState'
        submitState.validated = true;
        submitState.event = e;
        setSubmitState({ ...submitState });

        // Prevent submit of form
        if (preventSubmit) return;

        //If frequency is 'yearly' > Set renewal month
        if (event.pricingfrequency.value === 'Yearly') {

            submitRenewalMonth = event.renewalmonth.value;

        }

        //Handle 'status' field
        let status = 'published'
        if (event.status.checked) {
            status = 'hidden'
        }

        // Write disclaimer to Firestore first
        const document = {
            'acceptancedatetime': new Date(),
            'disclaimer': "By proceeding past this page, I acknowledge that I understand my responsiblities as a publisher.",
            'userid': getUser.userid,
            'termsid': getUser?.publisherstandardsaccepted,
            'items': {
                'product': productId.current
            }
        }

        WriteDocument('disclaimeracceptance', `${Date.now()}-${getUser.userid}`, document, false).then(() => {

            // Product document structure
            const document = {
                'productid': productId.current,
                'publisher': productOwners,
                'publisherstandardsaccepted': [getUser.emailaddress],
                'iconurl': iconUrl,
                'name': event.productname.value,
                'description': event.description.value,
                'overview': overview,
                'nominatedmailaddress': nominatedMailAddress,
                'pricingdiscount': event.pricingdiscount.value,
                'pricingamount': event.pricingamount.value,
                'pricingcurrency': event.pricingcurrency.value,
                'pricingfrequency': event.pricingfrequency.value,
                'pricingrenewalmonth': event.pricingfrequency.value === 'Monthly' ? '-' : submitRenewalMonth,
                'projectcode': event.projectcode.value,
                'tasknumber': event.tasknumber.value,
                'expendituretype': event.expendituretype.value,
                'pricingchanged': {
                    'effectivedate': 'n/a',
                    'notifycustomer': '',
                    'pricingdiscount': '',
                    'pricingamount': '',
                    'pricingcurrency': '',
                    'projectcode': '',
                    'tasknumber': '',
                    'expendituretype': '',
                },
                'totallicenses': event.totallicenses.value?.length > 0 ? Number(event.totallicenses.value) : 0,
                'availablelicenses': Number(event.totallicenses.value),
                'assignedlicenses': 0,
                'alertthreshold': Number(event.alertthreshold.value),
                'subnextsteps': event.subnextsteps.value,
                'unsubnextsteps': event.unsubnextsteps.value,
                'supportlink': event.supportlink.value,
                'supportemail': event.supportemail.value,
                'supportnextsteps': event.supportnextsteps.value,
                'subscriberterms': event.subscriberterms.value,
                'labels': searchTerms,
                'producturl': event.producturl.value,
                'createdate': new Date(),
                'edithistory': {
                    'userid': getUser.userid,
                    'lastmodified': new Date(),
                },
                'privateoffer': {
                    'enabled': JSON.parse(event.privateprod.value),
                    'users': JSON.parse(event.privateprod.value) ? privateUsers : [],
                },
                'status': status,
            };

            // Write product to firestore
            WriteDocument('products', productId.current, document, false).then(() => {

                setRequestType('view');
                setNavUpdate(true);
                setSubmitState({
                    'validated': false,
                    'preventSubmit': false,
                    'modalVisible': false,
                    'firebaseError': undefined,
                    'productPricing': undefined,
                })

            }).catch((error) => {

                setRequestType('onload');

                submitState.firebaseError = `${error}`;
                submitState.modalVisible = true;
                setSubmitState({ ...submitState });

                //Submit error to global store > This will be captured by ErrorHandler
                setError(`Publishing product has failed, error: ${error}`);

            });

        }).catch((error) => {

            setRequestType('error-fatal');

            //Submit error to global store > This will be captured by ErrorHandler
            setError(`Recording publisher disclaimer has failed, error: ${error}`);

        });

    };


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <PageResponse
            requestType={requestType}
            pageOnload={
                <div className='PublishForm-Container'>

                    {/* Publisher Form */}
                    <Form noValidate validated={submitState.validated} onSubmit={(e) => handleFormSubmit(e)}>

                        {/* Product Name */}
                        <QuestionLabel question='Product Name *'></QuestionLabel>
                        <div style={{ minWidth: '400px', maxWidth: '50%' }}>
                            <Form.Group className='mb-3' controlId='productname'>
                                <Form.Control required type='text' placeholder='e.g. Lendlease App' pattern={reStringAlphaNumSpaceHyphenSlashPara} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a product name. Must contain a combination of {errStringAlphaNumSpaceHyphenSlashPara}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        {/* Product URL */}
                        <QuestionLabel question='Product URL' helptext='(Optional) Allows customers to launch the application from the Marketplace store'></QuestionLabel>
                        <div style={{ minWidth: '400px', maxWidth: '50%' }}>
                            <Form.Group className='mb-3' controlId='producturl'>
                                <Form.Control type='text' placeholder='e.g. https://www.llapp.com.au' pattern={reString} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid product URL. Must contain a combination of {errString}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        {/* Icon */}
                        <QuestionLabel question='Product Icon' helptext='Upload an icon to make your product stand out in the marketplace.'></QuestionLabel>
                        <UploadIcon
                            path={`products/${productId.current}`}
                            setIconUrl={setIconUrl}
                            defaultValue={undefined}
                            required={false}
                        ></UploadIcon>

                        {/* Short description */}
                        <QuestionLabel question='Short Description *'></QuestionLabel>
                        <Form.Group className='mb-3' controlId='description'>
                            <Form.Control required type='text' maxLength={"250"} placeholder='e.g. Short description of what your product/service does.' pattern={reString} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a short description. Must contain a combination of {errString}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Overview */}
                        <RichTextArea
                            readOnly={false}
                            title='Overview *'
                            placeholder='e.g. More details on what this app can do'
                            setContent={setOverview}
                        ></RichTextArea>

                        {/* Product Owner(s) */}
                        <QuestionLabel question='Product Owner(s) *' helptext='Enter the email address of the owner(s) of this product'></QuestionLabel>
                        <div className='PublishForm-ProductOwner-Input-Container'>
                                <div 
                                style={{width: '50%'}}
                                >
                                    {/* Input Field */}
                                    <input
                                        className={productOwnerNotAdded ? 'PublishForm-Input-Field-Error' : 'PublishForm-Input-Field'}
                                        type="text"
                                        placeholder="Email address"
                                        value={productOwnerEmail}
                                        onChange={e => {
                                            setProductOwnerEmail(e.target.value)
                                            validateProductOwner(e.target.value)
                                        }}
                                    />

                                    {/* Error Message */}
                                    {
                                        productOwnerNotAdded === true || productOwnerErrorHidden === false ?
                                        (
                                            <div className='PublishForm-ErrorMessage'>
                                                Please enter a valid (@lendlease.com) email address and click the '+' button to confirm.
                                            </div>
                                        ) : null
                                    }

                                    {/* Warning Message */}
                                    {
                                        emailValidated === 'valid' ? 
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                Click the '+' button to add user
                                            </div>                                        
                                        ) 
                                        :
                                        emailValidated === 'existingUser' ? 
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                This user already exists, please try again
                                            </div>                                        
                                        )
                                        :
                                        emailValidated === 'notLendleaseEmail' ?
                                        (
                                            <div className='PublishForm-WarningMessage'>
                                                Please enter a valid (@lendlease.com) email address
                                            </div>
                                        )
                                        :
                                        null
                                    }
                                </div>
                                
                                {/* Add Button*/}
                                <div>
                                    <button style={{border: 'none', backgroundColor: 'white', padding:  'none'}} disabled={emailValidated !== 'valid' ? true : false} onClick={() => addProductOwner(productOwnerEmail)}>
                                        <img className='PublishForm-ProductOwner-Input-Container-Button' alt="Add Button" src={emailValidated !== 'valid' ? AddButtonDisabled : AddButton} ></img>
                                    </button>
                                </div>
                        </div>


                        {/* Product Owner List */}
                        <div className='PublishForm-ProductOwner-Container'>
                            {
                                productOwners?.map((owner, index) =>
                                    <div className='PublishForm-ProductOwner-Label-Container' key={index}>
                                        {owner === getUser?.emailaddress ?
                                            <>
                                                <label className='PublishForm-ProductOwner-Label-Disabled'>{owner}</label>
                                                <img src={DeleteIconDisabled} alt="Delete Icon"></img>
                                            </>
                                            :
                                            <>
                                                <label className='PublishForm-ProductOwner-Label'>{owner}</label>
                                                <img style={{ cursor: "pointer" }} src={DeleteIcon} alt="Delete Icon" onClick={() => setProductOwners((productOwners) => productOwners.filter((ownerEmail) => ownerEmail !== productOwners[index]))}></img>
                                            </>
                                        }
                                    </div>
                                )
                            }
                        </div>

                        {/* Nominated Mailbox */}
                        {/* Note* - This is required if we have more than 1 product owner */}
                        <QuestionLabel question={`Please nominate an email address for receiving notifications below. ${productOwners?.length > 1 ? '*' : ''}`} helptext='This email address will receive notifications for new subscription requests for this product. You may choose to enter a distribution list, a teams channel or a shared mailbox to manage subscription requests in a team.'></QuestionLabel>
                        <input
                            className={nominatedMailAddressErrorHidden ? 'PublishForm-Input-Field' : 'PublishForm-Input-Field-Error'}
                            style={{ width: "50%" }}
                            type='email'
                            placeholder='e.g.DLAppTeam@lendlease.com'
                            required={productOwners?.length > 1 ? true : false}
                            value={nominatedMailAddress}
                            onChange={e => handleNominatedMailAddressChange(e.target.value, e)}
                        />
                        <div hidden={nominatedMailAddressErrorHidden} className='PublishForm-ErrorMessage'>
                            Please provide a valid email address that does not belong to one of the owners listed above.
                        </div>
                        <p style={{ fontSize: "12px", color: "grey" }}>
                            Note: If you enter a distribution list, members in this group cannot approve/reject subscription requests unless they are also listed as an owner of this product above.
                        </p>

                        {/* Availability */}
                        <ProductAvailability
                            privateUsers={privateUsers}
                            setPrivateUsers={setPrivateUsers}
                            privateUserEmail={privateUserEmail}
                            setPrivateUserEmail={setPrivateUserEmail}
                            privateUserNotAdded={privateUserNotAdded}
                            setPrivateUserNotAdded={setPrivateUserNotAdded}
                        ></ProductAvailability>

                        {/* Pricing */}
                        <ProductPricing
                            setRequestType={setRequestType}
                            submitState={submitState}
                        ></ProductPricing>

                        {/* Licenses */}
                        <QuestionLabel question='Licenses' helptext='(Optional) Specify the total product licenses you can assign to users and receive alerts when a percentage of these licenses have been assigned.'></QuestionLabel>
                        <div className='PublishForm-Licenses-Container'>
                            {/* Total Licenses */}
                            <Form.Group className='mb-3' controlId='totallicenses'>
                                <QuestionLabel question='Total Licenses' helptext='(Optional) Set the maximum number of licenses to assign for your product. If left blank, there will be no limit on the number of users that can subscribe to your product.'></QuestionLabel>
                                <Form.Control
                                    style={{ marginTop: "10px" }}
                                    type='text'
                                    pattern={reStringUnsignedInteger}
                                />
                                <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                    Please enter the total number of licenses.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Alert Threshold */}
                            <QuestionLabel question='Set alert threshold rules' helptext='(Optional) Set an alert threshold for receiving alerts when a percentage of your total licenses have been assigned. If left blank, this will be set to 0% and no alerts will be issued.'></QuestionLabel>

                            <Form.Text muted style={{ fontWeight: "normal", marginBottom: "5px" }}>
                                Receive email alert notifications when a percentage of your total licenses have been assigned to users.
                            </Form.Text>

                            <InputGroup className="mb-3" controlId="alertthreshold" style={{ width: "50%" }}>
                                <FormControl
                                    required
                                    id="alertthreshold"
                                    type='text'
                                    defaultValue="0"
                                    pattern='^[0-9]$|^[1-9][0-9]$|^(100)$'
                                />
                                <InputGroup.Text>%</InputGroup.Text>
                                <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                    Please enter an alert threshold.
                                </Form.Control.Feedback>
                            </InputGroup>

                        </div>

                        {/* What happens after you become a subscriber? */}
                        <div className='PublishForm-Body-Items'>
                            <QuestionLabel question='What happens after you become a subscriber? *'></QuestionLabel>
                            <div style={{ margin: '10px 0px 20px' }}>
                                When a customer subscribes to a new product, IT Marketplace enables publishers to provide customers step-by-step of instructions. The below steps will be provided to the customer in a email and in the marketplace.
                            </div>
                            <div>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={7}
                                    name='subnextsteps'
                                    placeholder={'e.g. You will receive an email, with account information'}
                                    ref={subNextStepsRef}
                                    onChange={e => handleTextAreaChange(e.target.value, setSubNextSteps, subNextStepsRef)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a list of steps for your customer to follow when they have subscribed to your product. Must contain a combination of {errString}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        {/* What happens after a customer unsubscribes? */}
                        <div className='PublishForm-Body-Items'>
                            <QuestionLabel question='What happens after a customer unsubscribes? *'></QuestionLabel>
                            <div style={{ margin: '10px 0px 20px' }}>
                                When a customer unsubscribes from a product, IT Marketplace enables publishers to provide customers step-by-step instructions. The below steps will be provided to the customer in a email and in the marketplace.
                            </div>
                            <div>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={7}
                                    name='unsubnextsteps'
                                    placeholder={'e.g. You will lose access to the product after two days'}
                                    ref={unsubNextStepsRef}
                                    onChange={e => handleTextAreaChange(e.target.value, setUnsubNextSteps, unsubNextStepsRef)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a list of steps for your customer to follow when they have unsubscribed to your product. Must contain a combination of {errString}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        {/* How do customers get support?  */}
                        <div className='PublishForm-Body-Items'>
                            <QuestionLabel question='How do customers get support? *'></QuestionLabel>
                            <div className='PublishForm-Support-Container'>
                                <div className='PublishForm-Field' style={{ margin: '10px 0px' }}>
                                    Link
                                    <Form.Group className='mb-3' controlId='supportlink'>
                                        <Form.Control required type='text' placeholder='e.g. https://www.servicenow.com/support' pattern={reString} />
                                        <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                            Please provide a valid support link.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='PublishForm-Field' style={{ margin: '10px 0px' }}>
                                    Email Address
                                    <Form.Group className='mb-3' controlId='supportemail'>
                                        <Form.Control required type='text' placeholder='e.g. servicedesk@lendlease.com' pattern={reStringEmail} />
                                        <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                                            Please provide a valid support email address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={7}
                                    name='supportnextsteps'
                                    placeholder={'e.g. Contact support@llapp.com, you will get a response within 2 days'}
                                    ref={supportNextStepsRef}
                                    onChange={e => handleTextAreaChange(e.target.value, setSupportNextSteps, supportNextStepsRef)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide instructions on how your customers can get support for your product. Must contain a combination of {errString}
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        {/* Your Products Terms & Conditions? */}
                        <QuestionLabel question="What are your product's terms & conditions? *"></QuestionLabel>
                        <div style={{ margin: '10px 0px 20px' }}>
                            When a customer subscribes to a product, IT Marketplace allows publishers to provide terms and conditions. These conditions will be displayed to the customer before they subscribe to your product.
                        </div>
                        <div className='PublishForm-Body-Items'>
                            <Form.Control
                                required
                                as="textarea"
                                rows={7}
                                name='subscriberterms'
                                placeholder={'e.g. No copyrighted material'}
                                ref={subscriberTermsRef}
                                onChange={e => handleTextAreaChange(e.target.value, setSubscriberTerms, subscriberTermsRef)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a list of subscriber terms and conditions for your product. Must contain a combination of {errString}
                            </Form.Control.Feedback>
                        </div>

                        {/* Tags */}
                        <div className='PublishForm-Body-Items'>
                            <QuestionLabel question='Tags'></QuestionLabel>
                            <div style={{ margin: '10px 0px' }}>
                                IT Marketplace offers publishers the ability to tag a product with key words. These words are used by the IT Marketplace search.
                            </div>
                            <div>
                                <MultiLineDot setState={setSearchTerms} setPlaceHolder='e.g. Design'></MultiLineDot>
                            </div>
                        </div>

                        {/* Other settings */}
                        <div className='PublishForm-Body-Items'>
                            <QuestionLabel question='Other settings' style={{ marginBottom: "10px" }}></QuestionLabel>
                            <Form.Check
                                type="switch"
                                id="status"
                                label="Hide this product"
                            />
                        </div>

                        {/* Buttons */}
                        <div className='PublishForm-Buttons'>
                            <button className='Primary-Button' type='submit'>
                                Publish
                            </button>
                            <Link className='Secondary-Button' to="/" style={{ textDecoration: "none" }}>Cancel</Link>
                        </div>

                        {/* ================================================================= */}
                        {/*                                                                   */}
                        {/*                     Submit Handler                                */}
                        {/*                                                                   */}
                        {/* ================================================================= */}

                        {/* Invalid PTE/currency modal */}
                        <Modal show={submitState.modalVisible} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Warning
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {/*  Firebase errors */}
                                <NotificationLabel
                                    type={'error'}
                                    message={submitState.firebaseError}
                                    hidden={submitState.firebaseError === undefined ? (true) : (false)}
                                ></NotificationLabel>

                                {/* ProductPricing.js errors */}
                                <NotificationLabel
                                    type={'warning'}
                                    message={submitState.productPricing}
                                    hidden={submitState.productPricing === undefined ? (true) : (false)}
                                ></NotificationLabel>

                                {/* Toggle between try again and continue */}
                                {
                                    submitState.firebaseError === undefined ? (
                                        <p>
                                            If you wish to continue regardless, click <b>Continue</b>.
                                        </p>
                                    ) : (
                                        <p>
                                            Publishing product has failed, click <b>Try again</b> and if this issue persists contact support.
                                        </p>
                                    )
                                }
                                <p>
                                    If you would like to go back without saving your changes, click <b>Cancel</b>.
                                </p>

                            </Modal.Body>
                            <Modal.Footer>
                                <button className='Primary-Button' onClick={() => {

                                    submitState.modalVisible = false;
                                    submitState.preventSubmit = false;
                                    setSubmitState({ ...submitState });
                                    handleFormSubmit(submitState.event);

                                }}>
                                    {
                                        submitState.firebaseError === undefined ? (
                                            <>
                                                Continue
                                            </>
                                        ) : (
                                            <>
                                                Try again
                                            </>
                                        )
                                    }
                                </button>
                                <button className='Secondary-Button' variant="secondary" onClick={() => {
                                    submitState.modalVisible = false
                                    setSubmitState({ ...submitState })
                                }}>
                                    Cancel
                                </button>
                            </Modal.Footer>
                        </Modal>

                    </Form>

                </div>
            }
            // Successfully submitted form
            pageView={
                <div className='PublishForm-View-Container'>
                    Your product has been published!
                    <br></br>
                    <Link to="/home">
                        <button className='Primary-Button' style={{ marginTop: '20px' }}>Return Home</button>
                    </Link>
                </div>
            }
        ></PageResponse>
    )
}