//================================================================
//  Component: LicenseTable
//================================================================

//  Purpose: LicenseTable is a component for License Statistics page

//  Properties:

//  Example:
//    <LicenseTable></LicenseTable>    

//================================================================


//Libraries
import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import {GetUser} from '../../Library/GlobalContexts'

//Functions
import QueryDocument from  '../../Library/QueryDocument'

//CSS
import './LicenseStatistics.css'
import UsageBar from "../../Components/UsageBar/UsageBar";


export default function LicenseTable() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates & Variables
  //------------------------------------------------------

    //Controls the component status > 'pending', 'onload', 'success'
    const [requestType, setRequestType] = useState('pending');

    //Holds the products data
    const [products, setProducts] = useState();

    //Holds the sorting state for product name
    const [sortDirection, setsortDirection] = useState({ name: '', publisher: '', available: '', assigned: '', utilisation:''});

    //for navigation
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function processConditions(array){

      var arrayOfProducts = [];

      array.forEach(product => {

        // handle for undefined & zero values
        var percentuse = ''
        var usagetext = ''
        var licquantity = ''
        var alertthreshold = ''


        if ((product.totallicenses === undefined||product.totallicenses === 0) && (product.assignedlicenses === undefined||product.assignedlicenses === 0)) {
          // set this to negative value and start with 'Z' so its easy to sort it later
          percentuse = -1
          usagetext = 'Z_NA'
          licquantity = -1

        } else if ((product.assignedlicenses === undefined||product.assignedlicenses === 0) && (product.availablelicenses === undefined||product.availablelicenses === 0)) {
          let lictotal = product.totallicenses === undefined ? 0 : product.totallicenses
          
          percentuse = 0
          usagetext = `0 out of ${lictotal}`
          licquantity = lictotal
        
        // this shouldn't happen but let's handle it by calculating if it does 
        } else if (product.assignedlicenses === undefined||product.assignedlicenses === 0) {
          let lictotal = product.totallicenses === undefined ? 0 : product.totallicenses
          let licavail = product.availablelicenses === undefined ? 0 : product.availablelicenses
          let calcusage = lictotal - licavail

          percentuse = Math.round((calcusage/lictotal) * 100)
          usagetext = `${calcusage} out of ${lictotal}`
          licquantity = licavail

        } else if (product.totallicenses === undefined||product.totallicenses === 0) {
          percentuse = -1
          usagetext = `${product.assignedlicenses}`
          licquantity = -1

        } else {
          percentuse = Math.round((product.assignedlicenses/product.totallicenses) * 100)
          usagetext = `${product.assignedlicenses} out of ${product.totallicenses}`
          licquantity = product.availablelicenses === undefined ? 0 : product.availablelicenses
        }
      
        // check threshold
        if (percentuse >= 90) {
          alertthreshold = "alert"
        } else if (percentuse >= 80) {
          alertthreshold = "critical"
        } else if (percentuse >= 70) {
          alertthreshold = "warning"
        } else {
          alertthreshold = "normal"
        }

        var obj = {
          'productname': product.name,
          'productid': product.productid,
          'producticon': product.iconurl,
          'productpublisher': product.publisher[0],
          'licusagestatus': alertthreshold,
          'licusagepct': percentuse,
          'licusagetext': usagetext,
          'licavailability': licquantity,
        }

        arrayOfProducts.push(obj)
      })

      // check if empty coz we don't expect it to be
      if (!arrayOfProducts.length > 0) {
        console.log(`Unable to process raw products array`)
        setProducts([]);
        setRequestType('success');

      } else {

        // default sorted by usage percentage - descending
        const sortedArrayDesc = [...arrayOfProducts].sort((a, b) => b.licusagepct - a.licusagepct);

        setsortDirection(prev => {
          return { ...prev, utilisation: "desc" };
        });

        return sortedArrayDesc

      }

    }


    //Set the usage bar color based on alert threshold
    function handleColour(object){

      if (object === "alert") {
        return "#f90000" //redy

      } else if (object === "critical") {
        return "#f95700" //ambery

      } else if (object === "warning") {
        return "#f9ae00" //yellowy

      } else {
        return "#00B8B5" //greeny
      }

    }


    // Return the sort arrow direction
    function getArrow(direction) {

      if (direction === 'asc') {
        return '↑'

      } else if (direction === 'desc') {
        return '↓'

      } else {
        return '';

      }

    }
  

    //Sort the data based on passed key
    function sortColumn({target}){

      // Only process sorting when the onclick event is from the header buttons
      if (target.className !== undefined &&  target.className === 'Header-Button' ) {

        //set variables based on table header row attributes
        const { dataset: { label } } = target;
        var sortKey = target.getAttribute('data-key')
        var dataType = target.getAttribute('data-type')
        var sortDirection = target.getAttribute('data-sortway')

        //sorted data container
        var sortedArray = []

        //reset usestate for the sort direction
        setsortDirection({ name: '', publisher: '', available: '', assigned: '', utilisation:'' })

        //reset sort direction attribs for all headers except the currently clicked one
        //this is to enforce a stardard sort direction of asceding when clicking any header initially
        const headerRows = document.querySelectorAll("div.Header-Button")
        headerRows.forEach(function(header) {

          if (sortKey !== header.getAttribute('data-key')){
            header.setAttribute('data-sortway', '')
          }

        });


        //determine sorting direction 
        if (sortDirection === ""|| sortDirection === null ) {
          sortDirection = "asc"

        } else if (sortDirection === "asc") {
          sortDirection = "desc"

        } else {
          sortDirection = "asc"

        }

    
        // Sort the product array based on data type i.e. alpha or numeric and based on sort direction
        if (dataType === "alpha" && sortDirection === "asc") {
          sortedArray = [...products].sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

        } else if (dataType === "alpha" && sortDirection === "desc") {
          sortedArray = [...products].sort((a, b) => b[sortKey].localeCompare(a[sortKey]));

        } else if (dataType === "numeric" && sortDirection === "asc") {
          sortedArray = [...products].sort((a, b) => a[sortKey] - b[sortKey]);

        } else if (dataType === "numeric" && sortDirection === "desc") {
          sortedArray = [...products].sort((a, b) => b[sortKey] - a[sortKey]);

        } else {
          //default sort if can't determine the sorting id
          sortedArray = [...products].sort((a, b) => b.licusagepct - a.licusagepct);

        }


        // set the sort direction arrow
        if (sortDirection === "asc" || sortDirection === "desc") {

          setsortDirection(prev => {
            return { ...prev, [label]: sortDirection };
          });

          // write data attributes to table header for future reference
          target.setAttribute('data-sortway', sortDirection)


        } else {

          //default sort if can't determine the direction for some reason
          setsortDirection(prev => {
            return { ...prev, utilisation: "desc" };
          });
          target.setAttribute('data-sortway', "desc")

        }

        // finally sort the data
        setProducts(sortedArray);

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      //Conditions
      if (requestType !== 'pending') return;
      if (getUser === undefined) return;

      //------------------------------------------------------
      //  1. Get 'products' that are published
      //------------------------------------------------------

      var dbQuery = [];
      
      //Admins/stakeholders can see all products,
      //while product owners can only see the ones they own
      if (getUser?.roles?.isAdmin === true || getUser?.roles?.isStakeholder === true) {

        dbQuery = [
          ['status', '==', "published"]
        ];

      } else {
      
        dbQuery = [
          ['publisher', 'array-contains', getUser?.emailaddress],
          ['status', '==', "published"]
        ];
      
      }

      const productsPromise = QueryDocument('products', dbQuery)

      //------------------------------------------------------
      //  2. deal with the promises created above.
      //------------------------------------------------------
      Promise.resolve(productsPromise).then((products) =>{

        return products

      }).then((results) =>{

        if (results.length > 0){
          
          setProducts(processConditions(results));
          setRequestType('success');
          
        } else {

          setProducts([]);
          setRequestType('onload');
  
        }
        
      }).catch((error) =>{

        console.log(`Unable to get product information, error ${error}`)
        setProducts([]);
        setRequestType('success');
    
      });


    }, [getUser, requestType])


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //User doesnt have any active products
    if (requestType === 'pending'){
      return (
        <div className="Table-Pane">
          Loading...
        </div>
      );
    }

    //User doesnt have any active products
    if (requestType === 'onload'){
      return (
        <div className="Table-Pane">
          No records found.
        </div>
      );
    }

    //Show all active products
    if (requestType === 'success'){
      return (
        <div className="Table-Pane" style={{marginTop:"20px"}}>
          <table className='DataTable-Container' onClick={sortColumn} >
            <thead>
              <tr>
                <th>
                  <div className='Header-Button' data-key="productname" data-type="alpha" data-label="name">
                    Product Name {getArrow(sortDirection.name)}
                  </div>
                </th>
                <th>
                  <div className='Header-Button' data-key="productpublisher" data-type="alpha" data-label="publisher">
                    Publisher {getArrow(sortDirection.publisher)}
                  </div>
                </th>
                <th>
                  <div className='Header-Button' data-key="licavailability" data-type="numeric" data-label="available">
                    Available {getArrow(sortDirection.available)}
                  </div>
                </th>
                <th>
                  <div className='Header-Button' data-key="licusagetext" data-type="alpha" data-label="assigned">
                    Assigned {getArrow(sortDirection.assigned)}
                  </div>
                </th>
                <th className='Usage-Bar'>
                  <div className='Header-Button' data-key="licusagepct" data-type="numeric" data-label="utilisation">
                    Utilisation {getArrow(sortDirection.utilisation)}
                  </div>
                </th>
              </tr>
              {
                products?.map((object, index) =>(
                  <tr key={index}>
                    {/* Product Name */}
                    <td onClick={() => navigate(`/item?id=${object?.productid}`)}>
                      <div className='Product-Name-Container'>
                        <img className='Product-Name-Image' src={object?.producticon} alt={object?.productname} width="32" height="32"/>
                        <div className='Product-Name-Button'>{object?.productname}</div>
                      </div>
                    </td>

                    {/* Publisher */}
                    <td>{object?.productpublisher}</td>

                    {/* Available */}
                    <td>{
                        // Check if availability is equals to "-1"
                        (object?.licavailability === -1)
                          ? "-"
                          : object?.licavailability
                        }
                    </td>

                    {/* Assigned */}
                    <td>
                      {
                      // Check if usage text is equals to "Z_NA"
                      (object?.licusagetext === "Z_NA")
                        ? "-"
                        : object?.licusagetext
                      }
                    </td>

                    {/* Utilisation */}
                    <td className='Usage-Bar'>
                      <UsageBar bgcolor={handleColour(object?.licusagestatus)} progress={object?.licusagepct} hidden={(object?.licavailability === -1) ? true: false}/>
                    </td>
                  </tr>  
                ))
              }
            </thead>
          </table>
        </div>
      );
    }

}