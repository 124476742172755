
//================================================================
//  Component: InactiveTableRow Component
//================================================================

//  Purpose: This component is used in the 'My Customers' page by the InactiveTable Component

//  Properties:
//    - object = {A object, subscribers properties object}

//  Example:
//    <InactiveTableRow
//      object={object}
//    ></InactiveTableRow>    

//================================================================


//Libraries
import React, { useState } from 'react'

// Images
import ExpandIcon from '../../Components/Images/Icon_Expand_Black.svg'



export default function InactiveTableRow({ object }) {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    const [rowExpanded, setRowExpanded] = useState(false);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Toggle Row Open -> Close //
    const rowClickHandler = () => setRowExpanded(!rowExpanded)

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  Row Expanded
        //------------------------------------------------------
        
        if(rowExpanded) {
            return (
                <>
                    <tr className='ExpandableTable-Container-Row' onClick={rowClickHandler}>
                        <td>{object?.givenname}</td>
                        <td>{object?.surname}</td>
                        <td>{object?.email}</td>
                        <td>{object?.subscriptionenddate}</td>
                        <td style={{textAlign: "center"}}>
                            <img className="Expand-Icon" src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon"></img>
                        </td>
                    </tr> 

                    {/* Row Expanded > Subscription Details */}
                    <tr className='ExpandableTable-Container-Row-Expanded'>
                        <td colSpan="5" style={{padding: "10px 40px 30px "}}>
                            <table className="DataTable-Container">
                                <colgroup>
                                    <col span="1" style={{width: "20%"}}></col>
                                    <col span="1" style={{width: "20%"}}></col>
                                    <col span="1" style={{width: "20%"}}></col>
                                    <col span="1" style={{width: "40%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Subscription ID</th>
                                    <th>Subscription Start Date</th>
                                    <th>Subscription End Date</th>
                                    <th>Cancellation Reason</th> 
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    object?.requests.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.subscriptionid}</td>
                                            <td>{item?.subscriptionstartdate}</td>
                                            <td>{item?.subscriptionenddate}</td>
                                            <td>{item?.subscriptioncancelreason}</td>
                                        </tr>
                                     ))
                                }     
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </>
            )
        }

        //------------------------------------------------------
        //  Row Collapsed
        //------------------------------------------------------

        else {
            return (
                <tr className='ExpandableTable-Container-Row' onClick={rowClickHandler}>
                    <td>{object?.givenname}</td>
                    <td>{object?.surname}</td>
                    <td>{object?.email}</td>
                    <td>{object?.subscriptionenddate}</td>
                    <td style={{textAlign: "center"}}>
                        <img className="Expand-Icon" src={ExpandIcon} alt="Expand_Icon"></img>
                    </td>
                </tr>  
            )
        }
}
