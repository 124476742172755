//================================================================
//  Component: RatingsSimple
//================================================================

//  Purpose: This component shows the rating for a product within MySubscripions > "active" 

//  Properties:
//    - productid = {string - contains the productid}

//  Example:
//    <RatingsSimple
//      productid="string"
//    ></RatingsSimple>    

//================================================================

//Libraries
import React, { useState, useContext, useEffect } from "react";

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts';

//Functions
import CollectionListener from '../../Library/CollectionListener';
import WriteDocument from '../../Library/WriteDocument';

//CSS
import './RatingsSimple.css'

export default function RatingsDetail({productid}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
  
    //Stores the user's rating
    const [userRating, setUserRating] = useState(0);

    //Temporary use state that stores the value of the number of stars the user has hovered on
    const [hover, setHover] = useState(0);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //useEffect is triggered everytime user's rating changes > Writes rating to Firestore
    useEffect(() => {
      
      //If the user rating is undefined or equal to 0 > Don't write user rating to Firestore
      if(userRating === undefined || userRating === 0) return; 
      if(getUser === undefined) return; 
      if(productid === undefined) return;
      
      //If product does exist, create a temporary template "document"
      const document = {
        'date': new Date(),
        'emailaddress': getUser?.emailaddress,
        'rating': userRating
      }
      
      WriteDocument(`productratings/${productid}/ratings`, getUser?.emailaddress, document, true)
      .catch((error) => {

        console.log(`Failed to update users rating, error: ${error}`);
        setError(`Failed to update users rating, error: ${error}`);

      });

    // eslint-disable-next-line
    }, [userRating])
    
    //Collection Listener - Every time the 'productratings' collection changes > Reload the page with the latest metrics 
    useEffect(() => {

      //Conditions
      if (getUser === undefined) return;
      if (productid === undefined) return;

      //This runs when the page is first loaded
      function onLoadChange(documents) {

        //If the rating is 0 (unrated) skip over
        if (documents.length === 0) return;

        //Find the users rating and update
        setUserRating(
          documents.filter(object => object.emailaddress === getUser.emailaddress)[0]?.rating
        );

      }

      //Listens to 'productratings' collection for any new changes, errors will not be captured
      const unsubscribe = CollectionListener(`productratings/${productid}/ratings`, onLoadChange, onLoadChange);

      return () => {

        unsubscribe();

      }

    }, [productid, getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className="RatingsSimple-Container">     
      <div className="RatingsSimple-ClickToRate">
  
        {/* 5 Star Rating Component */}
        <div className="StarRating">

        {/* Output 5 stars by mapping over an array */}
        {[...Array(5)].map((star, index) => {
        //Start the array from 1 (user's can only rate product from 1-5)
        index += 1;
        return (
            <button
            type="button"
            key={index} //Store the value(index) of the star clicked of either "on" or "off"
            className={index <= (hover || userRating) ? "On" : "Off"}
            onClick={() => setUserRating(index)} //When a star is clicked on, set the rating
            onMouseEnter={() => setHover(index)} //Hover effect that indicates the rating that will be selected onClick
            onMouseLeave={() => setHover(userRating)}
            >
            <span className="star">&#9733;</span>
            </button>
        );
        })}           
        </div>
      </div>  
    </div>
  )
}


