//================================================================
//  Component: Maintenance Mode Modal
//================================================================

//  Purpose: Standard disclaimer when people access a development/uat environment

//  Example:
//    <GlobalMaintenanceModal></GlobalMaintenanceModal>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";

//Contexts

//Components

//Functions

//Images

//CSS


export default function GlobalMaintenanceModal() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const [showModal, setShowModal] = useState(false);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------



  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Only show the non-prod modal if it hasn't been shown before for this app instance
    //and of course if it isn't production
    useEffect(() =>{

      //Conditions
      if (process.env.REACT_APP_FIREBASE_ENVIRONMENT === undefined) return;
      if (process.env.REACT_APP_FIREBASE_ENVIRONMENT === 'PROD') return;

      setShowModal(true);

    }, []);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (showModal) {

    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Warning - non-production environment
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>
          This is the <b>{process.env.REACT_APP_FIREBASE_ENVIRONMENT} test</b> instance of the IT Marketplace. Please be aware of the following limitations:
        </p>
        <ul>
          <li>Emails will typically not be sent to publishers and subscribers</li>
          <li>Invoices will not be sent to subscribers, and no cost recharging will occur</li>
          <li>Your changes may be overwritten from time to time, eg. any products you create</li>
          <li>The IT Marketplace site may change regularly, and occasionally contain errors</li>
        </ul>
        <p>
          Click <a href="https://marketplace.lendlease.cloud/" target='_blank' rel='noopener noreferrer'>here</a> to return to the production instance of IT Marketplace.
        </p>
        </Modal.Body>
      </Modal>
    )

  } else {

    return null;

  }

}
