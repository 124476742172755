//------------------------------------------------------
//  License Use Page
//------------------------------------------------------

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import PageHeader from '../../Components/PageHeader/PageHeader';
import LicenseTable from './LicenseTable';

//CSS
import './LicenseStatistics.css';


export default function LicenseStatistics() {

  //------------------------------------------------------
  //  Hooks
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [requestType, setRequestType] = useState('pending');

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Checks people are stakeholders
    useEffect(()=>{

      //Conditions
      if( getUser === undefined ) return;

      //Allow the stakeholders to see the onload page
      setRequestType('onload');

    }, [getUser, navigate]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageHeader={
        <PageHeader
          title={"License Use"}
        ></PageHeader>
      }

      pageOnload={
        <div className='LicenseStats-Container'>
          <LicenseTable></LicenseTable>
        </div>
      }
    ></PageResponse>
  )
}
