//------------------------------------------------------
//  Financial Statistics page
//------------------------------------------------------

//Libraries
import React, {useState, useEffect, useContext} from 'react'

//Contexts
import {GetUser, SetError} from '../../Library/GlobalContexts'

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import PageHeader from '../../Components/PageHeader/PageHeader';
import TabView from '../../Components/TabView/TabView';
import FrequencyTab from './FinancialStatisticsComponents/FrequencyTab';
import GetDocument from '../../Library/GetDocument'

export default function FinancialStatistics() {
  
  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------

    //Page status > 'pending', 'onload', 'view', 'error-fatal'
    const [requestType, setRequestType] = useState('onload');
    const [rechargeDate, setRechargeDate] = useState('Checking...');

    //Text month to numerical date converter
    const monthConv = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const setError = useContext(SetError);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
    
  //On first load, get the journaling date
  useEffect(() => {

    //Check user exists
    if(getUser === undefined) return;

    //Get invoice settings
    GetDocument('invoices', 'settings')

    //Success > Get next recharge date
    .then((results) =>{
      const journalDay = results['journalday']

    //Calculate then set next recharge date - this month or next month?
    const currentDate = new Date();

    if (currentDate >= (new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), journalDay))) {
      setRechargeDate(`${journalDay} ${monthConv[currentDate.getUTCMonth() + 1]} ${currentDate.getUTCFullYear()}`)
    } else {
      setRechargeDate(`${journalDay} ${monthConv[currentDate.getUTCMonth()]} ${currentDate.getUTCFullYear()}`)
    }

    return
    })

    //Error > Show error page
    .catch((error) =>{
      //Submit error to global store > This will be captured by ErrorHandler
      setError(`Retrieving journal day from Invoice settings document has failed, error: ${error}`)

      //Call failed, so generate error page
      setRequestType('error-fatal')
      return
    })

    // eslint-disable-next-line
  },[getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageResponse
        requestType={requestType}
        pageHeader={[
          /* Header with descriptive banner */
          <PageHeader
            title={"Financial Statistics"}
            description={
              <div>
                View financial statistics for your products.<br></br><br></br>
                
                <ol>
                  <li>Select the 'Monthly' or 'Yearly' tab to see products corresponding to that billing frequency</li>
                  <li>Select the invoice period from the dropdown</li>
                  <li>Click the '&gt;' next to the product icons to see subscription level details</li>
                </ol>

                Please note the following:
                <ul>
                  <li>Any changes can take up to 24 hours to be reflected on this page</li>
                  <li>Any changes made to products and/or subscriptions will update these statistics.
                    eg. if there is a new subscriber for a product mid-month</li>
                  <li>Any products that are free to subscribe will not be shown</li>
                  <li>Any subscriptions with the same cost centre as your project will not be shown,
                    as they will not be invoiced</li>
                </ul>
                
                Approximate date when the next recharge submission to Finance will occur:&nbsp;&nbsp;
                <b>{rechargeDate}</b>
              </div>
            }
          ></PageHeader>
        ]}
        pageOnload={[
          <TabView
            defaultView={1}
            /* Monthly products tab */
            oneTitle={'Monthly'}
            oneContent = {[
              <FrequencyTab
                frequency='Monthly'
                setRequestType={setRequestType}
              ></FrequencyTab>
            ]}
            /* Yearly products tab */
            twoTitle={'Yearly'}
            twoContent={[
              <FrequencyTab
                frequency='Yearly'
                setRequestType={setRequestType}
              ></FrequencyTab>
            ]}
          ></TabView>
        ]}
      ></PageResponse>
    )
}
