//================================================================
//  Component: ProductCard
//================================================================

//  Purpose: This is the market place item on the home page

//  Properties:
//    - product= {object -> productid, name, description, status etc}
//    - isPublisher = {optional boolean, true || false}
//    - isAdmin = {optional boolean, true || false}

//  Example:
//    <ProductCard
//      product={product}
//      isPublisher={true}
//    ></ProductCard>

//================================================================

//Libraries
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Functions
import GetCollection from "../../Library/GetCollection";

//Components
import PricingTotal from '../PricingTotal/PricingTotal';

//Images
import DefaultIcon from "../../Components/Images/Icon_AppInBox_Loading.svg";
import StarFilled from "../../Components/Images/Icon_Star_Filled.svg";

//CSS
import "./ProductCard.css";

export default function ProductCard({ product, isPublisher, isAdmin }) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
    
  const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates & Variables
  //------------------------------------------------------
  
  //Used to store the product icon url
  const [imageUrl, setImageUrl] = useState(DefaultIcon);

  //Used to store the product average rating
  const [averageRating, setAverageRating] = useState(0);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------
  
   // If publisher or admin > Navigate to 'Product Page' > Else > Navigate to the 'Item'
   const clickHandler = () => { 
    if(isPublisher || isAdmin) {
      navigate(`/product?id=${product.productid}&view=overview`) 
    }
    else {
      navigate(`/item?id=${product.productid}`) 
    }
  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  //Used to set the product icon
  useEffect(() => {

    //Prevent unnecessary calls to Firebase storage
    if (product?.iconurl === undefined) return setImageUrl(DefaultIcon);

    setImageUrl(product?.iconurl);

  }, [product?.iconurl, setImageUrl]);

  //Triggered when product has information
  useEffect(() => {

    if(product === undefined) return; 
  
    //Get ratings data from productratings collection
    GetCollection(`productratings/${product.productid}/ratings`)
    .then((usersRating) =>{
      
      //if the rating is 0 (unrated) skip over
      if (usersRating.length === 0) return setAverageRating(0); 
      
      //If the rating is 5, include in array
      const fiveStarRating = usersRating.filter((user) => (user.rating === 5))
      
      //If the rating is 4, include in array
      const fourStarRating = usersRating.filter((user) => (user.rating === 4))
      
      //If the rating is 3, include in array
      const threeStarRating = usersRating.filter((user) => (user.rating === 3))

      //If the rating is 2, include in array
      const twoStarRating = usersRating.filter((user) => (user.rating === 2))

      //If the rating is 1, include in array
      const oneStarRating = usersRating.filter((user) => (user.rating === 1))

      //Calculate average of rating 
      //Count the amount of objects in each starRating array(length), add each starRating array and divide by total amount of ratings received to calculate average rating
      const ratingCalculation = ((fiveStarRating.length * 5) + (fourStarRating.length * 4) + (threeStarRating.length * 3) + (twoStarRating.length * 2) + oneStarRating.length)/usersRating.length
        setAverageRating(ratingCalculation)
    })
    .catch((error) =>{
      //We choose to ignore this error
      console.log(`Failed to get productratings collection - ${product.productid}`, error)
    });

  },[product]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className="Product-Card-Container" onClick={clickHandler}>
      
      {/* Product Card Header  */}
      <div className="Card-Header">
        <img className="Card-Icon" src={imageUrl} alt="Product-Icon"/>
        <div className="Card-Status">
          {product?.privateoffer?.enabled === true ?
          <p className="Card-Label">Private Product</p> : null
          }
          {isPublisher ?
              <p className="Card-Label">{product.status}</p> :
            isAdmin ? 
              <p className="Card-Label">Admin access</p> : null
          }
        </div>
      </div>

      {/* Product Summary */}
      <div className="Card-Summary">

        {/* Title */}
        <div className="Card-Title">
          <h6 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {product.name}
          </h6>
        </div>

        {/* Description */}
        <div className="Card-Description ">{product.description}</div>
        
        {/* Price */}
        <div className="Card-Price">
          <PricingTotal
            type='home'
            pricingdiscount={product?.pricingdiscount}
            pricingamount={product?.pricingamount}
            pricingcurrency={product?.pricingcurrency}
            pricingfrequency={product?.pricingfrequency}
            pricingchanged={product?.pricingchanged}
          ></PricingTotal>
        </div>

        {/* Ratings */}
        <div className="Card-Ratings">
          {averageRating > 0 ? 
            (
              <>
                <img src={StarFilled} alt="rating-star" />
                {averageRating}
              </>
            //only show rating if there is a rating for the product
            )
            : null
          }
        </div>
      </div>
    </div>
  );
}
