//------------------------------------------------------
//  Home Page
//------------------------------------------------------

//Libraries
import React, { useState, useEffect, useContext } from 'react';

//Contexts
import {GetUser, SetError} from '../../Library/GlobalContexts';

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse.js';
import ProductCard from '../../Components/ProductCard/ProductCard.js';

//Styles
import './Home.css';

export default function Home() {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //State used to determine current view on this page > 'pending', 'onload', 'view', 'create', 'edit', 'delete', 'manage'
    const [requestType, setRequestType] = useState('pending');

    //State used to store list of all products
    const [allProducts, setAllProducts] = useState([]);

    // Used by search to manipulate the results
    const [products, setProducts] = useState([]);

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Prevents a crash if the users profile isnt complete
    function GetName(){
      
      if (getUser === undefined) return;
      if (getUser.givenname === undefined) return;
      if (getUser.givenname === null) return;

      return `Hi, ${getUser?.givenname[0]?.toUpperCase() + getUser?.givenname?.slice(1)}!`;

    }

    // Handles resetting the searchbox
    function HandleClearSearch(event) {

      if(event.target.value === '') return setProducts([...allProducts]);

    }

    // Handles updating the searchbox
    function HandleSearch(event) {

      if(event.keyCode !== 13) return;

      const searchValue = event.target.value.toLowerCase().trim();
      const results = [];

      products.forEach(product =>{

        const productName = product?.name.toLowerCase();
        const productLabels = product?.labels

        // Prevent duplicates and crashes
        if(productName === undefined) return setProducts([]);
        if (results.indexOf(productName) !== -1) return;

        // 1. Find the product name
        if (productName.includes(searchValue)) {

          results.push(product);

        }

        // 2. Find all products that contain a particular tag, IF the product has tags!
        if (productLabels.length === 0) return;

        productLabels.forEach(label => {
          
          if (label.toLowerCase().includes(searchValue)) {

            if (results.indexOf(product) !== -1) return;
            results.push(product);
  
          }

        });

      });

      // Return results
      setProducts([...results]);

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When requestType changes > get list of all products
    useEffect(() => {

      if (getUser === undefined) return;
      if (getUser?.emailaddress === undefined) return;

      setRequestType('onload');

      if(requestType === 'pending'){

        // Find all available products > WITHOUT a privateoffer!
        const publicProductsPromise = QueryDocument('products', [
          ['privateoffer.enabled', '==', false],
          ['status', '==', 'published'],
        ]);

        // Find all available products > WITH a privateoffer!
        const privateProductsPromise = QueryDocument('products', [
          ['privateoffer.enabled', '==', true],
          ['privateoffer.users', 'array-contains', getUser?.emailaddress],
          ['status', '==', 'published'],
        ]);

        Promise.all([publicProductsPromise, privateProductsPromise]).then((results) =>{

          const publicProducts = results[0];
          const privateProducts = results[1];
          const sortedProducts = [...publicProducts, ...privateProducts].sort((a, b) => a.name.localeCompare(b.name, 'en', { sensivity: 'accent' }))

          setAllProducts(sortedProducts);
          setProducts(sortedProducts);
          setRequestType('onload');

        }).catch((error) =>{

          setRequestType('error-fatal');

          //Submit error to global store > This will be captured by ErrorHandler
          setError(`GetCollection for products has failed, error: ${error}`);

        });

      }

    },[requestType, getUser, setError]);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageHeader={
        <div className='Home-Header'>
          <h2>{GetName()}</h2>
          <p>Welcome to the IT Marketplace</p>
          <div className='Divider'></div>
        </div>
      }
      pageOnload={
        <div className='Home-Container'>
            <div className='Home-Subheading'>
              
              {/* Change the title based off the number of returned products */}
              {
                allProducts.length === products.length ? (
                  <>All Products</>
                ) : (
                  <>{products.length} of {allProducts.length} products found</>
                )
              }

              {/* Searchbox */}
              <input 
                className='Search'
                placeholder="Search" 
                type='search' 
                onKeyDown={(e) => HandleSearch(e)}
                onChange={(e) => HandleClearSearch(e)}
              ></input>

            </div>

            {/* Products Catalogue */}
            <div>
              {products.length > 0 ? 
                (
                  //If products found > return list of products
                  <div className='Home-Products-Container'>
                    {products.map((object, index) => (
                      <ProductCard
                        key={index}
                        product={object}
                        isPublisher={false}
                      ></ProductCard>
                    ))}
                  </div>
                )
                : (
                  // Else > return message
                  <div style={{margin: "10px 0px", textAlign: "left"}}>
                    No results found.
                  </div>
                )}
            </div>

        </div>
      }
    ></PageResponse>
  )

  //------------------------------------------------------
}