//================================================================
//  AuthProvider
//  Created by Mark Bennett
//================================================================

//  Purpose: 
//    1. This component resides in index.js
//    2. Handles any auth interactions with FireBase
//    3. Saves all interactions to the global store

//  Example: 
//    ReactDOM.render(

//      <GlobalContexts>
//        <AuthProvider>
//            <LoginHandler></LoginHandler>
//        </AuthProvider>
//      </GlobalContexts>,

//    document.getElementById('root')
//    );

//================================================================

//Libraries
// eslint-disable-next-line
import React, { useContext, useEffect } from 'react'
import { getAuth, onAuthStateChanged, OAuthProvider } from 'firebase/auth';

//Functions
import GetDocument from './GetDocument';
import WriteDocument from './WriteDocument';
import DocumentListener from './DocumentListener';

//Contexts
import {SetFireBaseProvider, SetFireBaseUser, SetUser, GetAppStatus, SetAppStatus, SetAppErrors, GetUser} from './GlobalContexts';


export default function AuthProvider({children}) {

  //------------------------------------------------------
  //  Firebase
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setFirebaseUser = useContext(SetFireBaseUser);
    const setUser = useContext(SetUser);
    const getUser = useContext(GetUser);
    const setContextProvider = useContext(SetFireBaseProvider);
    const getAppStatus = useContext(GetAppStatus);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  Find the users current auth state when the apps auth state changes
  //------------------------------------------------------

    useEffect(() => {

      onAuthStateChanged(auth, (user) =>{

        //Successful sign in 
        if(user){

          //------------------------------------------------------
          //  Save Firebase profile to useContext
          //------------------------------------------------------

          setFirebaseUser(user);

          //------------------------------------------------------
          //  1. Check for existing user profile
          //  2. Update exising user profile
          //------------------------------------------------------

          GetDocument('users', user.uid).then((results) =>{

            const userProfile = results;
            //We have the complete user profile
            //Save to context and update in Firestore
            //Includes updates to userid and emailaddress if applicable
            //Status set to 'pending' until it's confirmed that the user has an AAD account

            // Also update the user context
            userProfile.userid = user.uid;
            userProfile.emailaddress = user.email;
            userProfile.lastsignindate = new Date();
            userProfile.status = 'pending';

            return WriteDocument('users', user.uid, userProfile, true).then(() =>{

              setUser(userProfile);
              setAppStatus('authenticated');

            }).catch((error) =>{

              //Failed to update user profile > prevent sign in
              setAppStatus('failed');
              setAppErrors(`Unable to update user profile, error: ${error}`);

            });

          }).catch(() =>{

            //------------------------------------------------------
            //  1. User document didnt exist
            //  2. Create a new user document
            //  Note: We assume the information in Firebase is valid for now
            //------------------------------------------------------

            //Status set to 'pending' until it's confirmed that the user has an AAD account
            const document = {
              'userid': user.uid,
              'emailaddress': user.email,
              'lastsignindate': new Date(),
              'status': 'pending',
              // Note that the create date will be written by the Azure check
            };

            return WriteDocument('users', user.uid, document, true).then((results) =>{

              setUser(document); // Note that this is not the full user document at this stage
              setAppStatus('authenticated');

            }).catch((error) =>{

              //Failed to create user profile > prevent sign in
              setAppStatus('failed');
              setAppErrors(`Unable to create user profile, error: ${error}`);

            });

          });
        }

        //Ask the user signin
        else {

          setAppStatus('unauthenticated');
    
        }

      });

    }, [auth, setAppStatus, setFirebaseUser, setUser, setAppErrors]);

    //Save the auth state to session storage
    //This allows us to presist data after refreshes
    useEffect(() => {     
    
      sessionStorage.setItem('getAppStatus', getAppStatus);

    }, [getAppStatus]);

    //------------------------------------------------------
    //  Listens for any changes to to the user collection
    //  Update the user context with current data if retrieved from AAD
    //  If the user is marked inactive (no AAD user found), generate an error
    //  If the user is pending, still waiting for AAD data, so no action
    //  If the user is active, happy days
    //------------------------------------------------------
  
    useEffect(() => {     
    
      if (getAppStatus !== 'authenticated') return;
      if (getUser === undefined) return;

      // OnloadChange function to get initial state of the document or any changes
      function onLoadChange(document){

        if (document.status === 'inactive') {

          // User not in Azure AD - this is an issue, so immediately error out
          setAppStatus('failed');
          setAppErrors('User not found in Azure AD');

        } else if (document.status === 'active') {

          setUser(document);

        }

      }
      
      // Handle errors
      function onError(error){

        // User not in Azure AD - this is an issue, so immediately error out
        setAppStatus('failed');
        setAppErrors(error);

      }

      const unsubscribe = DocumentListener('users', getUser.userid, onLoadChange, onLoadChange, onError);

      // Remove document listener if the component 'unMounts'
      return () =>{

        unsubscribe();

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAppStatus]);

  //------------------------------------------------------
  //  Define firebase OAuthProvider > 'microsoft.com'
  //------------------------------------------------------

    useEffect(() => {

      //We want to use the 'OAuthProvider' > 'microsoft.com'
      const Provider = new OAuthProvider('microsoft.com');
      Provider.setCustomParameters(({
        tenant: process.env.REACT_APP_FIREBASE_AZURE_TENANT_ID,
      }));

      //Add scopes
      Provider.addScope('email');
      Provider.addScope('openid');
      Provider.addScope('profile');
      Provider.addScope('User.Read');

      //Save to useContext
      setContextProvider(Provider);

    }, [setContextProvider]);

  //------------------------------------------------------
  //  Pass down all Parent components to children
  //------------------------------------------------------

    return children;

  //------------------------------------------------------
}
