
//================================================================
//  Component: InactiveTable Component
//================================================================

//  Purpose: This component is used by 'My Customers' page

//  Properties:
//    - projectid = {A string, the unique product id}

//  Example:
//    <InactiveTable
//      projectid={"prd-1234567890"}
//    ></InactiveTable>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react'

//Components
import Tooltip from '../../Components/Tooltip/Tooltip';
import InactiveTableRow from './InactiveTableRow';

//Functions
import QueryListener from '../../Library/QueryListener';
import ConvertDate from '../../Library/ConvertDate';

//CSS
import './MyCustomers.css'


export default function InactiveTable({
  productid
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Handles the component status > 'pending', 'onload', 'success', 'error'
    const [requestType, setRequestType] = useState('pending');
    const [subscribers, setSubscribers] = useState([]);
 

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() =>{

      //Conditions
      if (productid === undefined) return;

      function onLoadChange(subscriptions){

        if (subscriptions.length > 0){
         
          // Initialise new set to hold unique customers //
          const unique = new Set();

          // Filter subscribers array to return unique values only -> i.e no duplicates //
          const filteredArray = subscriptions.filter(object => {
            const duplicate = unique.has(object?.subscriber?.emailaddress);
            unique.add(object?.subscriber?.emailaddress)
            return !duplicate
          })

          // Loop over the filtered array and map all subscriptions that match the customers email address //
          let subscriberProperties = filteredArray
            .sort((a, b) => a.subscriber?.givenname.localeCompare(b.subscriber?.givenname))
            .sort((a, b) => a.subscriber?.surname.localeCompare(b.subscriber?.surname))
            .map(object => {

            // email match check //
            const allSubs = subscriptions
              .sort((a, b) => b.enddate.toDate() - a.enddate.toDate())
              .filter(item => {
                return item?.subscriber?.emailaddress === object?.subscriber?.emailaddress
              })

            // return new data object containing all subscriptions relevant to the given customer //
              return {
                "email": object?.subscriber?.emailaddress,
                "givenname": object?.subscriber?.givenname,
                "surname": object?.subscriber?.surname,
                "subscriptionenddate": ConvertDate(allSubs[0].enddate?.toDate()),
                "requests": allSubs.map(object => {
                return {
                  "subscriptionid": object?.subscriptionid,
                  "subscriptionstartdate": ConvertDate(object?.startdate?.toDate()),
                  "subscriptionenddate": ConvertDate(object?.enddate?.toDate()),
                  "subscriptioncancelreason": ConvertDate(object?.cancelreason)
                }
              }) 
            }
          });
    
          setSubscribers(subscriberProperties);
          setRequestType('success');
          
        } else {

          setRequestType('onload');

        }

      }

      function onError(error){

        console.log(`Unable to get subscriber information, error ${error}`)
        setRequestType('success');

      }

      //Get Subscribers
      const unsubscribe = QueryListener('subscriptions', [
        ['productid', '==', productid],
        ['status', 'in', ['cancelled']]
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };

    }, [productid]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='Table-Pane'>

        {/* Table Heading */}
        <div className="Table-Title">
          Inactive Customers
          <Tooltip
            helpText="Customers that have unsubscribed to your product."
          ></Tooltip>
        </div>      

        {/* Inactive Table */}
        {
          // Loading screen
          requestType === 'pending' ? (

            <table className='ExpandableTable-Container'>
              <colgroup>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "40%"}}></col>
                <col span="1" style={{width: "20%"}}></col>
                <col span="1" style={{width: "10%"}}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Subscription End Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    Loading...
                  </td>
                </tr>
              </tbody>
            </table>
          
          // No results
          ): requestType === 'onload' ? (
            
            <div>
              No records found...
            </div>
          ):

          // Failed to get results
          requestType === 'error' ? (
            
            <table className='ExpandableTable-Container'>
              <colgroup>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "40%"}}></col>
                <col span="1" style={{width: "20%"}}></col>
                <col span="1" style={{width: "10%"}}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Subscription End Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    Failed to retrieve records!
                  </td>
                </tr>
              </tbody>
            </table>
          ): 
          
          // Success > show table rows
          (
            <table className='ExpandableTable-Container'>
              <colgroup>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "40%"}}></col>
                <col span="1" style={{width: "20%"}}></col>
                <col span="1" style={{width: "10%"}}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Subscription End Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  subscribers.map((item, index) => (
                    <InactiveTableRow key={index} object={item}/>
                  ))
                } 
              </tbody>
            </table>
          )
        }
      </div>
    )
}
