//================================================================
//  Component: Upload File
//  Created by Mark Bennett
//================================================================

//  Purpose: This component handles the uploading of files to a GCS bucket

//  Properties:
//  - path = {String, folder path in the GCS bucket}
//  - setIconUrl = {useState, this will contain the uploaded files url}
//  - defaultValue = {object, the firebase storage url and icon name}
//  - required = {boolen, do users need to complete this field}

//  Example:
//    <UploadIcon
//      path='uploads/images'
//      setIconUrl={setIconUrl}
//      defaultValue={'https://firebase-storage-url'}
//      required={true}
//    ></UploadIcon>

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';
import {Form} from 'react-bootstrap';

//Contexts

//Components

//Functions
import UploadFile from '../../Library/UploadFile';

//Images
import CloseIcon from '../Images/Icon_Close_White.svg';
import AddIcon from '../Images/Icon_Add_Grey.svg';

//CSS
import './UploadIcon.css';


export default function UploadIcon({
  path,
  setIconUrl,
  defaultValue,
  required,
}) {

  //------------------------------------------------------
  //  useStates & useRef
  //------------------------------------------------------

    // Handles the state of the upload > 'onload', 'onchange', 'pending', 'error-fatal'
    const [uploadStatus, setUploadStatus] = useState('onload');

    // The file url
    const [fileUrl, setFileUrl] = useState();

    // Any error messages
    const [errorMessage, setErrorMessage] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleChange(file){

      //------------------------------------------------------
      // Validate incoming file
      //------------------------------------------------------

      if (!file) return setErrorMessage('Select a file');
      
      let fileType;
      try {
        fileType = file.name.split('.')
        fileType = fileType[fileType.length - 1].toLowerCase();

      } catch (error) {
        
        return setErrorMessage('Supported file types, svg, png, jpeg, jpg and gif.');

      }

      //------------------------------------------------------
      // Client side validation --> Otherwise the upload MUST complete before the storage rules reject the request
      //------------------------------------------------------

      if (file.size > 5000000) return setErrorMessage('File must be smaller then 5MB');
      if ( fileType !== 'svg' && fileType !== 'png' && fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'gif') return setErrorMessage('Supported file types, svg, png, jpeg, jpg and gif.');

      //------------------------------------------------------
      // Passed all checks > proceed with upload
      //------------------------------------------------------

      setErrorMessage('');
      setUploadStatus('pending');

      const filename = `${Date.now()}.${fileType}`

      //Upload file
      UploadFile(`${path}/${filename}`, file).then((url) =>{

        setFileUrl(url);
        setIconUrl(url);
        setUploadStatus('onload');

      }).catch((error) =>{

        setErrorMessage(error.message);
        setUploadStatus('error-fatal');

      });

    }

    //Try again button handler
    function HandleUploadStatus(status){

      setErrorMessage('');
      setUploadStatus(status);

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // 'onLoad' > Check if there is a defaultValue
    useEffect(()=>{

      //Conditions
      if (defaultValue === undefined) {

        setFileUrl(process.env.REACT_APP_FIREBASE_DEFAULT_ICON_URL);
        setIconUrl(process.env.REACT_APP_FIREBASE_DEFAULT_ICON_URL);
        setUploadStatus('onload');

      } else {

        setFileUrl(defaultValue?.iconurl);
        setIconUrl(defaultValue?.iconurl);
        setUploadStatus('onload');

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  onload
    //------------------------------------------------------

      if (uploadStatus === 'onload') {
        return(
          <div className='Upload-Container-Success'>

            <img 
              className="Upload-Success-Icon" 
              alt="edit-icon" 
              src={fileUrl}
            ></img>
            <img style={{width: '50px', cursor: 'pointer'}} 
              alt="edit-icon" src={AddIcon} 
              onClick={() => HandleUploadStatus('onchange')}
            ></img>

          </div>
        )
      }

    //------------------------------------------------------
    //  onchange
    //------------------------------------------------------

      else if (uploadStatus === 'onchange') {
        return (
          <Form.Group controlId='formFile' className='mb-3'>

            <div className='Upload-Onload-Container'>
              <Form.Control 
                required={errorMessage.length > 0 ? (true):(required)}
                isInvalid={errorMessage.length > 0 ? (true):(false)} 
                onChange={(e) => handleChange(e.target.files[0])} 
                type='file'
              />
              <img 
                style={{width: '30px', marginLeft: '5px', cursor: 'pointer'}} 
                src={CloseIcon} alt='close-icon' 
                onClick={() => HandleUploadStatus('onload')}
              ></img>
            </div>
            
            <Form.Label className='Upload-Error-Message'>{errorMessage}</Form.Label>
            
          </Form.Group>
        )
      }

    //------------------------------------------------------
    //  pending
    //------------------------------------------------------

      else if (uploadStatus === 'pending') {
        return (
          <Form.Group controlId='formFile' className='mb-3'>

            <Form.Control required={true} hidden={true}/>
              Uploading...
            <Form.Control.Feedback className='Upload-Error-Message' type="invalid">
              Please wait for upload to complete
            </Form.Control.Feedback>

          </Form.Group>
        )
      }

    //------------------------------------------------------
    //  error-fatal
    //------------------------------------------------------

      else if (uploadStatus === 'error-fatal') {
        return(
        <Form.Group controlId='formFile' className='mb-3'>

          <Form.Control required={true} hidden={true}/>

          <button className="Primary-Button" onClick={() => HandleUploadStatus('onchange')}>Choose File</button>
          <div className='Upload-Error-Message'>
            Failed to upload file, please try again
          </div>

        </Form.Group>
        )
      }

    //------------------------------------------------------
}
