//------------------------------------------------------
//  Publish a Product Page
//------------------------------------------------------

//Libraries
import React, { useState, useContext } from 'react'
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap"

//Contexts
import {GetUser, SetUser, SetError} from '../../Library/GlobalContexts'

//Functions
import QueryDocument from '../../Library/QueryDocument'
import WriteDocument from '../../Library/WriteDocument'
import GetDocument from '../../Library/GetDocument'

//Components
import PageResponse from '../../Components/PageResponse/PageResponse.js'
import TermsConditions from './TermsConditions'
import NotificationLabel from '../../Components/NotificationLabel/NotificationLabel'
import PublishForm from './PublishForm'

//Styles
import './Publish.css'
import PageHeader from '../../Components/PageHeader/PageHeader';

export default function Publish() {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
    
    //State used to determine current view on this page > "pending", "onload", "view", "create"
    const [requestType, setRequestType] = useState("onload");

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function AcceptTermsConditions(){

      setRequestType('pending');

      // Get the publisher T&C's //
      QueryDocument('terms', [['type', '==', 'publisher']], ['termsid', 'desc'], 1).then((results) =>{
        
        // Append the termsid into the user document //
        const document ={
          'publisherstandardsaccepted': results[0].termsid
        };

         //Update the users document
        WriteDocument('users', getUser.userid, document, true).then(() =>{
          
          //Get the users document > Save to global store & load create page
          GetDocument('users', getUser.userid).then((results) =>{

            setUser(results);
            setRequestType('create');

          });


        }).catch((error) =>{

          setRequestType('error-fatal');
          setError(`Unable to update and/or get user profile, error ${error}`);

        });


      }).catch((error) =>{

      setRequestType('error-fatal');
      setError(`Unable to get terms collection, error ${error}`);

      }); 
    }


    //Show different messages to users based on the ITM environment, i.e. dev, uat and prod
    function handleWarningMessage(environment){

      if(environment === 'PROD'){
          return (
              <NotificationLabel
                  type={"info"}
                  message={
                      <div>
                        This is the production instance of the IT Marketplace.
                        If you are looking for a safe space to experiment with what the marketplace has to offer, please subscribe to the IT Marketplace UAT product <a href="https://marketplaceuat.lendlease.cloud/" target='_blank' rel='noopener noreferrer'>here</a>.
                      </div>
                  }
              ></NotificationLabel>
          )
      }

      else if (environment === 'UAT'){
          return (
              <NotificationLabel
                  type={"info"}
                  message={
                      <>
                          This is a test instance of the IT Marketplace.
                          Please note this environment is regularly updated with changes.
                      </>
                  }
              ></NotificationLabel>
          )
      }

      else {
          return null
      }

  }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageOnload={
        <div className='Publish-Container-Onload'>

          {/* Page Header */}
          <div className='Publisher-Header'>
            <PageHeader
              title="Terms & Conditions"
                breadcrumbs={
                  <Breadcrumb>
                      <Breadcrumb.Item style={{textDecoration: "none"}}>
                          <Link to="/">
                              Publish a Product
                          </Link>
                      </Breadcrumb.Item>
                    <Breadcrumb.Item active>Terms & Conditions</Breadcrumb.Item>
                  </Breadcrumb>
                }
            ></PageHeader>
          </div>

          {/* Publisher Agreement */}
          <div className='Publish-Agreement'>

            {/* Subheading */}
            <div className='Publish-Subheading'>Publisher Terms and Conditions</div>

            {/* T&C's */}
            <TermsConditions/>
            
            {/* Accept or Decline */}
            <div className='Publish-Acceptance'>

              {/* Warning Message for Users*/}
              {handleWarningMessage(process.env.REACT_APP_FIREBASE_ENVIRONMENT)}
  
              By proceeding past this page, I acknowledge that I understand my responsiblities as a publisher.
              <div style={{margin: "15px 0px"}}>
                  <button className='Primary-Button' onClick={() => AcceptTermsConditions()}>Accept</button>
                  <Link to="/home">
                      <button className='Secondary-Button'>Decline</button>
                  </Link>
              </div>

            </div>

          </div>
        </div>
      }
      pageCreate={
        <div>
            {/* Page Header */}
            <PageHeader
              title={"Publish a Product"}
              description={
              <>
                  Please fill in the details below and click 'Publish' to publish your product.
              </>
              }
            ></PageHeader> 

            {/* Publish a Product Form */}
            <PublishForm></PublishForm> 
        </div>
      }
    ></PageResponse>
  );

}
