//================================================================
//  Component: Get Image
//================================================================

//  Purpose: Child component of 'Sub Card Active' in the 'My Subscriptions' page
//           Also used by 'FrequencyTab' in the 'Financial Statistics' page
//  Supports a default icon, while we retrieve the image from GCS

//  Properties:
//    - iconurl = {String, the firbase storage bucket url}

//  Example:
//    <GetImage
//      iconurl={"https://firebase-url"}
//    ></GetImage>

//================================================================


//Libraries
import React, { useState, useEffect } from 'react'

//Contexts

//Components

//Functions

//Images
import DefaultIcon from '../Images/Icon_AppInBox_Loading.svg'


export default function GetImage(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

    const iconurl = props.iconurl

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [image, setImage] = useState(DefaultIcon);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() =>{

      //Conditions
      if (image === undefined) return;
      if (iconurl === undefined) return;

      setImage(iconurl);

    }, [image, iconurl]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <img src={image} alt='product-icon'></img>
    );

}
