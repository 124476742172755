//------------------------------------------------------
//  Product Page (For Publishers)
//------------------------------------------------------

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

//Contexts
import { GetUser, SetUser, SetError } from '../../Library/GlobalContexts'

//Functions
import QueryDocument from '../../Library/QueryDocument'
import WriteDocument from '../../Library/WriteDocument'
import GetDocument from '../../Library/GetDocument';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse'
import PageHeader from '../../Components/PageHeader/PageHeader';
import TabView from '../../Components/TabView/TabView';
import ProductsEdit from './ProductsEdit';
import MyCustomers from '../MyCustomers/MyCustomers';
import CustomerRequests from '../CustomerRequests/CustomerRequests';
import TermsConditions from '../Publish/TermsConditions';

//CSS
import './ProductPage.css'
import '../Publish/Publish.css'


export default function ProductPage() {

  //------------------------------------------------------
  //  UseParams from 'react-router'
  //------------------------------------------------------

    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useContexts & Functions
  //------------------------------------------------------
  
  const getUser = useContext(GetUser);
  const setUser = useContext(SetUser);
  const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------

    const disclaimer = "By proceeding past this page, I acknowledge that I understand my responsiblities as a publisher."

    //Page status > 'pending', onload', 'view', 'error-fatal'
    const [requestType, setRequestType] = useState('pending');

    //Product ID > Needs to remain consistent and never changes
    const [productObject, setProductObject] = useState();

    //View > Determines the page view
    const [view, setView] = useState();

    //Records if the requester is the product owner
    const [isOwner, setIsOwner] = useState(false)

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function AcceptTermsConditions(){

        setRequestType('pending');

        let queryPromises = [];

        // Get the publisher T&C's
        queryPromises.push(QueryDocument('terms', [['type', '==', 'publisher']], ['termsid', 'desc'], 1).catch((error) => {

            throw new Error(`Unable to get terms collection, error ${error}`);

        }));

        // Get relevant product-owner-change audit log entries that require updating
        queryPromises.push(QueryDocument('product-owner-change', [
            ['productid', '==', productObject.productid],
            ['publisherstandardsaccepted', '==', false],
            ['modifiedmember', '==', getUser.emailaddress],
            ['action', '==', 'add']
        ]).catch((error) => {

            throw new Error(`Unable to query product-owner-change collection, error ${error}`);

        }));

        Promise.all(queryPromises).then((results) => {

            const terms = results[0];
            const productOwnerChanges = results[1];

            // Write disclaimer to Firestore
            let disclaimerDocument = {
                'acceptancedatetime': new Date(),
                'disclaimer': disclaimer,
                'userid': getUser.userid,
                'termsid': terms[0].termsid,
                'items': {
                    'product': productObject.productid
                }
            };

            let writePromises = [];

            writePromises.push(WriteDocument('disclaimeracceptance',
                `${Date.now()}-${getUser.userid}`, disclaimerDocument, false).catch((error) => {

                throw new Error(`Recording publisher disclaimer has failed, error: ${error}`);
        
            }));
            
            // Append the termsid into the user document
            const userDocument = {
                'publisherstandardsaccepted': terms[0].termsid
            };
                
            writePromises.push(WriteDocument('users', getUser.userid, userDocument, true).catch((error) => {

                throw new Error(`Unable to update and/or get user profile, error ${error}`);
        
            }));

            // Update latest relevant product-owner-change audit log entry
            if (productOwnerChanges.length) {
            
                const auditDocument = {
                    'publisherstandardsaccepted': true
                };

                console.log(productOwnerChanges[productOwnerChanges.length - 1].docid)

                writePromises.push(WriteDocument('product-owner-change',
                    productOwnerChanges[productOwnerChanges.length - 1].docid, auditDocument, true).catch((error) => {

                    throw new Error(`Unable to update project owner change audit logs, error ${error}`);

                }));

            }

            // Resolve all write promises
            // ie. you can't access the product document until all above requirements are met
            return Promise.all(writePromises);

        }).then(() => {

            // Update acceptance in product document
            if (!Array.isArray(productObject.publisherstandardsaccepted)) productObject.publisherstandardsaccepted = [];

            productObject.publisherstandardsaccepted.push(getUser.emailaddress);
            setProductObject(productObject);

            const productDocument = {
                'publisherstandardsaccepted': productObject.publisherstandardsaccepted
            };

            return WriteDocument('products', productObject.productid, productDocument, true).catch((error) => {

                throw new Error(`Updating product with disclaimer status has failed, error: ${error}`);

            });
        
        }).then(() => GetDocument('users', getUser.userid).catch((error) => {

                throw new Error(`Unable to get user profile, error ${error}`);
            
            })
        
        ).then((results) => {

            // Save user document to global store & load tabbed products page
            setUser(results);
            setRequestType('view');

        }).catch((error) => {

            setRequestType('error-fatal');

            // Submit error to global store > This will be captured by ErrorHandler
            setError(error);

        });

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Extract productid from url and get information from the database
    useEffect(()=>{

        if (searchParams.get('id') === undefined) return;

        //Get product document > extract product ID
        GetDocument('products', searchParams.get('id'))
        .then((product) =>{

            //Set product ID and object
            setProductObject(product);

            //Determine if they are an owner
            setIsOwner(product.publisher?.filter((ownerEmail) => ownerEmail === getUser.emailaddress)?.length > 0)

            // Check to see if the user needs to accept T&Cs first (admins never have to)
            if (getUser?.roles?.isAdmin === true ||
                product.publisherstandardsaccepted?.filter((ownerEmail) => ownerEmail === getUser.emailaddress)?.length > 0) {
                setRequestType('view')
            } else {
                setRequestType('onload')
            }

        })
        .catch((error) =>{

            console.log("ProductsEdit - Failed to find product id, error", error)

            setRequestType('error-fatal');
            setError(`ProductsEdit - Failed to find product id, error ${error}`)

        });

        // eslint-disable-next-line
    }, [searchParams, setError]);

    //Extract the 'view' from the url > Set page view
    useEffect(() => {

        const urlView = searchParams.get('view')
      
        if (urlView === undefined) return;

        if (urlView === "overview") return setView(1);
        if (urlView === "customerrequests" && isOwner) return setView(2);
        if (urlView === "mycustomers" && isOwner) return setView(3);

    }, [searchParams, isOwner])
    

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <PageResponse
            requestType={requestType}
            pageHeader={
                <PageHeader
                    title={productObject?.name}
                    description={productObject?.description}
                    icon={productObject?.iconurl}
                    breadcrumbs={
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/products">
                                    My Published Products
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{productObject?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    }
                ></PageHeader>
            }
            pageOnload={
                /* Publisher Agreement */
                <div className='Publish-Container-Onload'>
                    <div className='Publish-Agreement'>
            
                        {/* Subheading */}
                        <div className='Publish-Subheading'>Publisher Terms and Conditions</div>
            
                        {/* T&C's */}
                        <TermsConditions/>
                        
                        {/* Accept or Decline */}
                        <div className='Publish-Acceptance'>
            
                            {disclaimer}
                            <div style={{margin: "15px 0px"}}>
                                <button className='Primary-Button' onClick={() => AcceptTermsConditions()}>Accept</button>
                                <Link to="/home">
                                    <button className='Secondary-Button'>Decline</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
            pageView={
                isOwner ? (
                    <TabView
                        defaultView={view}
                        oneTitle={"Overview"}
                        oneContent={                
                            <ProductsEdit isOwner={isOwner} setIsOwner={setIsOwner}/>
                        }
                        twoTitle={"Customer Requests"}
                        twoContent={
                            <CustomerRequests isOwner={isOwner}/>
                        }
                        threeTitle={"My Customers"}
                        threeContent={
                            <MyCustomers/>
                        }
                    ></TabView>
                ) : (
                    <TabView
                        defaultView={view}
                        oneTitle={"Admin"}
                        oneContent={                
                            <ProductsEdit isOwner={isOwner} setIsOwner={setIsOwner}/>
                        }
                    ></TabView>
                )
            }
        ></PageResponse>
    );
}
