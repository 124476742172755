//================================================================
//  Component: Tooltip
//================================================================

//  Purpose: This is a standard tooltip component

//  Properties:
//    - helpText = {A string, a short message}
//    
//    <Optional>
//    - h = 'left' or 'right'. Left or right align the tooltip (default is left)
//    - v = 'top' or 'bottom'. Top or bottom align the tooltip (dfault is top)
//    - hidden = {Boolen, show or hide the tooltip}
//    - type = {string, 'warning' or undefined defaults to the info icon}

//  Example:
//    <Tooltip
//      helpText={"This is an optional field for specifying the max number of licenses you would like to provision for your product."}
//      h='left'
//      v='top'
//      hidden={false}
//      type={'warning'}
//    ></Tooltip>    

//================================================================


//Libraries
import React, { useState } from 'react';

//Images
import InfoIcon from '../Images/Icon_i_Tooltip.svg';
import WarningIcon from '../Images/Icon_Warning_Yellow.svg';

//CSS
import './Tooltip.css';


export default function Tooltip({
  helpText,
  h,
  v,
  type,
  hidden,
}) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

    // Select correct alignment className based on props
    const className =
      h === undefined || h !== 'right' ? (
        v === undefined || v !== 'bottom' ? 'Tooltip-Text-TopLeft' : 'Tooltip-Text-BottomLeft'
      ) : (
        v === undefined || v !== 'bottom' ? 'Tooltip-Text-TopRight' : 'Tooltip-Text-BottomRight'
      )

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [hideToolTipText, setHideToolTipText] = useState(true);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    if (hidden === true) return null;

    else if (helpText === undefined){
      return null

    } 
    
    else {
      return (
        <div className="QuestionLabel-Tooltip">
          <img 
            className="QuestionLabel-Help-Icon" 
            alt="help-icon" 
            src={type === 'warning' ? (WarningIcon):(InfoIcon)}
            onPointerEnter={() => setHideToolTipText(false)} 
            onPointerLeave={() => setHideToolTipText(true)} 
          ></img>
          <span hidden={hideToolTipText} className={className}> {helpText}</span>
        </div>
      );

    }
}
