//================================================================
//  Component: Child Input
//================================================================

//  Purpose: Child component of MultiLineDot component 

//  Properties:
//    - index={string, the unique id for this element}
//    - hidden & setHidden={useState, boolen value for the visibility}
//    - defaultValue={string, the onload value for this form}
//    - defaultValue={string, the onload value for this form}
//    - onBlurHandler={function, that takes in an 'event' & 'index'}

//  Example:
//    <ChildInput
//      index={'1'}
//      defaultValue={'heyyyyy'}
//      setPlaceHolder={'e.g Design'}
//      onBlurHandler={onBlurHandler(event, index)}
//    ></ChildInput>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

//Functions
import { reStringAlphaNumSpaceHyphenSlashPara, errStringAlphaNumSpaceHyphenSlashPara } from '../../Library/ValidateUserInput';

//CSS
import './MultiLineDot.css';


export default function ChildInput({
  index,
  setDefaultValue,
  setPlaceHolder,
  onBlurHandler
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Holds the value for the current input
    const [value, setValue] = useState();

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // Once the parent re-creates the array (onBlurHandler) and passes down the value via 'setDefaultValue'
    useEffect(() => {

      setValue(setDefaultValue);

    },[setDefaultValue]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='MultiLineDot-Container'>
      <Form.Group className='MultiLineDot-InputField' controlId='multilinedot'>
        <Form.Control
          id={index}
          placeholder={setPlaceHolder} 
          defaultValue={setDefaultValue}
          value={value}
          pattern={reStringAlphaNumSpaceHyphenSlashPara}
          onChange={(e) => setValue(e.target.value)}
          type='text'
          onBlur={(event) => onBlurHandler(event, index)}
        ></Form.Control>
        <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
          Please provide a valid tag. Must contain a combination of {errStringAlphaNumSpaceHyphenSlashPara}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  )
}
