//================================================================
//  Component: PricingModel
//================================================================
//
//  Purpose: Handles the pricing model field, that works with bootstrap
//
//  Properties:
//    - controlId = {String, the unique id in the event (e.g: e.target.value)}

//    <Optional>
//    - defaultValue - {String, 0 to 100}
//    - required = {Boolen, is this field required?}
//    - readOnly = {Boolen, is this field read only?}
//
//  PricingModel:
//    <PricingModel
//      controlId={"pricingmodel"}
//      defaultValue={productObject?.pricingdiscount}
//      required={true}
//      readOnly={true}
//    ></PricingModel>    
//
//================================================================

//Libraries
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

//Contexts

//Components

//Functions

//Images
import ShowBack from '../Images/Icon_ShowBack.svg';
import ChargeBack from '../Images/Icon_ChargeBack.svg';

//CSS
import './PricingModel.css';


export default function PricingModel({
  controlId,
  defaultValue,
  required,
  readOnly,
}) {

  //------------------------------------------------------
  //  Component variables
  //------------------------------------------------------

    if (required === undefined) {

      required = false;
  
    }

    if (readOnly === undefined) {

      readOnly = false;
  
    }

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [discountPercent, setDiscountPercent] = useState();

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    useEffect(() => {

      if (defaultValue === undefined) return;

      setDiscountPercent(defaultValue);

    }, [defaultValue]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Read Only
    //------------------------------------------------------

    if (readOnly === true) {

      return (
        <div className='PricingModel-ReadOnly-Container'>
          <Form.Group className='mb-3' controlId={controlId}>
      
            <div className='PricingModel-Field'>Pricing Model</div>
            <Form.Control
              required={required}
              style={{marginTop: "10px"}}
              type='text'
              value={discountPercent}
              hidden
            />
            <img 
              className={discountPercent === '100' ? ('PricingModel-Icon-Readonly-Selected') : ('PricingModel-Icon-Readonly')}
              src={ShowBack} alt='icon-showback' 
            ></img>
            <img 
              className={discountPercent === '0' ? ('PricingModel-Icon-Readonly-Selected') : ('PricingModel-Icon-Readonly')}
              src={ChargeBack} 
              alt='icon-chargeback' 
            ></img>
      
            <Form.Control.Feedback type="invalid" className='PricingModel-Invalid-Message'>
              Please select a pricing model. 
            </Form.Control.Feedback>
      
          </Form.Group>
        </div>
      )

    }

    //------------------------------------------------------
    //  Default
    //------------------------------------------------------

    else {

      return (
        <Form.Group className='mb-3' controlId={controlId}>
    
          <div className='PricingModel-Field'>Pricing Model</div>
          <Form.Control
            required={required}
            style={{marginTop: "10px"}}
            type='text'
            value={discountPercent}
            hidden
          />
          <img 
            className={discountPercent === '100' ? ('PricingModel-Icon-Selected') : ('PricingModel-Icon')}
            src={ShowBack} alt='icon-showback' 
            onClick={() => setDiscountPercent('100')}
          ></img>
          <img 
            className={discountPercent === '0' ? ('PricingModel-Icon-Selected') : ('PricingModel-Icon')}
            src={ChargeBack} 
            alt='icon-chargeback' 
            onClick={() => setDiscountPercent('0')}
          ></img>
    
          <Form.Control.Feedback type="invalid" className='PricingModel-Invalid-Message'>
            Please select a pricing model. 
          </Form.Control.Feedback>
    
        </Form.Group>
      )

    }

}

