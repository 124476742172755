//================================================================
//  Component: ProductPricing used by PublishForm.js form
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - setRequestType = {useState}
//    - submitState = {useState with the form validation state}

//  Example:
//    <ProductPricing
//      setRequestType = {setRequestType}
//      submitState= {submitState}
//    ></ProductPricing>    

//================================================================


//Libraries
import React, { useReducer, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';

//Contexts
import { SetError } from '../../../Library/GlobalContexts';

//Components
import QuestionLabel from '../../../Components/QuestionLabel/QuestionLabel';
import PricingModel from '../../../Components/PricingModel/PricingModel';
import NotificationLabel from '../../../Components/NotificationLabel/NotificationLabel';

//Functions
import GetDocument from '../../../Library/GetDocument';
import { reString, reStringUnsignedInteger } from '../../../Library/ValidateUserInput';

//Images

//CSS
import './ProductPricing.css';


export default function ProductPricing({
  setRequestType,
  submitState,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Holds all firestore & form data
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      projectCode: '',
      projectCodeName: undefined,
      projectCodeError: undefined,
      currency: '',
      currencyError: undefined,

      taskNumber: '',
      taskNumberError: undefined,
      expenditureType: '',
      expenditureTypeError: undefined,

      renewalMonthDisabled: true,

      projectCodes: [],
      taskNumbers: [],
      expenditureTypes: [],
    }
  );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function validateForm() {

      // Form touched > Reset errors
      data.projectCodeError = undefined;
      data.currencyError = undefined;
      data.taskNumberError = undefined;
      data.expenditureTypeError = undefined;
      setData({...data});

      submitState.productPricing = undefined;
      submitState.preventSubmit = false;

      if (data.projectCode === '') return;

      // Project code exists > validate form
      GetDocument('oracleProjects', data.projectCode).then((results) => {

        let preventSubmit = false;

        //------------------------------------------------------
        //  1. Validate Project Code
        //------------------------------------------------------

        // a. Check if project code is active
        if (results.active !== true) {

          data.projectCodeError = `Oracle Project Code '${data.projectCode}' provided is not active. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`;
          preventSubmit = true;

        }

        // b. Check for 'projectName' and save it to useReducer 'projectCodeName'
        if ('projectName' in results) {

          data.projectCodeName = results.projectName;

        }

        //------------------------------------------------------
        //  2. Validate Currency
        //------------------------------------------------------

        // Check for 'currencyCode' is see if this matches the selected 
        if ('currencyCode' in results && data.currency !== '') {

          if (data.currency !== results.currencyCode) {

            data.projectCodeError = `Currency code '${data.currency}' does not match the Oracle project code '${data.projectCode}' with the currency code '${results.currencyCode}'. You may proceed to submit however we recommended checking that the selected currency is correct.`
            preventSubmit = true;

          } else {

            data.projectCodeError = undefined;
            
          }

        }

        //------------------------------------------------------
        //  3. Set Currency
        //------------------------------------------------------

        // If currency is undefined > set currency
        if ('currencyCode' in results && data.currency === '') {

          data.currency = results.currencyCode;

        }

        //------------------------------------------------------
        //  4. Validate Task Numbers
        //------------------------------------------------------

        // a. Check for 'taskNumbers' and save it to useReducer 'taskNumbers'
        if ('taskNumbers' in results) {

          data.taskNumbers = Object.keys(results.taskNumbers);
        }

        // b. Check the users input
        if (data.taskNumber !== '') {

          // Look for the 'data.taskNumber' in 'data.taskNumbers'
          const query = data.taskNumbers.find((tasknumber) => (tasknumber === data.taskNumber));

          // Does it exist?
          if (query !== data.taskNumber){

            data.taskNumberError = `Unknown Task Number '${data.taskNumber}' provided. You may proceed to submit however we recommended checking that your Oracle Task Number is correct.`
            preventSubmit = true;

          } else {

            data.taskNumberError = undefined;

          }

        }

        // b. Check hasn't done anything
        if (data.taskNumber === '') {

          // Look for the '11050' in 'data.taskNumbers'
          const query = data.taskNumbers.find((tasknumber) => (tasknumber === '11050'));
          
          // IF '11050' is a valid task number > set it!
          if (query === '11050') {

            data.taskNumber = '11050';

          }

        }

        //------------------------------------------------------
        //  5. Validate Expenditure Type
        //------------------------------------------------------

        // If taskNumber is '11050' > default to ICT costs
        if (data.taskNumber === '11050' && data.expenditureType === ''){

          data.expenditureType = 'ICT Costs';

        }

        //------------------------------------------------------
        //  6. Save changes to useReducer, parent useState & projectObject
        //------------------------------------------------------

        setData({...data});

        if (preventSubmit === true) {

          submitState.productPricing = `Please understand by continuing, it might not be possible to recharge costs for subscriptions against this product.`;
          submitState.preventSubmit = true;

        } else if (preventSubmit === false) {

          submitState.productPricing = undefined;
          submitState.preventSubmit = false;

        }

        //------------------------------------------------------

      }).catch(() => {

        submitState.productPricing = `Please understand by continuing, it might not be possible to recharge costs for subscriptions against this product.`;
        submitState.preventSubmit = true;

        setData({
          projectCodeName: undefined,
          projectCodeError: `Unknown Oracle Project Code '${data.projectCode}' provided. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`,
          currencyError: undefined,
    
          taskNumberError: undefined,
          expenditureTypeError: undefined,
    
          taskNumbers: [],
          expenditureTypes: [],
        });

      });

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad > Retrieve a list of project codes
    useEffect(() => {

      GetDocument('oracleProjects', '+projectCodes').then((results) => {

        if(results?.projectCodes === undefined || !Array.isArray(results.projectCodes)) {

          setError(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore`);
          setRequestType('error-fatal');

        }

        // Save to useReducer
        setData({projectCodes: results.projectCodes});

      }).catch((error) => {

        setError(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore. Error: ${error.message}`);
        setRequestType('error-fatal');

      });

    // eslint-disable-next-line
    }, []);

  
    // onChange > Validate project code changes
    useEffect(()=> {

      if (data.projectCode === '') return;
      if (data.projectCode.length <= 3) return;

      validateForm();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.projectCode]);



    // onChange > Validate tasknumber code changes
    useEffect(()=> {

      if (data.taskNumber === '') return;

      validateForm();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.taskNumber]);


    // onChange > Validate currency changes
    // Benno ~ due to currency been a selectbox, onBlur is too slow!
    useEffect(()=> {

      if (data.currency === '') return;

      validateForm();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.currency]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <>

      {/* ================================================================= */}
      {/*                                                                   */}
      {/*                     Product Pricing Form                          */}
      {/*                                                                   */}
      {/* ================================================================= */}

        <QuestionLabel question='Pricing *' helptext='Marketplace allows publishers to recharge their customers. Note that free products cannot be changed to paid products.'></QuestionLabel>
        
        {/* Pricing model */}
        <PricingModel
            controlId='pricingdiscount'
            required={true}
        ></PricingModel>                    
    
        <div className='ProductsEditForm-Pricing-Container'>

            {/* Per user cost */}
            <div>
              <Form.Group className='mb-3' controlId='pricingamount'>
                  <div className='PublishForm-Field'>Per User Cost</div>
                  <Form.Control
                      required
                      style={{marginTop: "10px"}}
                      type='text'
                      placeholder='e.g. 100'
                      pattern={reStringUnsignedInteger}
                  />
                  <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                      Please provide a valid cost per user. 
                  </Form.Control.Feedback>
              </Form.Group>
          </div>

            {/* Project code */}
            <div>
              <Form.Group className='mb-3' controlId='projectcode'>
                <div className='ProductsEditForm-Pricing-Field'>Project Code</div>
                <Form.Control
                  name='projectCode'
                  required
                  style={{marginTop: "10px"}}
                  type='text'
                  placeholder='e.g. 260018'
                  pattern={reStringUnsignedInteger}
                  value={data.projectCode}
                  onChange={(e) => setData({projectCode: e.target.value})}
                  list='productsEditProjectCodeList'
                ></Form.Control>
                <datalist id='productsEditProjectCodeList'>
                {
                  data.projectCodes.map((row, index) => (
                    <option id={index}>{row}</option>
                  ))
                }
                </datalist>
                <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                  Please provide a valid project code.
                </Form.Control.Feedback>
                  <label className='ProductsEditForm-Oracle-Project-label'>
                    {data.projectCodeName}
                  </label>
              </Form.Group>
            </div>

            {/* Currency */}
            <div>
                <Form.Group className='mb-3' controlId='pricingcurrency'>
                <div className='PublishForm-Field'>Currency</div>
                    <Form.Select name='currency' required minLength={"3"} style={{marginTop: "10px"}}
                      onChange={(e) => setData({currency: e.target.value})}
                      value={data.currency}
                    >
                        <option value="">-</option>
                        <option>AUD</option>
                        <option>USD</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                        Please select a currency.
                    </Form.Control.Feedback>
                </Form.Group>
            </div>

            {/* Task number */}
            <div>
              <Form.Group className='mb-3' controlId='tasknumber'>
                <div className='ProductsEditForm-Pricing-Field'>Task Number</div>
                <Form.Control
                  name='taskNumber'
                  required
                  style={{marginTop: "10px"}}
                  type='text'
                  placeholder='e.g. 11050'
                  pattern={reString}
                  value={data.taskNumber}
                  onChange={(e) => setData({taskNumber: e.target.value})}
                  list='productsEditTaskNumberList'
                ></Form.Control>
                <datalist id='productsEditTaskNumberList'>
                  {
                    data.taskNumbers.map((row, index) => (
                      <option id={index}>{row}</option>
                    ))
                  }
                </datalist>
                <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                  Please provide a valid task number.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            {/* Frequency */}
            <div>
                <Form.Group className='mb-3' controlId='pricingfrequency'>
                    <div className='PublishForm-Field'>Frequency</div>
                    <Form.Select 
                      required 
                      minLength={"3"} 
                      style={{marginTop: "10px"}} 
                      onChange={(e) => setData({renewalMonthDisabled: e.target.value})}>
                        <option value="">-</option>
                        <option>Monthly</option>
                        <option>Yearly</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                        Please select a frequency.
                    </Form.Control.Feedback>
                </Form.Group>
            </div>

            {/* Expenditure type */}
            <div>
              <Form.Group className='mb-3' controlId='expendituretype'>
                <div className='ProductsEditForm-Pricing-Field'>Expenditure Type</div>
                <Form.Control
                  name='expenditureType'
                  required
                  style={{marginTop: "10px"}}
                  type='text'
                  placeholder='e.g. ICT Costs'
                  pattern={reString}
                  value={data.expenditureType}
                  onChange={(e) => setData({expenditureType: e.target.value})}
                ></Form.Control>
                <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                  Please provide a valid expenditure type.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            {/* Renewal month */}
            <div>
                <Form.Group className='mb-3' controlId='renewalmonth'>
                    <div className='PublishForm-Field'>Renewal Month</div>
                    <Form.Select required minLength={"3"} style={{marginTop: "10px"}} disabled={data.renewalMonthDisabled === 'Yearly' ? (false): (true)}>
                        <option value="">-</option>
                        <option>January</option>
                        <option>February</option>
                        <option>March</option>
                        <option>April</option>
                        <option>May</option>
                        <option>June</option>
                        <option>July</option>
                        <option>August</option>
                        <option>September</option>
                        <option>October</option>
                        <option>November</option>
                        <option>December</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                        Please select a renewal month.
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
  
        </div>

        {/* Warning Label > Invalid Project code */}
        <NotificationLabel
          hidden={data.projectCodeError === undefined ? (true):(false)}
          type={"warning"}
          message={data.projectCodeError}
        ></NotificationLabel>

        {/* Warning Label > Invalid Currency */}
        <NotificationLabel
          hidden={data.currencyError === undefined ? (true):(false)}
          type={"warning"}
          message={data.currencyError}
        ></NotificationLabel>

        {/* Warning Label > Invalid Tasknumber */}
        <NotificationLabel
          hidden={data.taskNumberError === undefined ? (true):(false)}
          type={"warning"}
          message={data.taskNumberError}
        ></NotificationLabel>

    </>
  )
}
