//================================================================
//  Component: RichTextArea
//  Documentation: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
//================================================================

//  Purpose: Provide a standardised rich text area, that works with bootstrap

//  Properties:
//    - readOnly = {boolen, change to read only}
//    - title = { string, title of the field }
//    - placeholder = { string, place holder text }
//    - onLoadContent = {object, refer to 'Data Conversion' documentation, https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp }
//    - setContent = {useState, holds the overview data }


//  RichTextArea:
//    <RichTextArea
//      readOnly={false}
//      title='Overview *'
//      placeholder='e.g. More details on what this app can do'
//      onLoadContent={{"entityMap":{},"blocks":[{"key":"637gr","text":"This is text that will be added to the component!","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}}
//      setContent={setOverviewContent}
//    ></RichTextArea>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { Form } from 'react-bootstrap';

//Contexts

//Components
import QuestionLabel from '../QuestionLabel/QuestionLabel';

//Images

//CSS
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './RichTextArea.css'


export default function RichTextArea({
  readOnly,
  title,
  placeholder,
  onLoadContent,
  setContent
}) {

  //------------------------------------------------------
  //  Component variables
  //------------------------------------------------------
  
    // readOnly is a required prop
    if (readOnly === undefined) {

      readOnly = false;
    
    }

    // Configure the toolbar > https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
    const toolbarConfig = {
      options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link'],
      inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline'],
      },
      fontSize: {
        icon: 'fontSize',
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
      },
      list: {
        inDropdown: false,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
      },
      textAlign: {
        inDropdown: false,
        options: ['left', 'center', 'right', 'justify'],
      },
      colorPicker: {
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
      },
      link: {
        inDropdown: false,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_blank',
        options: ['link', 'unlink'],
        linkCallback: undefined
      },
    }

  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Holds the components mandatory states, because the library requires it!
    const [contentState, setContentState] = useState();

    // onLoad a hidden form input is flagged as invalid
    const [inValid, setInValid] = useState(true);

    // onLoad styling
    const [style, setStyle] = useState('RichTextArea');


  //------------------------------------------------------
  //  function
  //------------------------------------------------------

    // onChange validate the users actions
    function HandleChange(e){

      if (e?.blocks === undefined) return setInValid(true);
      if (e?.blocks?.length === 0) return setInValid(true);

      // Check 'e' to see if the user has input content
      let inValid = false;
      e.blocks.forEach((input) => {

        if (input?.text?.length <= 0) inValid = true;

      });

      // Based on the above, show an error or save the users inputs to 'setContent'
      if (inValid === true) {

        setStyle('RichTextArea-Invalid');
        setInValid(inValid);
        return;

      }
      if (inValid === false) {

        //Convert contents to json for saving to Firestore
        const convertedContent = JSON.stringify(e)
        
        //Check for illegal characaters
        setStyle('RichTextArea-Valid');

        setInValid(inValid);
        setContent(convertedContent);

      }

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad > If readOnly is true, change style
    useEffect(() => {

      if (readOnly === false) return;

      setStyle('RichTextArea-ReadOnly');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // onLoad > If onLoadContent is passed down, it SHOULD BE valid!
    useEffect(() => {

      // Start with a blank json if no overview content exists (ie. new product form)
      let contentForConversion = '{}'

      // Load overview content
      if (onLoadContent !== undefined) {
        contentForConversion = onLoadContent
      }

      //Convert contents from json back to rich text structure
      let convertedContent = JSON.parse(contentForConversion)
      
      // If we have content, make sure it's valid, otherwise start again
      if (convertedContent === null || (convertedContent.length > 0 && convertedContent?.blocks === undefined)) {
        convertedContent = {}
      };

      setInValid(false);
      setContentState(convertedContent);

      // Finally check if 'setContent' was passed down (NOT required if it is 'readOnly')
      if (setContent === undefined) return;

      setContent(onLoadContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onLoadContent]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Allows React and/or Firebase time to provide onLoad content
    if (contentState === undefined) return null

    // Prevents a crash if a CS admin doesn't use the component correctly!
    else if (setContent === undefined && readOnly === false) return (
      <h3 style={{color: 'red'}}>
        Missing prop 'setContent' in component 'RichTextArea.js'
      </h3>
    );

    // Return the component
    else {
      return (
        <Form.Group className='mb-3'>

          <QuestionLabel hidden={readOnly} question={title}></QuestionLabel>

          {/* Hidden input used to trigger validation checks */}
          {/* Note the 'onInvalid' event: https://reactjs.org/docs/events.html#form-events */}
          <Form.Control hidden={true} required={inValid} onInvalid={() => setStyle('RichTextArea-Invalid')}></Form.Control>

          {/* React Draft Wysiwyg */}
          <Editor
            wrapperClassName={style}
            readOnly={readOnly}
            toolbar={toolbarConfig}
            toolbarHidden={readOnly === true ? (true):(false)}
            defaultContentState={contentState?.blocks === undefined ? (undefined):(contentState)}
            onContentStateChange={(e) => HandleChange(e)}
            placeholder={placeholder}
          ></Editor>

          {/* Error messaging */}
          <Form.Control.Feedback type="invalid">
            Please provide a value.
          </Form.Control.Feedback>

        </Form.Group>

      )
    }

}
