//================================================================
//  Component: Usage Bar or Progress Bar
//================================================================

//  Purpose: Display a usage/progress bar based on a number 

//  Properties:
//  - bgcolor = {String, the desired colour you want to use e.g. "#f9ae00" or "yellow"}
//  - progress = {number, the percentage of what is used or what has progressed}
//  - hidden = {Boolean, "True" or "False"}


//  Example:

//    <UsageBar 
//      bgcolor="color" 
//      progress={24} 
//      hidden={true} 
//     />
  

//================================================================


//Libraries
import React from 'react'

//Contexts

//Components

//Functions

//Images

//CSS
import './UsageBar.css'


export default function UsageBar(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------


    const progress = props.progress
    const bgcolor = props.bgcolor
    const hidden = props.hidden

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  const fillerStyles = {
    height: 'inherit',
    // prevent overflow if the progress is more than 100%
    width: `${props.progress > 100 ? 100 : props.progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className="UsageBar-Container" hidden={hidden}>
      <div style={fillerStyles}>
        <span className="UsageBar-Label">{`${progress}%`}</span>
      </div>
    </div>
  );

};
