//------------------------------------------------------
//  Financial Statistics page - summary table
//------------------------------------------------------

//Libraries
import React from 'react'

//Functions
import UsageBar from "../../../Components/UsageBar/UsageBar";
import Tooltip from '../../../Components/Tooltip/Tooltip';

export default function SummaryTable(props) {

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------

  const stats = props.stats //Summary stats
  const toolTipText = props.toolTipText //Table header tooltips

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  >>> numFmt - Add thousand comma separators to numbers
  //------------------------------------------------------

  function numFmt(number) {
    return Number(number).toLocaleString('en-US')
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <table className="DataTable-Container">
      <thead>
        {/* Table headers with tooltips */}
        <tr>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Subscribers Invoiced
            <Tooltip
              helpText={toolTipText.SubscribersInvoiced}
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Price
            <Tooltip
              helpText={toolTipText.Price}
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Auto-recharge Completed
            <Tooltip
              helpText={toolTipText.AutoRechargeStatus}
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Auto-recharge Total
            <Tooltip
              helpText={toolTipText.AutoRechargeTotal}
              h='right'
              v='bottom'
            ></Tooltip>
            </div>
          </th>
          <th>
            <div className='FinancialStatistics-TableHeader'>
            Total Amount Due
            <Tooltip
              helpText={toolTipText.TotalAmountDue}
              h='right'
              v='bottom'
            ></Tooltip>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          /* Show summary stats for each pricing/currency combination */
          Object.entries(stats).map((object) =>
            /* object[0] = Pricing/currency combination */
            /* object[1] = Status for that combination */
            <tr>
              <td>{numFmt(object[1].subscribers)}</td>
              <td>{numFmt(object[0].split(' ')[0])} {object[0].split(' ')[1]}</td>
              <td>
                <UsageBar
                  bgcolor="#00B8B5"
                  progress={Number(object[1].journalcount) === 0? 100 : parseInt(Number(object[1].journalcomplete) * 100 / Number(object[1].journalcount))}
                  hidden={false}
                />
              </td>
              <td>{numFmt(object[1].journaltotal)} {object[0].split(' ')[1]} ({
                numFmt(object[1].journalcount)} out of {numFmt(object[1].subscribers)})</td>
              <td>{numFmt(object[1].total)} {object[0].split(' ')[1]}</td>
            </tr>
          )
        }
      </tbody>
    </table>
  )
}