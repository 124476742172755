//==========================================================================
//   Function: Batch Write Document
//   Description: Creates/Writes multiple Firestore documents atomically (always returns a promise)
//   Documentation:
//   - https://cloud.google.com/community/tutorials/cloud-functions-firestore
//   - https://cloud.google.com/firestore/docs/samples/firestore-data-set-doc-upsert#firestore_data_set_doc_upsert-nodejs
//   - https://firebase.google.com/docs/firestore/manage-data/add-data#set_a_document
//   - https://firebase.google.com/docs/firestore/manage-data/transactions#web-version-9_2
//==========================================================================
//
//   Example - How to use this function:
//
//    docsToUpdate = []
//    docsToUpdate.push(['products', 'prd-1670800850240', { 'publisher': publisherList }, true])
//    docsToUpdate.push(['subscriptions', 'sub-1662347917561', { 'status': 'pending' }, false])
//
//    BatchWriteDocument(docsToUpdate, true)
//    .then(() =>{
//      console.log("I'm done!")
//    })
//    .catch((error) =>{
//      console.log("error", error)
//      setRequestType('error-fatal')
//      setError(`BatchWriteDocument failed, error ${error}`)
//    })
//
//   Each element in the outer array is a write to commit as part of the batch
//   The inner array has the following elements:
//   - Collection ID
//   - Document ID
//   - Data hashtable to write to document
//   - Merge = true or false
//
//==========================================================================

//Libraries
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from './FirebaseConfig';
import { doc, writeBatch } from "firebase/firestore"; 


export default async function WriteDocument(documentList){

    try {

      //Cannot batch more than 500 documents
      if (Object.keys(documentList).length > 500) throw new Error(`- cannot batch more than 500 writes`)
      
      //Firestore Database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const batch = writeBatch(db);

      //Process each doc write in turn
      for (const document of documentList) {

        const docRef = doc(db, document[0], document[1]);
        batch.set(docRef, document[2], { merge: document[3] });

      }

      //Set collection

      //Write to document
      await batch.commit();

      return "success"
      

    } catch (error) {
      throw new Error(`Function WriteDocument failed to complete, error ${error}`)
    }

  //------------------------------------------------------
}