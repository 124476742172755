//==========================================================================
//   Component: Pending Table Row
//==========================================================================

//   Purpose: Allows user to expand/collapse row in the pending subscriptions table

//   Properties: 
//   - rowData = {data used to populate the rows in the table}

//   Example: 
//   <PendingTableRow
//      rowData={row}
//   ></PendingTableRow>

//------------------------------------------------------


//Libraries
import React, {useState} from 'react'

//Components
import GetImage from '../../../Components/GetImage/GetImage';
import PricingTotal from '../../../Components/PricingTotal/PricingTotal';

//Images
import ExpandIcon from '../../../Components/Images/Icon_Expand_Black.svg';

//CSS
import '../MySubscriptions.css';


export default function PendingTableRow(props) {

    //------------------------------------------------------
    //  Extract props
    //------------------------------------------------------

        var rowData = props.rowData

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        //Used to determine if the row is expanded/collapsed
        const [rowExpanded, setRowExpanded] = useState(false);

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  Row Expanded
        //------------------------------------------------------
        
        if(rowExpanded) {
            return (
                <>
                    <tr className='ExpandableTable-Container-Row' onClick={() => setRowExpanded(false)}>
                        <td style={{textAlign: "center"}}>
                            <div className='MySubscriptions-Pending-Logo'>
                                <GetImage iconurl={rowData.productobject?.iconurl}></GetImage>
                            </div>
                        </td>
                        <td>{rowData.productobject?.name[0].toUpperCase() + rowData.productobject?.name.slice(1)}</td>
                        <td>
                            {rowData.subscriptionid}
                        </td>
                        <td>
                            {rowData.subscriptioncreatedate}
                        </td>
                        <td style={{textAlign: "center"}}>
                            <img className="Expand-Icon" src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon"></img>
                        </td>
                    </tr>  
                    {/* Row Expanded > Subscription Details */}
                    <tr className='ExpandableTable-Container-Row-Expanded'>
                        <td colSpan="5">
                            <div className='PendingTableRow-Expanded'>

                                <div className='PendingTableRow-Column1'>
                                    <p className='PendingTableRow-FieldLabel'>Project Code</p>
                                    {rowData?.subscriberprojectcode}

                                    <p className='PendingTableRow-FieldLabel'>Task Number</p>
                                    {rowData?.subscribertasknumber}

                                    <p className='PendingTableRow-FieldLabel'>Expenditure Type</p>
                                    {rowData?.subscriberexpendituretype}
                                </div>

                                <div className='PendingTableRow-Column2'>

                                    <p className='PendingTableRow-FieldLabel'>Cost</p>
                                    <PricingTotal
                                        pricingdiscount={rowData.productobject?.pricingdiscount}
                                        pricingamount={rowData.productobject?.pricingamount}
                                        pricingfrequency={rowData.productobject?.pricingfrequency}
                                        pricingchanged={rowData?.productobject?.pricingchanged}
                                    ></PricingTotal>

                                    <p className='PendingTableRow-FieldLabel'>Support</p>
                                    <ul>
                                      <li>{rowData?.productobject?.supportemail}</li>
                                      <li><a target='_blank' rel='noreferrer' href={rowData.productobject?.supportlink}>{rowData.productobject?.supportlink}</a></li>
                                    </ul>

                                </div>

                            </div>
                        </td>
                    </tr>
                </>
            )
        }

        //------------------------------------------------------
        //  Row Collapsed
        //------------------------------------------------------

        else {
            return (
                <tr className='ExpandableTable-Container-Row' onClick={() => setRowExpanded(true)}>
                    <td style={{textAlign: "center"}}>
                        <div className='MySubscriptions-Pending-Logo'>
                            <GetImage iconurl={rowData.productobject?.iconurl}></GetImage>
                        </div>
                    </td>
                    <td>{rowData.productobject?.name[0].toUpperCase() + rowData.productobject?.name.slice(1)}</td>
                    <td>
                        {rowData.subscriptionid}
                    </td>
                    <td>
                        {rowData.subscriptioncreatedate}
                    </td>
                    <td style={{textAlign: "center"}}>
                        <img className="Expand-Icon" src={ExpandIcon} alt="Expand_Icon"></img>
                    </td>
                </tr>
            )
        }
        
}
