//================================================================
//  Page: Market Place Item
//================================================================

//  Purpose: The marketplace item page, allows users to subscribe to a product

//  Example:
//    <Item/>

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Table, Breadcrumb, Modal } from "react-bootstrap";

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import TabView from '../../Components/TabView/TabView';
import SubscribeForm from './SubscribeForm';
import SubscribeButton from './SubscribeButton';
import NotificationLabel from '../../Components/NotificationLabel/NotificationLabel';
import Tooltip from '../../Components/Tooltip/Tooltip';
import RatingsDetail from '../../Components/RatingsCard/RatingsDetail';
import PageHeader from '../../Components/PageHeader/PageHeader';
import RichTextArea from '../../Components/RichTextArea/RichTextArea';
import PricingTotal from '../../Components/PricingTotal/PricingTotal';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import DocumentListener from '../../Library/DocumentListener'

//Images
import DefaultIcon from '../../Components/Images/Icon_AppInBox_Loading.svg';
import SuccessPlane from '../../Components/Images/Image_Success_Plane.svg';

//CSS
import './Item.css'


export default function Item() {

  //------------------------------------------------------
  //  UseParams from 'react-router'
  //------------------------------------------------------

    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const setError = useContext(SetError);
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Handles the pages status > 'onload', 'view', 'create', 'success', 'error-fatal'
    const [requestType, setRequestType] = useState('pending');

    //Hold the product information
    const [productId, setProductId] = useState();
    const [productObject, setProductObject] = useState();
    const [productIcon, setProductIcon] = useState(DefaultIcon);

    //Has the user subscribed to this product > 'unsubcribed', 'pending', 'subscribed'
    const [subscribeStatus, setSubscribeStatus] = useState('unsubcribed');
    const [subRequestId, setSubRequestId] = useState();

    //Used to determine if the modal is visible
    const [showModal, setShowModal] = useState(false);


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // When page first loads > Check URL for product ID > Set Product ID
    useEffect(()=>{

      //Conditions
      if (searchParams === undefined) return;

      setProductId(searchParams.get('id'));

    }, [searchParams]);

    //When Product ID is set > Initialise DocumentListener > Get document and listen for changes 
    useEffect(()=>{

      //Conditions
      if (productId === undefined) return;

      //OnLoad Function > Gets initial state of the document
      function onLoad(document){

        setProductObject(document);
        setProductIcon(document?.iconurl)
        setRequestType('onload')

      }

      //OnChange Function > Handles changes to the product document
      function onChange(document, previousValue){

        setProductObject(document);

        if(previousValue === undefined) return;
        if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);

      }

      //onError Function > Handle errors when creating document listener
      function onError(error){

        setRequestType('error-fatal');
        setError(`Failed to get product information, unable to create the document listener error: ${error}`);

      }

      //Subscribe to document changes
      const unsubscribe = DocumentListener('products', productId, onLoad, onChange, onError);

      //Stop listening for changes when component 'unMounts'
      return () =>{

        unsubscribe();

      };
      
    // eslint-disable-next-line
    }, [productId]);

    //Check if you have subscription status to this product > Show Launch/Subscribe button
    useEffect(() =>{

      //Conditions
      if (productId === undefined) return;
      if (getUser === undefined) return;

      QueryDocument('subscriptions', [
        ['productid', '==', productId],
        ['subscriber.userid', '==', getUser.userid]
      
      ]).then((results) =>{

        //No requests found, the subscribe button will be shown
        if (results.length === 0) return;

        //Get last item in array
        //Benno - This might change after MVP, but this works for now
        const latestSub = results.pop();
        setSubscribeStatus(latestSub.status);

      }).catch(() =>{

        // Gets around permissions failed due security rule if no records found
        // https://firebase.google.com/docs/firestore/security/rules-query#queries_and_security_rules
        return;

      });
      
    // eslint-disable-next-line
    }, [productId, getUser]);

    //Handles different request types
    useEffect(() =>{

      //Conditions
      if (requestType === undefined) return;
      if (productObject === undefined) return;
      if (getUser === undefined) return;

      //Terms and Condition > view
      // - Check there is terms and conditions
      if (requestType === 'view'){

        //If there are no T&Cs for the product > Skip to the subscriber form 
        if (productObject.subscriberterms.length === 0){

          setRequestType('create');

        }

      }

    // eslint-disable-next-line
    }, [requestType]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageOnload={
        <div className='Item-Onload-Container'> 
            {/* Breadcrumbs */}
            <div className='Item-Breadcrumbs'>
              <Breadcrumb>
                  <Breadcrumb.Item>
                      <Link to="/">
                          Marketplace
                      </Link>
                  </Breadcrumb.Item>
                <Breadcrumb.Item active>{productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            {/* Item Title */}
            <div className='Item-Title'>

              {/* Warning Label > Licenses Unavailable */}
              {
                (productObject?.availablelicenses === 0 && productObject?.assignedlicenses !== 0 && subscribeStatus !== 'active') ? 
                (
                  <NotificationLabel
                    type={"warning"}
                    message={"There are currently no licenses available for this product therefore there may be a delay in processing your request."}
                  ></NotificationLabel>
                ) :
                (null)
              }

              {/* Item Header */}
              <div className='Item-Product-Title'>

                {/* Product Icon */}
                <img style={{alignSelf: "center", justifySelf: "center"}} src={productIcon} alt='default-icon'></img>

                {/* Product Summary */}
                <div>
                  <div className='Item-NameRow'>
                    <div className='Item-Name'>{productObject?.name}</div>
                    {
                      productObject?.privateoffer?.enabled === true ? (
                        <div className="Card-Label">Private Product</div>
                      ) : (
                        null
                      )
                    }
                    
                  </div>
                  <div className='Item-Description'>
                    {productObject?.description}
                  </div>
                  <SubscribeButton 
                    subscribeStatus={subscribeStatus}
                    productObject={productObject}
                    setRequestType={setRequestType}
                  ></SubscribeButton>
                </div>
              </div>
              
            </div>
          
            {/* Item Body */}
            <div className='Item-Body'>
              <TabView
                defaultView={1}
                oneTitle={"Overview"}
                oneContent={                
                  <div className='Item-Overview-Container'>

                    <div className='Item-Overview'>
                      <div className='Item-Field-Label'>
                        Overview
                      </div>
                      <RichTextArea
                        readOnly={true}
                        onLoadContent = {productObject?.overview}
                      ></RichTextArea>
                    </div>

                    {/* OVERVIEW TAB */}
                    <div className='Item-Overview-Details'>
                      
                      {/* Customer Rating */}
                      <div className='Item-Fields'>
                        
                        <div className='Item-Field-Label'>
                          Customer Rating
                        </div>

                        <RatingsDetail
                          productid={productObject?.productid}
                          subscribeStatus={subscribeStatus}
                        ></RatingsDetail>
                      </div>

                      {/* Additional details */}
                      <div className='Item-Fields'>

                        <div className='Item-Field-Label' style={{marginBottom: '20px'}}>
                          Additional Details
                        </div>

                        {/* Publishers */}
                        <div>
                          Publishers
                          {
                            productObject?.publisher.map((object, index) =>
                              <div key={index} style={{fontWeight: 'normal'}}><a href="mailto:{object}">{object}</a></div>
                            )
                          }
                        </div>

                
                        {/* Labels > Show IF the product has any! */}
                        {
                          productObject?.labels.length > 0 ?
                          (
                            <div>
                              Tags
                              {
                                productObject?.labels.map((object, index) =>
                                  <div className='Item-Label-Tag' key={index}>{object}</div>
                                )
                              }
                            </div>
                          )
                          :
                          (
                            null
                          )
                        }
                      </div>
                      
                    </div>
                  </div>
                }
                twoTitle={"Pricing"}
                twoContent={     
                  <div className='Item-Pricing'> 
                    {/* PRICING TAB */}
                    <table className='Item-Pricing-Table'>
                      <colgroup>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "70%"}}></col>
                      </colgroup>
                      <tbody>
                        <tr>
                          <td className='Item-Field-Label'>Cost</td>
                          <td>
                            <PricingTotal
                              pricingdiscount={productObject?.pricingdiscount}
                              pricingamount={productObject?.pricingamount}
                              pricingchanged={productObject?.pricingchanged}
                            ></PricingTotal>
                          </td>

                        </tr>
                        <tr>
                          <td className='Item-Field-Label'>Currency</td>
                          <td>{productObject?.pricingcurrency}</td>
                        </tr>
                        <tr>
                          <td className='Item-Field-Label'>Frequency</td>
                          <td >
                            {productObject?.pricingfrequency}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className='Item-Field-Label' style={{display: "flex", flexDirection: "row"}}>
                              Available Licenses 
                              <Tooltip
                                helpText={"This is the total available licenses for this product."}
                              ></Tooltip>  
                            </div>
                        
                          </td>
                          <td>
                            {
                              (productObject?.availablelicenses >= 0 && productObject?.totallicenses !== 0) ? 
                                productObject?.availablelicenses 
                                : 
                                <label className="Item-NA-Label">N/A</label>
                            } 
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>     
                }
                threeTitle={"Support"}
                threeContent={
                  <div className='Item-Support'>
                    {/* SUPPORT TAB */}
                    <Table>
                      <thead>
                        <tr>
                          <td className='Item-Field-Label'>Email</td>
                          <td>{productObject?.supportemail}</td>
                        </tr>
                        <tr>
                          <td className='Item-Field-Label'>Website</td>
                          <td><a href={productObject?.supportlink} target='_blank' rel='noreferrer'>{productObject?.supportlink}</a></td>
                        </tr>
                      </thead>
                    </Table>

                    <div>
                      <div className='Item-Field-Label' style={{margin: "10px 0px"}}>
                        Additional Information
                      </div>
                      <textarea className='Item-Textarea' readOnly rows="10" value={productObject?.supportnextsteps}></textarea>
                    </div>

                  </div>
                }
                fourTitle={"Terms & Conditions"}
                fourContent={
                  <div className='Item-TermsService'>
                    {/* TERMS AND CONDITIONS TAB */}
                    <div className='Item-Field-Label' style={{marginBottom: "10px"}}>
                      Terms & Conditions
                    </div>
                    <textarea className='Item-Textarea' readOnly value={productObject?.subscriberterms}></textarea>
                  </div>
                }
              ></TabView>    

            </div>

        </div>
      }
      pageView={
        <div className='Item-View-Container'>
          {/* Terms and Conditions */}
          <PageHeader
            title={"Terms & Conditions"}
            breadcrumbs= {
              <Breadcrumb>
                <Breadcrumb.Item style={{textDecoration: "none"}}>
                    <Link to="/">
                        Marketplace
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}</Breadcrumb.Item>
              </Breadcrumb>
              }
          ></PageHeader>
           
            {/* Body */}
            <div className='Item-View-Body'>
              <p>The Publisher requires the below to be accepted before proceeding</p>
                <textarea className='Item-Textarea' readOnly value={productObject?.subscriberterms}></textarea>
            </div>

            {/* Buttons */}
            <div className='Item-View-Buttons'>
              <button className='Primary-Button' onClick={() => setRequestType('create')}>Agree</button>
              <button className='Secondary-Button' onClick={() => setRequestType('onload')}>Decline</button>
            </div>

        </div>
      }
      pageCreate={
        <div className='Item-Create-Container'>
          <PageHeader
            title={"Complete your request"}
            breadcrumbs= {
            <Breadcrumb>
              <Breadcrumb.Item style={{textDecoration: "none"}}>
                  <Link to="/">
                      Marketplace
                  </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}</Breadcrumb.Item>
            </Breadcrumb>
            }
          ></PageHeader> 

          {/* Body */}
          <div className='Item-Create-Body'>

            {/* Subscribe Form */}
            <SubscribeForm
              setRequestType={setRequestType}
              productObject={productObject}
              setSubRequestId={setSubRequestId}
            ></SubscribeForm>    
        
          </div>

          {/* Modal */}
          <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Warning
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                The pricing for this product has been updated by the publisher. 
                Please check the pricing before subscribing to this product.
              </p>
            </Modal.Body>
          </Modal>
          
        </div>
      }
      pageSuccess={
        <div className='Item-Success-Container'>
          <PageHeader
            title={"We are processing your request"}
            icon={SuccessPlane}
            breadcrumbs = {
              <Breadcrumb>
                <Breadcrumb.Item style={{textDecoration: "none"}}>
                    <Link to="/">
                        Marketplace
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{productObject?.name[0].toUpperCase() + productObject?.name.slice(1)}</Breadcrumb.Item>
              </Breadcrumb>
            }
          ></PageHeader> 

          {/* Body */}
          <div className='Item-Success-Body'>
          <p style={{fontWeight: "normal"}}>Your subscription request has been sent to the product owner for approval.</p>
            Next steps:
            <textarea className='Item-Textarea' style={{height: "fit-content"}} readOnly value={productObject?.subnextsteps}></textarea>
            Request ID: 
            <p style={{fontWeight: "normal"}}>{subRequestId}</p>
            <br></br>
            <Link to="/subscriptions?view=pending">
              <button className="Primary-Button">
                View Subscriptions
              </button>
            </Link>
          </div>
          
        </div>
      }
    ></PageResponse>
  )
}