//------------------------------------------------------
//  Customer Requests Page
//------------------------------------------------------

//Libraries
import React, { useContext, useState, useEffect, useReducer } from 'react';
import { useSearchParams } from "react-router-dom";

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import GetDocument from '../../Library/GetDocument';
import ConvertDate from '../../Library/ConvertDate';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import CustomerRequestsPendingItem from './CustomerRequestsPendingItem';
import Tooltip from '../../Components/Tooltip/Tooltip';
import NotificationLabel from '../../Components/NotificationLabel/NotificationLabel';

//CSS
import './CustomerRequests.css';


export default function CustomerRequests({
  isOwner
}) {

  //------------------------------------------------------
  //  UseParams from 'react-router'
  //------------------------------------------------------

    const [searchParams] = useSearchParams();
    const productId = searchParams.get('id');

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------

    //Page status > 'pending', 'onload', 'view', 'error-fatal'
    const [requestType, setRequestType] = useState('pending');

    // Product data
    const [product, setProduct] = useState({});

    // Pending subscription data
    const [pendingSubs, setPendingSubs] = useState([]);

    // Cancelled subscription data
    const [cancelledSubs, setCancelledSubs] = useState([]);

    // For each pending subscription, what state they are on the form
    // > 'idle', 'confirmPending', 'rejectPending', 'confirmComplete', 'rejectComplete'
    const [pendingSubStates, setPendingSubStates] = useReducer(
      (state, newState) => ({ ...state, ...newState }), {}
    );

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
    
    // Always check that the current logged in user is the owner of this product
    useEffect(() => {

      if (getUser === undefined) return;

      if (!isOwner) {

          setRequestType('error-fatal');
          return;

      }

  }, [isOwner, getUser, pendingSubStates]); // pendingSubStates added to force refresh if the state changes


    // Retrieve product and related subscriber information for the provided productid
    useEffect(() => {


    //Check 'productid' exists in url
      if (productId === undefined) return;

      //Check requestType is 'pending'
      if (requestType !== 'pending') return;
        
      //Get product document 
      const getProductPromise = GetDocument('products', productId).catch((error) => {

        throw new Error(`Could not retrieve product information, error: ${error}`);

      });

      //Get 'pending' subscriptions
      const getPendingSubsPromise = QueryDocument('subscriptions', [['productid', '==', productId], ['status', '==', 'pending']]).catch((error) => {

        throw new Error(`Could not retrieve pending subscriptions, error: ${error}`);

      });

      //Get 'cancelled' subscriptions
      const getCancelledSubsPromise = QueryDocument('subscriptions', [['productid', '==', productId], ['status', '==', 'cancelled']]).catch((error) => {

        throw new Error(`Could not retrieve cancelled subscriptions, error: ${error}`);

      });

      //Resolve all promises 
      Promise.all([getProductPromise, getPendingSubsPromise, getCancelledSubsPromise]).then((results) => {

        const [resProduct, resPendingSubs, resCancelledSubs] = results;
        setProduct(resProduct);
        setPendingSubs(resPendingSubs);
        setCancelledSubs(resCancelledSubs);

        if (!Object.keys(resProduct).length) {

          setError(`Product '${productId}'not found`);
          setRequestType('error-fatal');
          return;

        }

        // Set initial idle state for each pending subscription
        let resSubState = {}
        
        for (const sub of resPendingSubs) {

          resSubState[sub.subscriptionid] = 'idle';

        }

        setPendingSubStates(resSubState);
        
        //Load the page
        setRequestType('onload');
        return;

      }).catch((error) => {

        setError(error)
        setRequestType('error-fatal');
        return;

      });
      
    }, [productId, requestType, setRequestType, setError, setProduct, setPendingSubs, setCancelledSubs]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageOnload={
        <div className='CustomerRequests-Body'>

          {/* ==================================== */}
          {/*  Subscription Requests               */}
          {/* ==================================== */}

          <div className="Table-Pane">

            {/* Table Heading */}
            <div className="Table-Title">
              Subscription Requests
              <Tooltip
                helpText="Click 'Complete' to grant your customer access to your product and so you can commence charging them. Click 'Reject' to cancel their request, so they will not be charged."
              ></Tooltip>
            </div>

            {/* Subscriptions Table */}
            {
              pendingSubs.length ? (
                <table className='InteractiveTable-Container'>
                  <thead>
                    <tr>
                      <th>Subscription ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Request Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pendingSubs?.map((sub, index) =>(
                        <CustomerRequestsPendingItem
                          key={index}
                          subscription={sub}
                          states={pendingSubStates}
                          setStates={setPendingSubStates}
                          setRequestType={setRequestType}
                          setError={setError}
                        ></CustomerRequestsPendingItem>
                      ))
                    }
                  </tbody>
                </table>
              )
              :
              (
                <div>
                  No pending subscription requests for this product.
                </div>
              )
            }
            
          </div>


          {/* ==================================== */}
          {/*  Cancellation Requests               */}
          {/* ==================================== */}

          <div className="Table-Pane">

            {/* Table Heading */}
            <div className="Table-Title">
              Cancellation Requests
            </div>
            
            {/* Info Label */}
            <NotificationLabel
              type={"info"}
              message={
                <>
                These customers are ending their subscription. Ensure they are decommissioned from your product after the Subscription End Date.
                Customers will not be charged after the Subscription End Date.
                </>
              }
            ></NotificationLabel>

            {/* Cancellations Table */}
            {
              cancelledSubs.length ? 
              (
                <table className='DataTable-Container'>
                  <thead>
                    <tr>
                      <th>Subscription ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Subscription End Date</th>
                      <th>Cancellation Reason</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {
                        cancelledSubs?.sort((a, b) => b.enddate.toDate() - a.enddate.toDate()).map((sub, index) =>(
                        <tr key={index}>
                          <td>{sub.subscriptionid}</td>
                          <td>{sub.subscriber?.givenname}</td>
                          <td>{sub.subscriber?.surname}</td>
                          <td>{sub.subscriber?.emailaddress}</td>
                          <td>{ConvertDate(sub?.enddate.toDate())}</td>
                          <td>{sub?.cancelreason}</td>
                        </tr>  
                        ))
                    }
                  </tbody>
                </table>
              )
              : 
              (
                <div>
                  No cancellation requests for this product.
                </div>
              )
            }

          </div>

        </div>
      }
    ></PageResponse>
  )

}
