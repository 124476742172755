//================================================================
//  Component: Dropdown
//================================================================

//  Purpose: Creates a dropbown box

//  Properties:
//    - id = "Optional id for the dropdown"
//    - label = "Optional label for the dropdown"
//    - items = {List of items to choose from - either objects or string}
//    - displayProp = "Property to use for display if items are objects"
//    - defaultValue = {Value to select as default - either an object or string}
//    - onChangeFunction = {Function to run when an item is selected}
//    - width = Optional: Width of dropdown

//  Example:
//    In this example, alpha, beta, gamma are objects with a
//    key called 'displayname'

//    <DropDown
//      id='greekletter'
//      label="Select a Greek letter:"
//      items={["alpha", "beta", "gamma"]}
//      displayProp="displayName"
//      defaultValue={"alpha"}
//      onChangeFunction={onDropdownChange}
//      width="200px"
//    ></Dropdown>

//================================================================

//Libraries
import React from "react";

//CSS
import './Dropdown.css';

export default function Dropdown(props) {

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------

  const id = props.id === undefined ? "dropdown" : props.id // ID to use to identify this component
  const label = props.label // Optional label for dropdown
  const items = props.items // Items to populate dropdown
  const displayProp = props.displayProp // For objects, which property to display
  const defaultValue = props.defaultValue // Default value
  const onChangeFunction = props.onChangeFunction // Function to run when a selection is made
  const width = props.width === undefined ? {} : {width: props.width} // Optional width of dropdown

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  >>> getDefaultValue - Check for a match on a particular field
  //  Only applies if items are objects
  //------------------------------------------------------

  function getDefaultValue() {
   return items.findIndex(item => item[displayProp] === defaultValue[displayProp])
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className="Dropdown-Body">
      {
        /* Show label if specified */
        label !== undefined ? (
          <label for="dropdown">
            {label}
          </label>
        ) : (
          <div></div>
        )
      }
      {
        displayProp !== undefined ? (
          /* Create dropdown if items are objects */
          <select style={width} className="Dropdown-Dropdown" value={getDefaultValue()} onChange={onChangeFunction} id={id}>
            {
              items.map((item, index) =>
                <option key={index} value={index}>{item[displayProp]}</option>
              )
            }
          </select>
        ) : (
          /* Create dropdown if items are strings */
          <select style={width} className="Dropdown-Dropdown" value={defaultValue} onChange={onChangeFunction} id={id}>
            {
              items.map((item) =>
                <option>{item}</option>
              )
            }
          </select>
        )
      }
    </div>
  )
}