//================================================================
//  MaintenanceMode page
//  
//  If an ITM Admin changes the field 'maintenancemode' in Firestore ('general/settings/maintenancemode') to 'true'
//  App.js will redirect all routes to this page
//
//================================================================

//Libraries
import React from 'react'

//Contexts

//Components

//Functions

//Images
import ImageLlLogo from '../../Components//Images/Image_LL_Logo_101x72.png';
import ImageMaintenanceMode from '../../Components/Images/Image_MaintenanceMode.svg';

//CSS
import './MaintenanceMode.css'


export default function MaintenanceMode() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='LoginHandler-Container'>
  
        <div className='LoginHandler-Header'>
          <img className='LoginHandler-Header-Logo' src={ImageLlLogo} alt='Lendlease-Logo'></img>
          <h4 style={{color: "#FDFDFD"}}>IT Marketplace</h4>
        </div>

        {/* Info Pane */}
        <div className='LoginHandler-Body'>
          <div className='LoginHandler-Content'>
            <h1 style={{textAlign: 'center'}}>Sorry ... site is under maintenance.</h1>

            {/* Maintenance Graphic */}
            <div>
              <img className='LoginHandler-Graphic' src={ImageMaintenanceMode} alt='Login-Graphic'></img>
            </div>
              
            {/* Login & Getting Started Button */}
            <div className="LoginHandler-Button-Container">
              We'll be back up and running as soon as possible!
            </div>
          </div>
        </div>
        
      </div>
    )
}