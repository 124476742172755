//================================================================
//  Component: Multi Line Dot 
//================================================================

//  Purpose: Creates an array of strings based off the users input

//  Properties:
//    - setState = {setState, that will be append with an array of tags}
//    - setPlaceHolder = {string, place holder string for each new input}
//    - setDefaultValue= <Optional> {array, default values for each label }

//  Example:
//    <MultiLineDot
//      setState={setArrayOfValues}
//      setPlaceHolder={'e.g Design'}
//      setDefaultValue={['value 1', 'value2']}
//    ></MultiLineDot>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';

//Contexts

//Components
import ChildInput from './ChildInput';

//CSS
import './MultiLineDot.css';


export default function MultiLineDot({
  setState,
  setPlaceHolder,
  setDefaultValue
}) {

  //------------------------------------------------------
  //  Ensure the setDefaultValue array is populated
  //------------------------------------------------------

    if (setDefaultValue === undefined || setDefaultValue.length === 0){
      setDefaultValue = [
        ''
      ];
    }

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Manages the state of every tag
    const [values, setValues] = useState(setDefaultValue);

    const [showError, setShowError] = useState(false);
    
  //------------------------------------------------------
  //  functions
  //------------------------------------------------------

    // Adds another tag field > slices a new element into the 'values' array
    function AddTag(index){

      setValues([
        ...values.slice(0, index + 1), 
        '' ,
        ...values.slice(index + 1)
      ]);

    }

    // Removes tag field > Excluding the first item
    function RemoveTag(index){

      // Clear first tag
      if(values.length === 1) return setValues(['']);

      // Remove tag at index
      values.splice(index, 1);
      setValues([...values]);

    }

    //Handles adding values to the 'values' useState
    function BlurHandler(event, index){

      const newValue = event.target.value.trim();
      const existingValue = values.filter((existing) => (existing === newValue)).length

      // Prevent duplicates
      if (newValue !== '' && newValue !== values[index] && existingValue >= 1) return setShowError(true);

      setShowError(false);
      values[index] = newValue;
      setValues([...values]);

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onChange > Save filtered results to parent useState
    useEffect(() =>{

      // Remove any empty tags
      let results = values.filter((tag) => (tag !== ''));

      // Save to parent useState
      setState([...results]);

    }, [values, setState]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div>
      {
        values?.map((tag, index) => (
          <div className='MultiLineDot-Container' key={index}>
            <div className='MultiLineDot-Button' onClick={() => AddTag(index)}>+</div>
            <div className='MultiLineDot-Button' onClick={() => RemoveTag(index)}>-</div>
            <ChildInput
              index={index}
              setDefaultValue={tag}
              setPlaceHolder={setPlaceHolder}
              onBlurHandler={BlurHandler}
            ></ChildInput>
          </div>
        ))
      }
      <div hidden={!showError} className='MultiLineDot-ErrorMessage'>
        * Duplicate tags will be removed
      </div>  
    </div>
  );
}
