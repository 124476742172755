//================================================================
//  Component: Subscribe Form
//================================================================

//  Purpose: This component is used as part of the items page

//  Properties:
//    - setRequestType = {useState, pass in the setRequestType useState}
//    - productObject = {Object, the product object from the products Firestore collection}
//    - setSubRequestId={useState, pass in the setSubRequestId useState}

//  Example:
//    <SubscribeForm
//      setRequestType={setRequestType}
//      productObject={productObject}
//      setSubRequestId={setSubRequestId}
//    ></SubscribeForm>    

//================================================================


//Libraries
import React, { useReducer, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';

//Contexts
import { GetUser, SetError } from '../../Library/GlobalContexts';

//Components
import NotificationLabel from '../../Components/NotificationLabel/NotificationLabel';
import PricingTotal from '../../Components/PricingTotal/PricingTotal';

//Functions
import GetDocument from '../../Library/GetDocument';
import WriteDocument from '../../Library/WriteDocument';
import { reStringUnsignedInteger, reString } from '../../Library/ValidateUserInput';

//Images

//CSS
import './Item.css';


export default function SubscribeForm({
  setRequestType,
  productObject,
  setSubRequestId,
}) {

  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------

    const [disclaimer] = useState('I have discussed the charges associated with this subscription with my manager, and I have their endorsement to proceed with the project/task number/expenditure type that I have entered.');


    // Define what component(s) or function(s) that can raise errors in the submit modal
    const [submitState, setSubmitState] = useState({
      'validated': false,
      'preventSubmit': false,
      'modalVisible': false,
      'firebaseError': undefined,
      'productPricing': undefined,
      'event': undefined,
    });

    //for navigation
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Holds all firestore & form data
    const [data, setData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        projectCode: '',
        projectCodeName: undefined,
        projectCodeError: undefined,
        allowedCurrencies: [
          'AUD', 
          'USD',
        ],
        currency: '',
        currencyError: undefined,

        taskNumber: '',
        taskNumberError: undefined,
        expenditureType: '',
        expenditureTypeError: undefined,

        projectCodes: [],
        taskNumbers: [],
        expenditureTypes: [],
      }
    );

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setError = useContext(SetError);


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function validateForm() {

      // Form touched > Reset errors
      data.projectCodeError = undefined;
      data.currencyError = undefined;
      data.taskNumberError = undefined;
      data.expenditureTypeError = undefined;
      setData({...data});

      submitState.productPricing = undefined;
      submitState.preventSubmit = false;

      if (data.projectCode === '') return;

      // Project code exists > validate form
      GetDocument('oracleProjects', data.projectCode).then((results) => {

        let preventSubmit = false;

        //------------------------------------------------------
        //  1. Validate Project Code
        //------------------------------------------------------

        // a. Check if project code is active
        if (results.active !== true) {

          data.projectCodeError = `Oracle Project Code '${data.projectCode}' provided is not active. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`;
          preventSubmit = true;

        }

        // b. Check for 'projectName' and save it to useReducer 'projectCodeName'
        if ('projectName' in results) {

          data.projectCodeName = results.projectName;

        }

        //------------------------------------------------------
        //  2. Validate Task Numbers
        //------------------------------------------------------

        // a. Check for 'taskNumbers' and save it to useReducer 'taskNumbers'
        if ('taskNumbers' in results) {

          data.taskNumbers = Object.keys(results.taskNumbers);
        }

        // b. Check the users input
        if (data.taskNumber !== '') {

          // Look for the 'data.taskNumber' in 'data.taskNumbers'
          const query = data.taskNumbers.find((tasknumber) => (tasknumber === data.taskNumber));

          // Does it exist?
          if (query !== data.taskNumber){

            data.taskNumberError = `Unknown Task Number '${data.taskNumber}' provided. You may proceed to submit however we recommended checking that your Oracle Task Number is correct.`
            preventSubmit = true;

          } else {

            data.taskNumberError = undefined;

          }

        }

        // b. Check hasn't done anything
        if (data.taskNumber === '') {

          // Look for the '11050' in 'data.taskNumbers'
          const query = data.taskNumbers.find((tasknumber) => (tasknumber === '11050'));
          
          // IF '11050' is a valid task number > set it!
          if (query === '11050') {

            data.taskNumber = '11050';

          }

        }

        //------------------------------------------------------
        //  3. Validate Expenditure Type
        //------------------------------------------------------

        // If taskNumber is '11050' > default to ICT costs
        if (data.taskNumber === '11050' && data.expenditureType === ''){

          data.expenditureType = 'ICT Costs';

        }

        //------------------------------------------------------
        //  4. Save changes to useReducer, parent useState & projectObject
        //------------------------------------------------------

        setData({...data});

        if (preventSubmit === true) {

          submitState.productPricing = `Please understand by continuing, it might not be possible to recharge costs for subscriptions against this product.`;
          submitState.preventSubmit = true;

        } else if (preventSubmit === false) {

          submitState.productPricing = undefined;
          submitState.preventSubmit = false;

        }

        //------------------------------------------------------

      }).catch(() => {

        submitState.productPricing = `Please understand by continuing, it might not be possible to recharge costs for subscriptions against this product.`;
        submitState.preventSubmit = true;

        setData({
          projectCodeName: undefined,
          projectCodeError: `Unknown Oracle Project Code '${data.projectCode}' provided. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`,
          currencyError: undefined,
    
          taskNumberError: undefined,
          expenditureTypeError: undefined,
    
          taskNumbers: [],
          expenditureTypes: [],
        });

      });

    }


  //------------------------------------------------------
  //  Event Handler (Form Submission)
  //------------------------------------------------------

    //Submit Form > Check for duplicates > Write to Firestore
    function handleFormSubmit(e){

      //Prevent reload, propagation & disable enter key
      e.preventDefault();
      e.stopPropagation();
      if (e.keyCode === 13) {

        return;
  
      }

      const documentId = `sub-${Date.now()}`;
      setSubRequestId(documentId);

      //Create flag for preventing submission until form validated
      let preventSubmit = false;

      //Capture submission event
      const event = e.target;

      //Validate form inputs > If invalid -> prevent submission
      if (event.checkValidity() === false) {

        submitState.validated = true;
        setSubmitState({...submitState});
        return;
      }

      if (submitState.preventSubmit === true) {

        submitState.modalVisible = true;
        preventSubmit = true;

      }
    
      // Update 'submitState'
      submitState.validated = true;
      submitState.event = e;
      setSubmitState({...submitState});

      // Prevent submit of form
      if (preventSubmit) return;

      // Define subscription document
      const subscriptionDocument = {
        'subscriptionid': documentId,
        'subscriberprojectcode': e.target.subscriberprojectcode.value ? e.target.subscriberprojectcode.value : 'N/A', //If product free > show 'N/A'
        'subscribertasknumber': e.target.subscribertasknumber.value ? e.target.subscribertasknumber.value : 'N/A',  //If product free > show 'N/A'
        'subscriberexpendituretype': e.target.subscriberexpendituretype.value ? e.target.subscriberexpendituretype.value : 'N/A', //If product free > show 'N/A'
        'pricingdiscount': productObject.pricingdiscount,
        'pricingamount': productObject.pricingamount,
        'pricingcurrency': productObject.pricingcurrency,
        'productid': productObject.productid,
        'createdate': new Date(),
        'subscriber': {
          'userid': getUser.userid,
          'emailaddress': getUser.emailaddress,
          'givenname': getUser.givenname,
          'surname': getUser.surname,
        },
        'status': 'pending'
      }

      // Define disclaimer document
      const disclaimerDocument = {
        'acceptancedatetime': new Date(),
        'disclaimer': disclaimer,
        'userid': getUser.userid,
        'items': {
          'subscription': subscriptionDocument.subscriptionid,
          'product': productObject.productid
        }
      }

      WriteDocument('disclaimeracceptance', `${Date.now()}-${getUser.userid}`, disclaimerDocument, false).then(() =>{

        WriteDocument('subscriptions', subscriptionDocument.subscriptionid, subscriptionDocument, false).then(() =>{
  
          setRequestType('success');
  
        }).catch((error) =>{
  
          submitState.firebaseError = String(error);
          submitState.modalVisible = true;
          setSubmitState({...submitState});

          //Submit error to global store > This will be captured by ErrorHandler
          setError(`Subscribing to a product has failed, error: ${error}`);
  
        });
  
      }).catch((error) =>{

        submitState.firebaseError = String(error);
        submitState.modalVisible = true;
        setSubmitState({...submitState});

        //Submit error to global store > This will be captured by ErrorHandler
        setError(`Recording subscription line manager disclaimer has failed, error: ${error}`);

      });


    };


  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // OnLoad > Retrieve a list of project codes
    useEffect(() => {

      GetDocument('oracleProjects', '+projectCodes').then((results) => {

        if(results?.projectCodes === undefined || !Array.isArray(results.projectCodes)) {

          setError(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore`);
          setRequestType('error-fatal');

        }

        // Save to useReducer
        setData({projectCodes: results.projectCodes});

      }).catch((error) => {

        setError(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore. Error: ${error.message}`);
        setRequestType('error-fatal');

      });

    // eslint-disable-next-line
    }, []);


    // onChange > Validate project code changes
    useEffect(()=> {

      if (data.projectCode === '') return;
      if (data.projectCode.length <= 3) return;

      validateForm();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.projectCode]);


    // onChange > Validate tasknumber code changes
    useEffect(()=> {

      if (data.taskNumber === '') return;

      validateForm();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.taskNumber]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    <div className='Item-SubscribeForm'>

      {/* PTE Form */}
      <div className="SubscribeForm-PTE">

        <Form onSubmit={(e) => handleFormSubmit(e)}>

          {/* Instructions */}
          {/* If product free > Change instructions */}
          {
            productObject.pricingamount === "0" ?
            (<p>This product is free of charge. Click below to submit your subscription request.</p>)
            :
            (<p>Enter your details below to complete your subscription</p>)
          }
          
          
          <div style={productObject.pricingamount === "0" ? {visibility: "hidden"} : null}>

            {/* Project Code */}
            <Form.Group className='mb-3' controlId='subscriberprojectcode'>
              <p className='Item-SubscribeForm-Font'>Project Code</p>
              <Form.Control
                name='projectCode'
                required
                type='text'
                placeholder='e.g. 260018'
                pattern={reStringUnsignedInteger}
                value={data.projectCode}
                onChange={(e) => setData({projectCode: e.target.value})}
                list='subscribeFormProjectCodeList'
                disabled={productObject.pricingamount === "0"}
              ></Form.Control>
              <datalist id='subscribeFormProjectCodeList'>
                {
                  data.projectCodes.map((row, index) => (
                    <option id={index}>{row}</option>
                  ))
                }
              </datalist>
              <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                Please provide a valid project code.
              </Form.Control.Feedback>
                <label className='ProductsEditForm-Oracle-Project-label'>
                  {data.projectCodeName}
                </label>
              </Form.Group>

            {/* Task Number */}
            <Form.Group className='mb-3' controlId='subscribertasknumber'>
              <p className='Item-SubscribeForm-Font'>Task Number</p>
              <Form.Control
                name='taskNumber'
                required
                type='text' 
                placeholder='e.g. 11050'
                pattern={reString}
                value={data.taskNumber}
                onChange={(e) => setData({taskNumber: e.target.value})}
                list='subscribeFormTaskNumberList'
                disabled={productObject.pricingamount === "0"}
              ></Form.Control>
              <datalist id='productsEditTaskNumberList'>
                {
                  data.taskNumbers.map((row, index) => (
                    <option id={index}>{row}</option>
                  ))
                }
              </datalist>
              <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                Please provide a valid task number.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Expenditure Type */}
            <Form.Group className='mb-3' controlId='subscriberexpendituretype'>
              <p className='Item-SubscribeForm-Font'>Expenditure Type</p>
              <Form.Control 
                name='expenditureType'
                required
                type='text' 
                placeholder='e.g. ICT Costs'
                pattern={reString}
                value={data.expenditureType}
                onChange={(e) => setData({expenditureType: e.target.value})}
                disabled={productObject.pricingamount === "0"}
              ></Form.Control>
              <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
                Please provide a valid expenditure type.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Warning Label > Invalid Project code */}
            <NotificationLabel
              hidden={data.projectCodeError === undefined ? (true):(false)}
              type={"warning"}
              message={data.projectCodeError}
            ></NotificationLabel>

            {/* Warning Label > Invalid Tasknumber */}
            <NotificationLabel
              hidden={data.taskNumberError === undefined ? (true):(false)}
              type={"warning"}
              message={data.taskNumberError}
            ></NotificationLabel>

            {/* Line Manager Approval Checkbox */}
            <Form.Group className='mb-3' controlId='linemanageracceptance' style={{marginTop: '30px'}}>
              <Form.Check
                required
                type="checkbox"
                label={disclaimer}
                disabled={productObject.pricingamount === "0"}
              ></Form.Check>
            </Form.Group>
          
          </div>
          
          {/* Buttons */}
          <div>
            <button type='submit' className='Primary-Button'>Submit</button>
            <button className='Secondary-Button' onClick={() => setRequestType('onload')}>Cancel</button>
          </div>

        </Form>
        
      </div>

      {/* Order Summary */}
      <div className="SubscribeForm-OrderSummary">
        <div className='Subheading'>
         Summary
        </div>
        <div className='Subscription-Item'>
          <img className='Subscription-Item-Icon' src={productObject?.iconurl} alt={productObject?.productname}/>
          <div>
            {productObject?.name}
            <p style={{fontSize: "0.85rem", fontWeight: "normal"}}>{productObject?.pricingfrequency} Subscription</p>
          </div>
        </div>
        <div className='Subscription-Total'>
          <PricingTotal
            type='shoppingcart'
            pricingcurrency={productObject?.pricingcurrency}
            pricingdiscount={productObject?.pricingdiscount}
            pricingamount={productObject?.pricingamount}
            pricingchanged={productObject?.pricingchanged}
          ></PricingTotal>
          <PricingTotal
            type='pricechange'
            pricingdiscount={productObject?.pricingdiscount}
            pricingamount={productObject?.pricingamount}
            pricingchanged={productObject?.pricingchanged}
          ></PricingTotal>
        </div>

      </div>


      {/* ================================================================= */}
      {/*                                                                   */}
      {/*                     Submit Handler                                */}
      {/*                                                                   */}
      {/* ================================================================= */}

      {/* Invalid PTE/currency modal */}
      <Modal show={submitState.modalVisible} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            { submitState.firebaseError === undefined ? 'Warning' : 'Error' }
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

              {/*  Firebase errors */}
              <NotificationLabel
                  type={'error'}
                  message={submitState.firebaseError}
                  hidden={submitState.firebaseError === undefined ? (true):(false)}
              ></NotificationLabel>

              {/* Warning Label > Invalid Project code */}
              <NotificationLabel
                  type={'warning'}
                  message={data.projectCodeError}
                  hidden={data.projectCodeError === undefined ? (true):(false)}
              ></NotificationLabel>

              {/* Warning Label > Invalid Tasknumber */}
              <NotificationLabel
                hidden={data.taskNumberError === undefined ? (true):(false)}
                type={"warning"}
                message={data.taskNumberError}
              ></NotificationLabel>

              {/* Toggle between try again and continue */}
              {
                submitState.firebaseError === undefined ? (
                  <>
                    <p>
                      If you wish to continue regardless, click <b>Continue</b>.
                    </p>
                    <p>
                        If you would like to go back without saving your changes, click <b>Cancel</b>.
                    </p>
                  </>
                ):(
                  <p>
                    Subscribing to the product has failed. The product may have been changed.
                    Click <b>OK</b> to return to the home screen. Re-select the product to try again.
                  </p>
                )
              }
          
          </Modal.Body>
          <Modal.Footer>
            {
              submitState.firebaseError === undefined ? (

                <>
                  <button className='Primary-Button' onClick={() =>{

                    submitState.modalVisible = false;
                    submitState.preventSubmit = false;
                    setSubmitState({...submitState});
                    handleFormSubmit(submitState.event);

                  }}>Continue</button>

                  <button className='Secondary-Button' variant="secondary" onClick={() => {

                    submitState.modalVisible = false
                    setSubmitState({...submitState})
                  
                  }}>Cancel</button>
                </>
              
              ):(
                <button className='Primary-Button' onClick={() => navigate('/')}>OK</button>
              )
            }
          </Modal.Footer>
      </Modal>

    </div>
  )
}
