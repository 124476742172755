//================================================================
//  Component: Subscribe Button
//================================================================

//  Purpose: This handles the Subscribe Button in the 'item.js' page

//  Properties:
//    - subscribeStatus = {String, the users subscription status}
//    - productObject = {Object, contains the product information}
//    - setRequestType = {useState, changes the parents requestType}

//  Example:
//    <SubscribeButton
//      subscribeStatus={'pending'}
//      productObject={object}
//      setRequestType={setRequestType}
//    ></SubscribeButton>    

//================================================================


//Libraries
import React from 'react'
import {Link} from 'react-router-dom';

//CSS
import './Item.css'


export default function SubscribeButton(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

    const subscribeStatus = props.subscribeStatus;
    const productObject = props.productObject;
    const setRequestType = props.setRequestType;

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //Subscription request is pending > prevent the creation of another sub
    if(subscribeStatus === 'pending') {
    return (
        <div className='Pending-Text'>
            <div>Click <Link to="/subscriptions?view=pending"> here </Link> to see your pending subscription request.</div>
            <label className='Pending-Label'>PENDING</label>
        </div>
    );
    }

    //Subscription is active
    else if(subscribeStatus === 'active') {

        if (productObject?.producturl?.toLowerCase ()?.startsWith('http://') || productObject?.producturl?.toLowerCase ()?.startsWith('https://')){

            return (
                <a href={productObject?.producturl} target='_blank' rel='noreferrer'>
                    <button className='Primary-Button'>Launch</button>
                </a>
            );
        } else {

            return (
                <a href={`https://${productObject?.producturl}`} target='_blank' rel='noreferrer'>
                    <button className='Primary-Button'>Launch</button>
                </a>
            );

        }

    }

    //Catch all
    else{
    return (
        <button className='Primary-Button' onClick={() => setRequestType('view')}>Subscribe</button>
    );
    }
}
