//================================================================
//  Component: ProductAvailability
//================================================================
//
//  Purpose: Show availability status of a product (public/private), and select members as required
//
//  Properties:
//    - privateEnabled - Boolean indicating whether the product is private or not
//    - privateUsers = Array of private users (useState)
//    - setPrivateUsers = Set useState with updated list of private users
//    - privateUserEmail = Current value of private user email used in input field
//    - setPrivateUserEmail = Set current value of private user email
//    - privateUserNotAdded = validation state used in handleSubmit of function
//    - setPrivateUserNotAdded = sets validatation state 
//
//  Usage:
//    <ProductAvailability
//      privateEnabled=true
//      privateUsers={privateUsers}
//      setPrivateUsers={setPrivateUsers}
//      privateUserEmail={privateUserEmail}
//      setPrivateUserEmail={setPrivateUserEmail}
//      privateUserNotAdded={privateUserNotAdded}
//      setPrivateUserNotAdded={setPrivateUserNotAdded}
//    ></ProductAvailability>      
//
//================================================================

//Libraries
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

//Contexts

//Components
import QuestionLabel from '../../../Components/QuestionLabel/QuestionLabel';

//Functions
import { isStringEmail } from '../../../Library/ValidateUserInput';

//Images
import PrivateIcon from '../../../Components/Images/Icon_PrivateProduct.svg';
import PublicIcon from '../../../Components/Images/Icon_PublicProduct.svg';
import AddButton from '../../../Components/Images/Icon_Add_Teal.svg';
import AddButtonDisabled from '../../../Components/Images/Icon_Add_Grey.svg';
import DeleteIcon from '../../../Components/Images/Icon_Delete_Teal.svg';

//CSS
import './ProductAvailability.css';


export default function ProductAvailability({
  privateEnabled,
  privateUsers,
  setPrivateUsers,
  privateUserEmail,
  setPrivateUserEmail,
  privateUserNotAdded, 
  setPrivateUserNotAdded
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // const [privateUserEmail, setPrivateUserEmail] = useState("");
  const [errorUserListHidden, setErrorUserListHidden] = useState(true);

  // States for email validation > 'notValid', 'existingUser', 'valid'
  const [emailPUValidated, setEmailPUValidated] = useState('notValid');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //Function to add a user to the list of private users
  function addPrivateUser(user) {

    //Validate email address
    user = user.toLowerCase();

    //Check to ensure it's a valid Lendlease email address
    if (isStringEmail(user) && user.includes('@lendlease.com')) {
      setErrorUserListHidden(true);
    } else {
      setErrorUserListHidden(false);
      return;
    };

    // Add to list
    setPrivateUsers(privateUsers => [...privateUsers, user]);

    //Clear input field
    setPrivateUserEmail("");

    setEmailPUValidated("notValid");
    setPrivateUserNotAdded(false);

  }

  function validatePrivateUser(e) {

    e = e.toLowerCase()

    if (privateUsers?.includes(e)) {
      setEmailPUValidated('existingUser')
    } else if (isStringEmail(e) && e.endsWith('@lendlease.com') && (!privateUsers?.includes(e))) {
      setEmailPUValidated('valid')
    } else if (e.endsWith('.com') && !e.endsWith('@lendlease.com')) {
      setEmailPUValidated('notLendleaseEmail')
    } else {
      setEmailPUValidated('notValid')
    }
    setPrivateUserNotAdded(false)
  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <>
      <QuestionLabel
        question='Product availability *'
        helptext="A product can be made public or limited to select subscribers. Note that a product's public/private status cannot be changed."
      ></QuestionLabel>

      <Form.Group className='mb-3' controlId='privateprod'>
        <img
          className='ProductAvailability-Icon-Readonly-Selected'
          hidden={privateEnabled}
          src={PublicIcon}
          alt='icon-public'
        ></img>
        <img
          className='ProductAvailability-Icon-Readonly-Selected'
          hidden={!privateEnabled}
          src={PrivateIcon}
          alt='icon-private'
        ></img>

        <Form.Control.Feedback type="invalid" className='ProductAvailability-Invalid-Message'>
          Please select whether the product is public or private.
        </Form.Control.Feedback>

      </Form.Group>

      {/* Select private users if private product */}
      {
        privateEnabled ? (
          <>
            <div className='ProductAvailability-Field'>Users for private access</div>
            <div className='PublishForm-ProductOwner-Input-Container'>
                    <div 
                    style={{width: '50%'}}
                    >
                        {/* Input Field */}
                        <input
                            className={privateUserNotAdded ? 'PublishForm-Input-Field-Error' : 'PublishForm-Input-Field'}
                            type="text"
                            placeholder="Email address"
                            value={privateUserEmail}
                            onChange={e => {
                                setPrivateUserEmail(e.target.value)
                                validatePrivateUser(e.target.value)
                            }}
                        />

                        {/* Error Message */}
                        {
                            privateUserNotAdded === true || errorUserListHidden === false ?
                            (
                                <div className='PublishForm-ErrorMessage'>
                                    Please enter a valid (@lendlease.com) email address and click the '+' button to confirm.
                                </div>
                            ) : null
                        }

                        {/* Warning Message */}
                        {
                            emailPUValidated === 'valid' ? 
                            (
                                <div className='PublishForm-WarningMessage'>
                                    Click the '+' button to add user
                                </div>                                        
                            ) 
                            :
                            emailPUValidated === 'existingUser' ? 
                            (
                                <div className='PublishForm-WarningMessage'>
                                    This user already exists, please try again
                                </div>                                        
                            )
                            :
                            emailPUValidated === 'notLendleaseEmail' ?
                            (
                                <div className='PublishForm-WarningMessage'>
                                    Please enter a valid (@lendlease.com) email address
                                </div>
                            )
                            :
                            null
                        }
                    </div>
                    
                    {/* Add Button*/}
                    <div>
                        <button style={{border: 'none', backgroundColor: 'white'}} disabled={emailPUValidated !== 'valid' ? true : false} onClick={() => addPrivateUser(privateUserEmail)}>
                            <img className='PublishForm-ProductOwner-Input-Container-Button' alt="Add Button" src={emailPUValidated !== 'valid' ? AddButtonDisabled : AddButton} ></img>
                        </button>
                    </div>
            </div>

            {/* Private user list */}
            <div className='PublishForm-ProductOwner-Container'>
              {
                privateUsers?.map((user, index) =>
                  <div className='PublishForm-ProductOwner-Label-Container' key={index}>
                    <label className='PublishForm-ProductOwner-Label'>{user}</label>
                    <img style={{ cursor: "pointer" }} src={DeleteIcon} alt="Delete Icon" onClick={() => setPrivateUsers((privateUsers) => privateUsers.filter((userEmail) => userEmail !== privateUsers[index]))}></img>
                  </div>
                )
              }
            </div>
          </>
        ) : null
      }
    </>
  )
}


