//==========================================================================
//   Function: Document Listener
//   Description: Creates a Firebase document listener
//   Documentation:
//      https://firebase.google.com/docs/firestore/query-data/listen
//      https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/DocumentSnapshot
//
//
//   //------------------------------------------------------
//   //   How to use this function: 
//   //------------------------------------------------------
//   
//      1. Create a useEffect
//      2. In the useEffect, define an 'onLoad' function, this will run once when the page first loads:
//         
//        function onLoad(document){
//
//          console.log('Getting the current document!', document);
//
//        }
//
//      3. In the useEffect, define an 'onChange' function, this will run each time a change occurs:
//         
//          function onChange(document, snapshot){
//          
//              console.log('Change happened!', document);
//          
//          }
//
//      4. In the useEffect, define an 'onError' function, this will run each time an error occurs:
//  
//          function onError(error){
//          
//              console.log('Something broke, error:', error);
//          
//          }
//
//      5. Import and call 'CollectionListener', note it returns a 'unsubscribe' callback. You WILL need this later!
//
//         const unsubscribe = CollectionListener('products', 'prd-123', onChange, onError);
//
//      6. Finally, we need ensure the document listener is shutdown when the component 'unmounts'
//
//        return () =>{
//  
//          unsubscribe();
//  
//        };
//
//
//     //------------------------------------------------------
//     //   Please see the example below:
//     //------------------------------------------------------
//
//      useEffect(()=>{
    
//        if (productId === undefined) return;
    
//        function onLoad(document){
//          setProductObject(document);
//        }
    
//        function onChange(document, previousValue){
//          if(previousValue === undefined) return;
//          if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);   
//        }
    
//        function onError(error){
//          setRequestType('error-fatal');
//          setError(`Failed to get product information, unable to create the document listener error: ${error}`);  
//        }
    
//        const unsubscribe = CollectionListener('products', productId, onLoad, onChange, onError);
    
//        return () =>{
//          unsubscribe();
//        };
    
//      }, [productId]);
//
//
//------------------------------------------------------

//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore, onSnapshot, collection, getDocs } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';


export default function CollectionListener(collectionId, onLoad, onChange, onError){

  try {

    //Firestore client
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    //Create document reference
    const colRef = collection(db, collectionId);

    //Get the document > call 'onLoad' function
    getDocs(colRef).then((documents) => {

      let array = []

      documents.forEach(doc =>{

        array.push(doc.data());

      });

      onLoad(array);

    }).catch((error) => {

      onError(`CollectionListener has failed to complete, error: ${error}`);

    });

    var previousValue;

    // Invoke document listener > call 'onChange' function
    const unsubscribe = onSnapshot(colRef, (documents) => {

      let array = []

      documents.forEach(doc =>{

        array.push(doc.data());

      });

      onChange(array, previousValue);
      previousValue = array;

    });

    // Return the callback function > Allows the caller to stop listening to changes
    return unsubscribe;

  } catch (error) {

    onError(`CollectionListener has failed to complete, error: ${error}`);

  }

}
