//------------------------------------------------------
//  My Customers Page
//------------------------------------------------------

//Libraries
import React, {useContext, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom';

//Contexts
import { SetError } from '../../Library/GlobalContexts';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import ActiveTable from './ActiveTable'
import InactiveTable from './InactiveTable'

//CSS
import './MyCustomers.css'


export default function MyCustomers() {

  //------------------------------------------------------
  //  UseParams from 'react-router'
  //------------------------------------------------------

    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Handles the pages status > 'pending', 'onload', 'success', 'error-fatal'
    const [requestType, setRequestType] = useState('pending');

    //Hold the product information
    const [productId, setProductId] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Extract 'productid' from url > Load the page
    useEffect(()=>{

      if (searchParams === undefined) return;

      setProductId(searchParams.get('id'));

      setRequestType('onload');

    }, [searchParams, setError]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageResponse
        requestType={requestType}
        pageOnload={
          <div className='MyCustomers-Container'>
            <div className='MyCustomers-Header-Body'>

              {/* Active Customers */}
              <ActiveTable productid={productId}></ActiveTable>

              <br></br>

              {/* Inactive Customers */}
              <InactiveTable productid={productId}></InactiveTable>

            </div>
          </div>
        }
      ></PageResponse>
    );

}
