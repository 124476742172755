//================================================================
//  Component: Product Owner Changes
//  Created by Vincent
//================================================================

//  Purpose: This component shows an audit log of product owner changes

//  Properties:

//  Example:
//    <ProductOwnerChanges
//    ></ProductOwnerChanges>

//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react'
import { CSVLink } from "react-csv";

import QueryDocument from '../../Library/QueryDocument'

//Contexts
import {SetAppErrors} from '../../Library/GlobalContexts';

//Components
import DropDown from '../../Components/Dropdown/Dropdown';
import ConvertDate from '../../Library/ConvertDate';

//CSS
import './Admins.css'


export default function ProductOwnerChanges() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates & useRef
  //------------------------------------------------------

    //Handles the state of the upload > 'onload', 'pending', 'success', 'error-fatal'
    const [viewStatus, setViewStatus] = useState('onload');

    //Set initial date rates to now and one year in the past
    const [startDate, setStartDate] = useState(new Date((new Date()).setFullYear((new Date()).getFullYear() - 1)));
    const [endDate, setEndDate] = useState(new Date());

    //Product list to select from
    const [productList, setProductList] = useState(['-'])

    //Selected product
    const [selectedProduct, setSelectedProduct] = useState('-');

    //Logs from product-owner-change collection
    const [logs, setLogs] = useState([]);

    //Logs filtered by product to display
    const [filteredLogs, setFilteredLogs] = useState([]);

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

  //Reload logs if date range changes
  useEffect(() => {

    //Check we have the required data
    if (startDate === undefined || endDate === undefined) return;

    //Get audit log of project owner changes between the selected dates
    QueryDocument('product-owner-change', 
      [
        ['date', '>=', startDate],
        ['date', '<', endDate]
      ],
      ['date', 'desc']
    )
    
    //Successful
    .then((results) =>{
      
      // No results, so return nothing and reset product filter
      if (results.length <= 0) {
        setProductList(['<All products>'])
        setSelectedProduct('<All products>')
        setLogs([])
        return
      }

      // Save logs
      setLogs(results)

      // Generate list of products for filter dropdown
      const retrievedProducts = ['-', '<All products>', ...new Set(results.map(entry => entry.productname))].sort()
      setProductList(retrievedProducts)
      
      // Selected product doesn't have logs in the date range, so display results for all products
      if (!retrievedProducts.includes(selectedProduct)) {
        setSelectedProduct('-')
      }

    })

    //Error > Show error page
    .catch((error) =>{

      //Submit error to global store > This will be captured by ErrorHandler
      setAppErrors(`QueryDocument for product-owner-change has failed, error: ${error}`)

      //Call failed, so generate error message
      setViewStatus('error-fatal')
      return

    })

    // eslint-disable-next-line
  },[startDate, endDate])


  //Regenerate filtered logs if either logs are refreshed or select product changes
  useEffect(() => {

    //Check we have the required data
    if (startDate === undefined || endDate === undefined || logs === undefined || selectedProduct === undefined) return;
    
    if (selectedProduct === '-') {
      setViewStatus('onload')
      return
    }

    //Also tidy up the output so it's easy to display and correct exports to CSV
    if (selectedProduct === '<All products>') {
      setFilteredLogs(logs.map(row => { return {...row, requester: row.requester.emailaddress, date: ConvertDate(row.date.toDate())}}))
    
    } else {
      setFilteredLogs(logs.filter((entry) => entry.productname === selectedProduct)
        .map(row => { return {...row, requester: row.requester.emailaddress, date: ConvertDate(row.date.toDate())}}))
    }

    if (viewStatus === 'onload') setViewStatus('success')

    // eslint-disable-next-line
  },[logs, selectedProduct])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='ProductOwnerChange-Body'>

      <div className='ProductOwnerChange-FilterBar'>

        {/* Start date to retrieve logs from */}
        <div className='ProductOwnerChange-DatePickerBody'>
          <label>Start date:</label>
          <input
            type="datetime-local"
            step={60}
            className="ProductOwnerChange-DatePicker"
            defaultValue={`${startDate.getFullYear()}-` +
              `${(startDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}-` +
              `${startDate.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})}T` +
              `${startDate.getHours().toLocaleString(undefined, {minimumIntegerDigits: 2})}:` +
              `${startDate.getMinutes().toLocaleString(undefined, {minimumIntegerDigits: 2})}`}
            onBlur={e => setStartDate(new Date(e.target.value))}
            id="startDate"
          ></input>
        </div>

        {/* End date to retrieve logs to */}
        <div className='ProductOwnerChange-DatePickerBody'>
          <label>End date:</label>
          <input
            type="datetime-local"
            step={60}
            className="ProductOwnerChange-DatePicker"
            defaultValue={`${endDate.getFullYear()}-` +
              `${(endDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}-` +
              `${endDate.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})}T` +
              `${endDate.getHours().toLocaleString(undefined, {minimumIntegerDigits: 2})}:` +
              `${endDate.getMinutes().toLocaleString(undefined, {minimumIntegerDigits: 2})}`}
            onBlur={e => setEndDate(new Date(e.target.value))}
            id="endDate"></input>
        </div>

        {/* List of products available between the dates selected to filter by */}
        <DropDown
          id='product'
          label='Product:'
          items={productList}
          defaultValue={selectedProduct}
          onChangeFunction={e => setSelectedProduct(e.target.value)}
        ></DropDown>

        { /* Export to CSV button */
          viewStatus === 'success' &&
          <CSVLink data={filteredLogs} style={{width: 'fit-content'}} headers={[
            { label: 'Date',           key: 'date' },
            { label: 'Product',        key: 'productname' },
            { label: 'Action',         key: 'action' },
            { label: 'Member',         key: 'modifiedmember' },
            { label: 'Terms Accepted', key: 'publisherstandardsaccepted' },
            { label: 'Requester',      key: 'requester' },
          ]}>
            <button className='Primary-Button'>
              Download
            </button>
          </CSVLink>
        }
      </div>

      { /*  onload  */
        viewStatus === 'onload' ? (
          <div>
            Please select a product above.
          </div>
        ) :

        /*  pending  */
        viewStatus === 'pending' ? (
          <div>
            Retrieving logs...
          </div>
        ) :

        /*  success  */
        viewStatus === 'success' ? (
          filteredLogs.length <= 0 ? (
            <div>
              No records found...
            </div>
          ) : (
            <table className='DataTable-Container'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Product</th>
                  <th>Action</th>
                  <th>Member</th>
                  <th>Terms Accepted</th>
                  <th>Requester</th>
                </tr>
                {
                  filteredLogs?.map((entry, index) =>(
                    <tr key={index}>
                      <td>{entry.date}</td>
                      <td>{entry.productname}</td>
                      <td>
                        {
                          entry.action === 'add' ? (
                            <div style={{color: "#6AAA6E"}}>
                              Add
                            </div>
                          ) : (
                            <div style={{color: "#c75606"}}>
                              Remove
                            </div>
                          )
                        }
                      </td>
                      <td>{entry.modifiedmember}</td>
                      <td>
                        {entry.action === 'remove' ? '-' : entry.publisherstandardsaccepted ? (
                          <div style={{color: "#6AAA6E"}}>
                            Yes
                          </div>
                        ) : (
                          <div style={{color: "#c75606"}}>
                            No
                          </div>
                        )}
                      </td>
                      <td>{entry.requester}</td>
                    </tr>  
                  ))
                }
              </thead>
            </table>
          )
        ) :
  
        /*  error-fatal  */
        (
          <div>
            Something went wrong. Please select a product above to try again.
          </div>
        )
      }
    </div>
  )
}
