//------------------------------------------------------
//  The IT Marketplace
//  Product Owner: Cloud Studio
//------------------------------------------------------

//Libraries
import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

//Functions
import DocumentListener from './Library/DocumentListener'

//Contexts
import { GetUser, SetError } from './Library/GlobalContexts';

//Pages
import MaintenanceMode from './Pages/MaintenanceMode/MaintenanceMode';
import Home from './Pages/Home/Home';
import Item from './Pages/Item/Item';
import Publish from './Pages/Publish/Publish';
import MyPublishedProducts from './Pages/MyPublishedProducts/MyPublishedProducts';
import ProductPage from './Pages/MyPublishedProducts/ProductPage';
import MySubscriptions from './Pages/MySubscriptions/MySubscriptions';
import Unsubscribe from './Pages/Unsubscribe/Unsubscribe';
import Admins from './Pages/Admins/Admins'
import NotFound from './Pages/NotFound/notfound';
import LicenseStatistics from './Pages/LicenseStatistics/LicenseStatistics';
import FinancialStatistics from './Pages/FinancialStatistics/FinancialStatistics';

//Components
import GlobalMaintenanceModal from './Components/GlobalMaintenanceModal/GlobalMaintenanceModal';
import Navbar from './Components/Navbar/Navbar';

//Styles
import './App.css'
import './Library/LoginHandler.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";


export default function App() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates and variables
  //------------------------------------------------------

    const navigate = useNavigate();
    const location = useLocation();

    // This useState disables all routes
    const [maintenanceMode, setMaintenanceMode] = useState(false);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // On load > set up doc listener for changes in maintenance mode
    // Users with the 'isAdmin' role ARE NOT AFFECTED!!!
    useEffect(()=>{

      // Gets initial state of the general/settings document
      function onLoadChange(document){

        // Conditions
        if (getUser?.roles?.isAdmin === true) return;
        if (document?.maintenancemode === undefined) return;

        // ITM is under maintenance > redirect user to 'maintenance' page
        if (document?.maintenancemode === true) {

          navigate('/maintenance');
          setMaintenanceMode(true);

        } else {

          // Disable maintenance mode and route user back to home page
          setMaintenanceMode(false);
          if (location.pathname === '/maintenance') return navigate('/home');

        }

      }

      //onError Function > Handle errors when creating document listener
      function onError(error){

        setError(`Failed to create document listener for mainenance mode: ${error}`);

      }

      //Subscribe to document changes
      const unsubscribe = DocumentListener('general', 'settings', onLoadChange, onLoadChange, onError);
        
      //Stop listening for changes when component 'unMounts'
      return () =>{

        unsubscribe();

      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (

      <div className="App-Container">

        {/* Global Modals */}
        <GlobalMaintenanceModal></GlobalMaintenanceModal>    

        {/* Nav */}
        <div className='App-Container-Nav'>
          <Navbar></Navbar>
        </div>

        {/* Body > Contains all pages in the app  */}
        <div className='App-Container-Body'>

          {/* All routes are redirected IF ITM is in 'Maintenance Mode' */}
          <Routes>
            <Route path="/maintenance" element={<MaintenanceMode/>}/>
            <Route path="/" element={ maintenanceMode ? <MaintenanceMode/> : <Home/>}/>
            <Route path="/home" element={ maintenanceMode ? <MaintenanceMode/> : <Home/>}/>
            <Route path="/item" element={ maintenanceMode ? <MaintenanceMode/> : <Item/> }/>
            <Route path="/publish" element={ maintenanceMode ? <MaintenanceMode/> : <Publish/> }/>
            <Route path="/products" element={ maintenanceMode ? <MaintenanceMode/> : <MyPublishedProducts/> }/>
            <Route path="/product" element={ maintenanceMode ? <MaintenanceMode/> : <ProductPage/> }/>
            <Route path="/subscriptions" element={ maintenanceMode ? <MaintenanceMode/> : <MySubscriptions/> }/>
            <Route path="/unsubscribe" element={ maintenanceMode ? <MaintenanceMode/> : <Unsubscribe/> }/>
            <Route path="/admin" element={ maintenanceMode ? <MaintenanceMode/> : <Admins/> }/>
            <Route path="/licensestatistics" element={ maintenanceMode ? <MaintenanceMode/> : <LicenseStatistics/> }/>
            <Route path="/financialstatistics" element={ maintenanceMode ? <MaintenanceMode/> : <FinancialStatistics/> }/>
            <Route path="*" element={ maintenanceMode ? <MaintenanceMode/> : <NotFound /> }/>
          </Routes>
          
        </div>
      </div>
    )

    //------------------------------------------------------
}