//================================================================
//  Convert Date Function
//  Created by Nowshin Hassan 17/08/22
//================================================================

//  Purpose: 
//    1. Function to convert a date object to the correct timezone

//  Example:
//     <td>{ConvertDate(object?.enddate.toDate())}</td>

//================================================================

export default function ConvertDate(date){

    try {
        return date.toLocaleString()

    } catch (error) {
        return
        
    }
     
}

//--------------------------------------------------------