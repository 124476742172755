//================================================================
//  Component: History
//================================================================

//  Purpose: History is a component for My Subscriptions page

//  Properties:

//  Example:
//    <History></History>    

//================================================================


//Libraries
import React, {useContext, useEffect, useState} from 'react'

//Contexts
import {GetUser, SetAppErrors} from '../../../Library/GlobalContexts';

//Components
import Tooltip from '../../../Components/Tooltip/Tooltip';
import PricingTotal from '../../../Components/PricingTotal/PricingTotal';

//Functions
import QueryDocument from '../../../Library/QueryDocument';
import GetDocument from '../../../Library/GetDocument';
import ConvertDate from '../../../Library/ConvertDate';

//CSS
import '../MySubscriptions.css'


export default function History() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Controls the component status > 'pending', 'onload', 'success'
    const [requestType, setRequestType] = useState('pending');

    //Holds the subscription data
    const [subscriptions, setSubscriptions] = useState();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleStatus(status) {

      if(status === 'pending'){
        return (
          <label className='Status-Label' style={{backgroundColor: "#FAEAC3", color: "#A1833D"}}>
            Pending
          </label>
        )
      }

      if(status === 'active'){
        return (
          <label className='Status-Label' style={{backgroundColor: "#DEF8DC", color: "#6AAA6E"}}>
            Active
          </label>
        )
      }

      if(status === 'cancelled'){
        return (
          <label className='Status-Label' style={{backgroundColor: "#F7D6D5", color: "#BF525C"}}>
            Cancelled
          </label>
        )
      }

      if(status === 'rejected'){
        return (
          <label className='Status-Label' style={{backgroundColor: "#fcd7bd", color: "#c75606"}}>
            Rejected
          </label>
        )
      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // This useEffect is designed to query two different collections and handle the results
    // YES this was painful to write as everything including loops is async.... :D :) :| :\ :(
    // Talk with Benno if you need help!
    useEffect(() => {

      //Conditions
      if (requestType !== 'pending') return;
      if (getUser === undefined) return;

      //------------------------------------------------------
      //  1. Get 'subscriptions' for the current user
      //------------------------------------------------------

      const subscriptionsPromise = QueryDocument('subscriptions', [
        ['subscriber.userid', '==', getUser.userid]
      ]
      ).then((subscriptions) =>{

        //------------------------------------------------------
        //  2. Look up each 'product' document for each 'subscription'
        //  - Loop through all subs and get the product information
        //  - I used a map, this allows me to return a the existing array without having to define a new one
        //------------------------------------------------------

        return subscriptions.map((subscription) =>{

          return {
            'productid': subscription.productid,
            'productobject': GetDocument('products', subscription.productid),
            'subscriberexpendituretype': subscription.subscriberexpendituretype,
            'subscriberprojectcode': subscription.subscriberprojectcode,
            'subscribertasknumber': subscription.subscribertasknumber,
            'subscriptionid': subscription.subscriptionid,
            'createdate': ConvertDate(subscription?.createdate?.toDate()),
            'status': subscription.status
          }

        });

      }).catch(() => {

        // Gets around permissions failed due security rule if no records found
        // https://firebase.google.com/docs/firestore/security/rules-query#queries_and_security_rules
        return []

      });


        //------------------------------------------------------
        //  3. Settle all promises
        //  - Settle the subscriptions promise
        //  - Loop through each subscription and handle the product promise
        //------------------------------------------------------

        subscriptionsPromise.then((subscriptions) =>{

          // Promise.all allows us to handle the asynchronous nature of 'map'
          Promise.all(

            subscriptions.map(async(subscription, index) => {

              return Promise.allSettled([subscription.productobject]).then((promises) =>{

                const product = promises[0];

                if (product.status === 'fulfilled') {

                  // Flagged the valid subscription (Found a product document) as 'valid'
                  return {
                    'productid': subscription.productid,
                    'productobject': product.value,
                    'subscriberexpendituretype': subscription.subscriberexpendituretype,
                    'subscriberprojectcode': subscription.subscriberprojectcode,
                    'subscribertasknumber': subscription.subscribertasknumber,
                    'subscriptionid': subscription.subscriptionid,
                    'createdate': subscription.createdate,
                    'status': subscription.status
                  }

                } else {

                  console.log(`Subscription ${subscription.subscriptionid} is referencing Product id ${subscription.productid} that doesn't exist in the products collection!`)
                  setAppErrors(`Subscription ${subscription.subscriptionid} is referencing Product id ${subscription.productid} that doesn't exist in the products collection!`)
                  
                  // Flagged the invalid subscription (Unable to find a product document) as 'invalid'
                  return {
                    'productid': subscription.productid,
                    'productobject': undefined,
                    'subscriberexpendituretype': subscription.subscriberexpendituretype,
                    'subscriberprojectcode': subscription.subscriberprojectcode,
                    'subscribertasknumber': subscription.subscribertasknumber,
                    'subscriptionid': subscription.subscriptionid,
                    'createdate': subscription.createdate,
                    'status': 'invalid'
                  }

                }

              });

            })

          //------------------------------------------------------
          //  4. Finally, all promises are settled!
          //    - Save the data to a useState
          //    - Any missing products
          //------------------------------------------------------

          ).then((subscriptions) => {

            // Filter out invalid results
            const results = subscriptions.filter((subscription) => (subscription.status !== 'invalid'));

            //Check the number of results
            if (results.length > 0){

              setSubscriptions(results);
              setRequestType('success');

            } else {

              setSubscriptions([]);
              setRequestType('onload');
            
            }

          }).catch((error) => {

            console.log(`Unable to get subscription information, error ${error}`)
            setSubscriptions([]);
            setRequestType('error');
    
          });

      }).catch((error) => {

        console.log(`Unable to get subscription information, error ${error}`)
        setSubscriptions([]);
        setRequestType('error');

      });

    }, [getUser, requestType, setAppErrors]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //Loading the table
    if (requestType === 'pending'){
      return (
        <div className='Table-Pane'>
          Loading...
        </div>
      );
    }

    //User doesnt have any active subscriptions
    if (requestType === 'onload'){
      return (
        <div className='Table-Pane'>
          <div className='Table-Title'>
            History
            <Tooltip
              helpText="Below are a list of all your pending, active and cancelled subscriptions."
            ></Tooltip>
          </div>
          No records found
        </div>
      );
    }

    if (requestType === 'error'){
      return (
        <div className='Table-Pane'>
          <div className='Table-Title'>
            Active Subscriptions
            <Tooltip
              helpText="Below are a list of all your current active subscriptions to products in the IT marketplace."
            ></Tooltip>
          </div>
          Failed to retrieve records
      </div>
      )
    }

    //Show all active subscriptions
    if (requestType === 'success'){
      return (
        <div className='Table-Pane'>

          {/* Table Heading */}
          <div className='Table-Title'>
            History
            <Tooltip
              helpText="Below are a list of all your pending, active and cancelled subscriptions."
            ></Tooltip>
          </div>

          {/* Table */}
          <table className='InteractiveTable-Container'>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Subscription</th>
                <th>Cost</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
              {
                subscriptions?.map((object, index) =>(
                <tr key={index}>
                  <td>{object?.productobject?.name}</td>
                  <td>{object?.subscriptionid}</td>
                  <td>
                    <PricingTotal
                      pricingdiscount={object.productobject?.pricingdiscount}
                      pricingamount={object.productobject?.pricingamount}
                      pricingfrequency={object.productobject?.pricingfrequency}
                    ></PricingTotal>
                  </td>
                  <td>{object?.createdate}</td>
                  <td>{handleStatus(object?.status)}</td>
                </tr>  
                ))
              }
            </thead>
          </table>
        </div>
      );
    }

}
