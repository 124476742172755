//================================================================
//  Page: My Subscriptions
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - name = {A string, this is the persons name}
//    - message = {A string, a short message}

//  Example:
//    <MySubscriptions
//      name={"Mark Bennett"}
//      message={"stop breaking stuff!"}
//    ></MySubscriptions>    

//================================================================


//Libraries
import React, {useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import PageHeader from '../../Components/PageHeader/PageHeader';
import TabView from '../../Components/TabView/TabView';
import Pending from './MySubscriptionComponents/Pending'
import Active from './MySubscriptionComponents/Active'
import History from './MySubscriptionComponents/History'

//CSS
import './MySubscriptions.css'


export default function MySubscriptions() {

  //------------------------------------------------------
  //  UseParams from 'react-router'
  //------------------------------------------------------

    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //View > Determines the page view
    const [view, setView] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
  
    //Extract the 'view' from the url > Set page view
    useEffect(() => {

      const urlView = searchParams.get('view')
    
      if (urlView === undefined) return;

      if(urlView === "pending") return setView(1);
      if(urlView === "active") return setView(2);
      if(urlView === "history") return setView(3);

    }, [searchParams])


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={'onload'}
      pageHeader={
        <PageHeader
          title={"My Subscriptions"}
          description={"View a summary of your pending, active and cancelled subscriptions below."}
        ></PageHeader>
      }
      pageOnload={
        <TabView
          defaultView={view}
          oneTitle={"Pending"}
          oneContent={                
            <Pending></Pending>
          }
          twoTitle={"Active"}
          twoContent={     
            <Active></Active>
          }
          threeTitle={"History"}
          threeContent={
            <History></History>
          }
        ></TabView>
      }
    ></PageResponse>
  )
}
