//================================================================
//  Component: PricingTotal
//================================================================

//  Purpose: Handles the pricing model field, that works with bootstrap

//  Properties:
//    - pricingdiscount = {String, the 'pricingdiscount' from firestore}
//    - pricingamount = {String, the 'pricingamount' from firestore}

//    <OPTIONAL>
//    - pricingchanged = {object, 'pricingchanged' from the 'product' document in firestore}
//    - type = {String, changes the styling 'shoppingcart', 'pricechange', 'home' or undefined is the default}
//    - pricingcurrency = {String, currency for 'pricingamount'}
//    - pricingfrequency = {String, frequency for 'pricingamount'}

//  Example:
//    <PricingTotal
//      pricingdiscount={'100'}
//      pricingamount={'100'}
//      pricingchanged = {pricingchanged}
//      type={'shoppingcart'}
//      pricingcurrency={'AUD'}
//      pricingfrequency={'Monthly'}
//    ></PricingTotal>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';

//Contexts

//Components
import NotificationLabel from '../NotificationLabel/NotificationLabel';
import Tooltip from '../Tooltip/Tooltip';

//Functions

//Images

//CSS
import './PricingTotal.css'


export default function PricingTotal({
  pricingdiscount,
  pricingamount,
  pricingchanged,
  type,
  pricingcurrency,
  pricingfrequency,
}) {

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Current price
    const [pricingAmount, setPricingAmount] = useState();
    const [totalDiscount, setTotalDiscount] = useState();
    const [totalPrice, setTotalPrice] = useState();

    // Future price
    const [futureTotalDiscount, setFutureTotalDiscount] = useState();
    const [futureTotalPrice, setFutureTotalPrice] = useState();

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad > Calulate the current price
    useEffect(() => {

      // Conditions, we only support string values from firestore
      if (pricingamount === undefined || pricingamount === '') return;
      if (typeof(pricingamount) !== 'string') return;

      const initalPrice = parseFloat(pricingamount);
      setTotalPrice(parseFloat(pricingamount).toFixed(2));
      setPricingAmount(parseFloat(pricingamount).toFixed(2));

      // IF we have a 'pricingdiscount', proceed to calulate the new price
      if (pricingdiscount === undefined || pricingdiscount === '') return;
      if (typeof(pricingdiscount) !== 'string') return;

      const initalDiscount = parseInt(pricingdiscount);

      // (percentage / 100) * totalPrice = discount
      const discount = (initalDiscount / 100) * initalPrice;
      setTotalDiscount((discount).toFixed(2));

      // price - discounted amount = total price
      setTotalPrice((initalPrice - discount).toFixed(2));

    }, [pricingdiscount, pricingamount]);


    // onLoad > Calulate the future price
    useEffect(() => {

      // Conditions, we only support string values from firestore
      if (pricingchanged?.pricingamount === undefined || pricingchanged?.pricingamount === '') return;
      if (pricingchanged?.pricingdiscount === undefined || pricingchanged?.pricingdiscount === '') return;
      if (typeof(pricingchanged?.pricingamount) !== 'string') return;
      if (typeof(pricingchanged?.pricingdiscount) !== 'string') return;

      // 1. Calculate price
      const initalPrice = parseFloat(pricingchanged.pricingamount).toFixed(2);
      setFutureTotalPrice(initalPrice);

      // 2. Calculate discount
      const initalDiscount = parseInt(pricingchanged?.pricingdiscount);

      // (percentage / 100) * totalPrice = discount
      const discount = (initalDiscount / 100) * initalPrice;
      setFutureTotalDiscount((discount).toFixed(2));

      // price - discounted amount = total price
      setFutureTotalPrice((initalPrice - discount).toFixed(2));

    }, [pricingamount, pricingchanged, pricingdiscount]);


  //------------------------------------------------------
  //  Shopping cart styling
  //------------------------------------------------------

    if (type === 'shoppingcart') {

      return (
        <div className='PricingTotal-ShoppingCart'>

          <div className='PricingTotal-ShoppingCart-Item'>
            <div>Sub Total</div>
            <div>${pricingAmount}</div>
          </div>

          <div hidden={totalPrice === pricingAmount ? (true):(false)} className='PricingTotal-ShoppingCart-Item'>
            <div>Discount</div>
            <div>-${totalDiscount}</div>
          </div>

          <div style={{marginTop: '20px'}} className='PricingTotal-ShoppingCart-Item'>
            <div>Total</div>
            <div>{pricingcurrency} ${totalPrice}</div>
          </div>

          <div style={{marginTop: '20px'}}>
            <NotificationLabel
              hidden={totalPrice === pricingAmount ? (true):(false)}
              message={
                <>
                  This product is set to a <strong>showback pricing model</strong>. You will not be charged for the product but will still be sent invoices. 
                </>
              }
            ></NotificationLabel>
          </div>

        </div>
      )

    }

  //------------------------------------------------------
  //  Price change warning
  //------------------------------------------------------

    else if (type === 'pricechange') {

      return (
        <NotificationLabel
          hidden={futureTotalPrice === undefined ? (true):(false)}
          type='warning'
          message={
            <>
              The cost of this product is expected to change on {pricingchanged?.effectivedate}-01.
              <br></br><br></br>
              The new cost is {pricingchanged?.pricingcurrency} ${futureTotalPrice}, with a discount of ${futureTotalDiscount}.
            </>
          }
        ></NotificationLabel>
      )

    }

  //------------------------------------------------------
  //  home.js
  //------------------------------------------------------

    else if (type === 'home') {
      return (
        <div className='PricingTotal-Container'>

          {
            totalPrice === pricingAmount ? (

              // Standard price
              <>
                ${totalPrice} {pricingcurrency} {pricingfrequency}
              </>

            ):(

              // Discounted/Showback price
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <div>
                  ${totalPrice} {pricingcurrency} {pricingfrequency}
                </div>
                <div style={{textDecoration: 'line-through', color: '#959292'}}>
                  ${pricingAmount} {pricingcurrency}
                </div> 
              </div>
            )
          }

        </div>
      )
    }

  //------------------------------------------------------
  //  Default > item.js, etc
  //------------------------------------------------------

    else {
      return (
        <div className='PricingTotal-Container'>

          {
            totalPrice === pricingAmount ? (

              // Standard price
              <>
                ${totalPrice} {pricingcurrency} {pricingfrequency}
              </>

            ):(

              // Discounted/Showback price
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{textDecoration: 'line-through', color: '#959292', marginRight: '8px'}}>
                  {pricingcurrency} ${pricingAmount}
                </div>
                <div>
                  ${totalPrice} {pricingcurrency} {pricingfrequency}
                </div>
              </div>
            )
          }

          {/* Price change warning */}
          <Tooltip
            hidden={futureTotalPrice === undefined ? (true):(false)}
            helpText={`The cost of this product is expected to change on ${pricingchanged?.effectivedate}-01. The new cost is ${pricingchanged?.pricingcurrency} $${futureTotalPrice}, with a discount of $${futureTotalDiscount}.`}
            type='warning'
          ></Tooltip>

        </div>
      )
    }

}
