//================================================================
//  Component: Pending
//================================================================

//  Purpose: Pending is a component for My Subscriptions page

//  Properties:

//  Example:
//    <Pending></Pending>    

//================================================================

//Libraries
import React, {useContext, useEffect, useState} from 'react'

//Contexts
import {GetUser, SetAppErrors} from '../../../Library/GlobalContexts'

//Functions
import QueryDocument from '../../../Library/QueryDocument';
import GetDocument from '../../../Library/GetDocument';
import ConvertDate from '../../../Library/ConvertDate';

//Components
import PendingTableRow from './PendingTableRow';
import Tooltip from '../../../Components/Tooltip/Tooltip';

//CSS
import '../MySubscriptions.css'


export default function Pending() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Controls the component status > 'pending', 'onload', 'success'
    const [requestType, setRequestType] = useState('pending');

    //Holds the subscription data
    const [subscriptions, setSubscriptions] = useState([]);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // This useEffect is designed to query two different collections and handle the results
    // YES this was painful to write as everything including loops is async.... :D :) :| :\ :(
    // Talk with Benno if you need help!
    useEffect(() => {

      //Conditions
      if (requestType !== 'pending') return;
      if (getUser === undefined) return;

      //------------------------------------------------------
      //  1. Get 'subscriptions' for the current user
      //------------------------------------------------------

      const subscriptionsPromise = QueryDocument('subscriptions', [
        ['subscriber.userid', '==', getUser.userid],
        ['status', '==', 'pending']
      ]
      ).then((subscriptions) =>{

        //------------------------------------------------------
        //  2. Look up each 'product' document for each 'subscription'
        //  - Loop through all subs and get the product information
        //  - I used a map, this allows me to return a the existing array without having to define a new one
        //------------------------------------------------------

        return subscriptions.map((subscription) =>{

          return {
            'productid': subscription.productid,
            'productobject': GetDocument('products', subscription.productid),
            'subscriberexpendituretype': subscription.subscriberexpendituretype,
            'subscriberprojectcode': subscription.subscriberprojectcode,
            'subscribertasknumber': subscription.subscribertasknumber,
            'subscriptionid': subscription.subscriptionid,
            'startdate': ConvertDate(subscription?.startdate?.toDate()),
          }

        });

      }).catch(() => {

        // Gets around permissions failed due security rule if no records found
        // https://firebase.google.com/docs/firestore/security/rules-query#queries_and_security_rules
        return []

      });


        //------------------------------------------------------
        //  3. Settle all promises
        //  - Settle the subscriptions promise
        //  - Loop through each subscription and handle the product promise
        //------------------------------------------------------

        subscriptionsPromise.then((subscriptions) =>{

          // Promise.all allows us to handle the asynchronous nature of 'map'
          Promise.all(

            subscriptions.map(async(subscription, index) => {

              return Promise.allSettled([subscription.productobject]).then((promises) =>{

                const product = promises[0];

                if (product.status === 'fulfilled') {

                  // Flagged the valid subscription (Found a product document) as 'valid'
                  return {
                    'productid': subscription.productid,
                    'productobject': product.value,
                    'subscriberexpendituretype': subscription.subscriberexpendituretype,
                    'subscriberprojectcode': subscription.subscriberprojectcode,
                    'subscribertasknumber': subscription.subscribertasknumber,
                    'subscriptionid': subscription.subscriptionid,
                    'startdate': subscription.startdate,
                    'status': 'valid'
                  }

                } else {

                  console.log(`Subscription ${subscription.subscriptionid} is referencing Product id ${subscription.productid} that doesn't exist in the products collection!`)
                  setAppErrors(`Subscription ${subscription.subscriptionid} is referencing Product id ${subscription.productid} that doesn't exist in the products collection!`)
                  
                  // Flagged the invalid subscription (Unable to find a product document) as 'invalid'
                  return {
                    'productid': subscription.productid,
                    'productobject': undefined,
                    'subscriberexpendituretype': subscription.subscriberexpendituretype,
                    'subscriberprojectcode': subscription.subscriberprojectcode,
                    'subscribertasknumber': subscription.subscribertasknumber,
                    'subscriptionid': subscription.subscriptionid,
                    'startdate': subscription.startdate,
                    'status': 'invalid'
                  }

                }

              });

            })

          //------------------------------------------------------
          //  4. Finally, all promises are settled!
          //    - Save the data to a useState
          //    - Any missing products
          //------------------------------------------------------

          ).then((subscriptions) => {

            // Filter out invalid results
            const results = subscriptions.filter((subscription) => (subscription.status === 'valid'));

            //Check the number of results
            if (results.length > 0){

              setSubscriptions(results);
              setRequestType('success');

            } else {

              setSubscriptions([]);
              setRequestType('onload');
            
            }

          }).catch((error) => {

            console.log(`Unable to get subscription information, error ${error}`)
            setSubscriptions([]);
            setRequestType('error');
    
          });

      }).catch((error) => {

        console.log(`Unable to get subscription information, error ${error}`)
        setSubscriptions([]);
        setRequestType('error');

      });

    }, [getUser, requestType, setAppErrors]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  //User doesnt have any active subscriptions
  if (requestType === 'pending'){
    return (
      <div className='Table-Pane'>
        <div className='Table-Title'>
          Pending Subscriptions
          <Tooltip
            helpText="The below subscription requests are all pending approval from the product publisher."
          ></Tooltip>
        </div>
          Loading...
      </div>
    );
  }

  //User doesnt have any active subscriptions
  if (requestType === 'onload'){
    return (
      <div className='Table-Pane'>
        <div className='Table-Title'>
          Pending Subscriptions
          <Tooltip
            helpText="The below subscription requests are all pending approval from the product publisher."
          ></Tooltip>
        </div>
          No records found
      </div>
    );
  }

  if (requestType === 'error'){
    return (
      <div className='Table-Pane'>
        <div className='Table-Title'>
          Pending Subscriptions
          <Tooltip
            helpText="The below subscription requests are all pending approval from the product publisher."
          ></Tooltip>
        </div>
        Failed to retrieve records
    </div>
    )
  }

  //Show all active subscriptions
  if (requestType === 'success'){

    return (
      <div className='Table-Pane'>

        {/* Table Heading */}
        <div className='Table-Title'>
          Pending Subscriptions
          <Tooltip
            helpText="The below subscription requests are all pending approval from the product publisher."
          ></Tooltip>
        </div>

        {/* Pending Subscriptions */}
        <table className='ExpandableTable-Container'>
          <colgroup>
            <col span="1" style={{width: "5%"}}></col>
            <col span="1" style={{width: "15%"}}></col>
            <col span="1" style={{width: "20%"}}></col>
            <col span="1" style={{width: "40%"}}></col>
            <col span="1" style={{width: "10%"}}></col>
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Product Name</th>
              <th>Subscription ID</th>
              <th>Create Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {
            subscriptions.map((object, index) =>(
              <PendingTableRow
                key={index}
                rowData={object}
              ></PendingTableRow>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }

}
