//================================================================
//  Component: Admins Page
//================================================================

//  Purpose: Allows admins to:
//           - Assign roles (in future)
//           - Upload content to the resources folder
//           - Audit product owner changes

//  Properties:

//  Admins:
//    <Admins></Admins>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageResponse from '../../Components/PageResponse/PageResponse';
import ProductOwnerChanges from './ProductOwnerChanges';
import PageHeader from '../../Components/PageHeader/PageHeader';

//Functions

//Images

//CSS
import './Admins.css';


export default function Admins() {

  //------------------------------------------------------
  //  Hooks
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [requestType, setRequestType] = useState('pending');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Checks people are admins
    useEffect(()=>{

      //Conditions
      if( getUser === undefined ) return;
      if ( getUser?.roles?.isAdmin === undefined) return navigate('*');

      //Allow the admin to see the onload page
      setRequestType('onload');

    }, [getUser, navigate]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageResponse
      requestType={requestType}
      pageHeader={
        <PageHeader
          title={"Welcome to the Admins Page"}
          description={"Admins of the team that LOVES manbuns, cats and more...."}
        ></PageHeader> 
      }
      pageOnload={
        <div className='Admins-Body'>
          <div className="Table-Pane">
            <div className='Table-Title'>
              Product owner changes
            </div>
            <ProductOwnerChanges></ProductOwnerChanges>
          </div>
        </div>
      }
    ></PageResponse>
  )
}
