//================================================================
//  Component: Question Label
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - question = {A string, the question}
//    - helptext = {A string, the tool tip}

//  Example:
//    <QuestionLabel
//      question={"First name?"}
//      helptext={"enter the persons first name"}
//    ></QuestionLabel>    

//================================================================


//Libraries
import React, {useState} from 'react'

//Images
import InfoIcon from '../Images/Icon_i_Tooltip.svg'

//CSS
import './QuestionLabel.css'


export default function QuestionLabel({
  question,
  helptext,
  style,
  hidden
}) {

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    const [showToolTipText, setShowToolTipText] = useState(true);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Hides the tooltip if no value is passed in
    function toggleHelpTextVisible(){

      if (helptext === undefined){

        return true;

      } else {

        return false;

      }

    }
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Hide the component
  if (hidden) return null

  // Show the component
  else {
    return (
      <div className="QuestionLabel-Container">
        <label style={style} className="QuestionLabel-Label">{question}</label>
        <div hidden={toggleHelpTextVisible()} className="QuestionLabel-Tooltip">
          <img 
            className="QuestionLabel-Help-Icon" 
            alt="help-icon" 
            src={InfoIcon}
            onPointerEnter={() => setShowToolTipText(false)} 
            onPointerLeave={() => setShowToolTipText(true)} 
          ></img>
          <span hidden={showToolTipText} className="QuestionLabel-Tooltip-Text"> {helptext}</span>
        </div> 
      </div>
    )
  }

}
