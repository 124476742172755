//------------------------------------------------------
//  Customer Requests Pending Subscription Item
//------------------------------------------------------

//Libraries
import React, { useState } from 'react';

//Functions
import WriteDocument from '../../Library/WriteDocument';
import ConvertDate from '../../Library/ConvertDate';
import { isString, errString } from '../../Library/ValidateUserInput';

//CSS
import './CustomerRequests.css';


export default function CustomerRequestsPendingItem(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

  const sub = props.subscription;
  const states = props.states;
  const setStates = props.setStates;
  const setRequestType = props.setRequestType;
  const setError = props.setError;

  //------------------------------------------------------
  //  useStates & global variables
  //------------------------------------------------------

  const subid = sub.subscriptionid;
  const createDate = ConvertDate(sub?.createdate.toDate())

  //Reject text and text error state for subscription
  const [rejectText, setRejectText] = useState('');
  const [rejectError, setRejectError] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Sets the request state and perform actions based on the button the user clicked on
  function buttonClick(newState) {

    if (newState === 'confirmPending' || newState === 'rejectPending') {

      // If the user has clicked 'Complete' or 'Reject', reset state of any pending requests
      // on other subscriptions as user can only have one going at a time
      for (const sub in states) {

        if (sub !== subid && (states[sub] === 'confirmPending' || states[sub] === 'rejectPending')){

          setStates({ [sub]: 'idle' });
        
        }
      
      }
    
    } else if (newState === 'confirmComplete') {

      // Subscription complete, so update subscriptions collection to reflect this
      const doc = {
        'status': 'active',
        'startdate': new Date(),
      };

      // TODO: Then statement to send email
      WriteDocument('subscriptions', subid, doc, true).catch((error) => {
        setError(`Could not update completion in subscriptions collection for ${subid}, error: ${error}`);
        setRequestType('error-fatal');
      })


    } else if (newState === 'rejectComplete') {

      // Check reject text is valid
      if(updateRejectText(rejectText)) {
        return;
      }

      // Subscription rejected, so update subscriptions collection to reflect this
      const doc = {
        'status': 'rejected',
        'rejectdate': new Date(),
        'rejectreason': rejectText,
      };

      // TODO: Then statement to send email
      WriteDocument('subscriptions', subid, doc, true).catch((error) => {

        setError(`Could not update rejection in subscriptions collection for ${subid}, error: ${error}`);
        setRequestType('error-fatal');

      });
   
    }

    setStates({ [subid]: newState });

  }

  // Update reject text and check if there is a formatting error
  function updateRejectText(input) {

    setRejectText(input);

    if (input.length === 0 || isString(input)) {
      setRejectError(false);
      return false;
    }

    setRejectError(true);
    return true;

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (states[subid] === 'confirmPending') {

    return (
      <tr className='CustomerRequests-ActiveRow'>
        <td valign='middle'>{subid}</td>
        <td valign='middle'>{sub.subscriber?.givenname}</td>
        <td valign='middle'>{sub.subscriber?.surname}</td>
        <td valign='middle'>{sub.subscriber?.emailaddress}</td>
        <td valign='middle'>{createDate}</td>
        <td valign='middle' colSpan='2'>
          <div className='CustomerRequests-ButtonGroup'>
            <button className="CustomerRequests-ButtonComplete" onClick={() => buttonClick('confirmComplete')}>Confirm</button>
            <button className="CustomerRequests-ButtonReject" onClick={() => buttonClick('idle')}>Cancel</button>
          </div>
        </td>
      </tr>
    )

    } else if (states[subid] === 'rejectPending') {

      return (
        <tr className='CustomerRequests-ActiveRow'>
          <td valign='middle'>{subid}</td>
          <td valign='middle' colSpan='4'>
            <div>
              <input className={rejectError ? 'CustomerRequests-RejectError' : 'CustomerRequests-RejectMessage'}
                type='text'
                onChange={evt => updateRejectText(evt.target.value)}
                placeholder='Optional message to customer'
              ></input>
              <div hidden={!rejectError} className='PublishForm-ErrorMessage'>
                  Reject message must contain a combination of {errString}
              </div>
            </div>
          </td>
          <td valign='middle' colSpan='2'>
            <div className='CustomerRequests-ButtonGroup'>
              <button className="CustomerRequests-ButtonComplete" onClick={() => buttonClick('rejectComplete')}>Submit</button>
              <button className="CustomerRequests-ButtonReject" onClick={() => buttonClick('idle')}>Cancel</button>
            </div>
          </td>
        </tr>
      )
  
    } else if (states[subid] === 'confirmComplete') {

      return (
        <tr>
        <td valign='middle'>{subid}</td>
        <td valign='middle'>{sub.subscriber?.givenname}</td>
        <td valign='middle'>{sub.subscriber?.surname}</td>
        <td valign='middle'>{sub.subscriber?.emailaddress}</td>
        <td valign='middle'>{createDate}</td>
        <td className='CustomerRequests-CompleteText'>Request Completed</td>
        </tr>
      )
  
    } else if (states[subid] === 'rejectComplete') {

      return (
        <tr>
        <td valign='middle'>{subid}</td>
        <td valign='middle'>{sub.subscriber?.givenname}</td>
        <td valign='middle'>{sub.subscriber?.surname}</td>
        <td valign='middle'>{sub.subscriber?.emailaddress}</td>
        <td valign='middle'>{createDate}</td>
        <td className='CustomerRequests-RejectText'>Request Rejected</td>
        </tr>
      )
  
    } else {

    return (
      <tr>
        <td valign='middle'>{subid}</td>
        <td valign='middle'>{sub.subscriber?.givenname}</td>
        <td valign='middle'>{sub.subscriber?.surname}</td>
        <td valign='middle'>{sub.subscriber?.emailaddress}</td>
        <td valign='middle'>{createDate}</td>
        <td valign='middle'>
          <div className='CustomerRequests-ButtonGroup'>
            <button className="CustomerRequests-ButtonComplete" onClick={() => buttonClick('confirmPending')}>Complete</button>
            <button className="CustomerRequests-ButtonReject" onClick={() => buttonClick('rejectPending')}>Reject</button>
          </div>
        </td>
      </tr>
    )

  }

}
