//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for ITM

//  Properties:
//    - name = {A string, this is the persons name}
//    - message = {A string, a short message}

//  Example:
//    <Example
//      name={"Mark Bennett"}
//      message={"stop breaking stuff!"}
//    ></Example>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";

//Contexts
import {GetUser, NavUpdate} from '../../Library/GlobalContexts'

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument'

//Images
import ITMLogo from '../Images/Icon_ITMLogo.svg'
import MarketplaceLogo from '../Images/Icon_Marketplace_White.svg'
import MySubscriptions from '../Images/Icon_MySubscriptions_White.svg'
import PublishProduct from '../Images/Icon_PublishProduct_White.svg'
import PublishedProducts from '../Images/Icon_PublishedProducts_White.svg'
import LicenseUse from '../Images/Icon_LicenseUse_White.svg'
import FinancialStatistics from '../Images/Icon_FinancialStatistics_White.svg'
import Support from '../Images/Icon_Support_White.svg'

//CSS
import './Navbar.css'


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const navUpdate = useContext(NavUpdate);
    
  //------------------------------------------------------
  //  useStates & hooks
  //------------------------------------------------------

    //State used to determine current view on this page > "pending", "success"
    const [componentStatus, setComponentStatus] = useState('pending');

    //Controls the visibility of 'My Published Projects'
    const [isPublisher, setisPublisher] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    //Holds the current route
    const location = useLocation();

    //Hold the nav item styles
    const [marketplaceStyle, setMarketplaceStyle] = useState('Navbar-Items-Container-Link');
    const [mySubStyle, setMySubStyle] = useState('Navbar-Items-Container-Link');
    const [publishStyle, setPublishStyle] = useState('Navbar-Items-Container-Link');
    const [myProductsStyle, setMyProductsStyle] = useState('Navbar-Items-Container-Link');
    const [licenseStatsStyle, setLicenseStatsStyle] = useState('Navbar-Items-Container-Link');
    const [financialStatsStyle, setFinancialStatsStyle] = useState('Navbar-Items-Container-Link');

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Checks if the user is an admin or publisher > E.g. Be able to view 'My Published Products'
    useEffect(() =>{

      if (getUser === undefined) return;

      setComponentStatus('success')
      setIsAdmin(getUser?.roles?.isAdmin)

      QueryDocument('products', [
        ['publisher', 'array-contains', getUser.emailaddress],
        ['status', 'in', ['published', 'hidden']]
      ]).then((results) =>{

        if (results.length > 0){
          setisPublisher(true)
        }
      
      }).catch((error) =>{
        //We choose to ignore this error
        console.log('Failed to get users published products', error)
      });
      
    }, [getUser, navUpdate])

    //Check the current route > update highlighted button
    useEffect(() =>{

      if (location.pathname === '/home' || location.pathname === '/'){

        setMarketplaceStyle('Navbar-Items-Container-Link-Active');
        setMySubStyle('Navbar-Items-Container-Link');
        setPublishStyle('Navbar-Items-Container-Link');
        setMyProductsStyle('Navbar-Items-Container-Link');
        setLicenseStatsStyle('Navbar-Items-Container-Link');
        setFinancialStatsStyle('Navbar-Items-Container-Link');

      }

      else if (location.pathname === '/subscriptions'){
  
        setMarketplaceStyle('Navbar-Items-Container-Link');
        setMySubStyle('Navbar-Items-Container-Link-Active');
        setPublishStyle('Navbar-Items-Container-Link');
        setMyProductsStyle('Navbar-Items-Container-Link');
        setLicenseStatsStyle('Navbar-Items-Container-Link');
        setFinancialStatsStyle('Navbar-Items-Container-Link');

      }
      else if (location.pathname === '/publish'){

        setMarketplaceStyle('Navbar-Items-Container-Link');
        setMySubStyle('Navbar-Items-Container-Link');
        setPublishStyle('Navbar-Items-Container-Link-Active');
        setMyProductsStyle('Navbar-Items-Container-Link');
        setLicenseStatsStyle('Navbar-Items-Container-Link');
        setFinancialStatsStyle('Navbar-Items-Container-Link');

      }
      else if (location.pathname === '/products'){

        setMarketplaceStyle('Navbar-Items-Container-Link');
        setMySubStyle('Navbar-Items-Container-Link');
        setPublishStyle('Navbar-Items-Container-Link');
        setMyProductsStyle('Navbar-Items-Container-Link-Active');
        setLicenseStatsStyle('Navbar-Items-Container-Link');
        setFinancialStatsStyle('Navbar-Items-Container-Link');

      }
      else if (location.pathname === '/licensestatistics'){

        setMarketplaceStyle('Navbar-Items-Container-Link');
        setMySubStyle('Navbar-Items-Container-Link');
        setPublishStyle('Navbar-Items-Container-Link');
        setMyProductsStyle('Navbar-Items-Container-Link');
        setLicenseStatsStyle('Navbar-Items-Container-Link-Active');
        setFinancialStatsStyle('Navbar-Items-Container-Link');

      }
      else if (location.pathname === '/financialstatistics'){

        setMarketplaceStyle('Navbar-Items-Container-Link');
        setMySubStyle('Navbar-Items-Container-Link');
        setPublishStyle('Navbar-Items-Container-Link');
        setMyProductsStyle('Navbar-Items-Container-Link');
        setLicenseStatsStyle('Navbar-Items-Container-Link');
        setFinancialStatsStyle('Navbar-Items-Container-Link-Active');

      } else {

        setMarketplaceStyle('Navbar-Items-Container-Link');
        setMySubStyle('Navbar-Items-Container-Link');
        setPublishStyle('Navbar-Items-Container-Link');
        setMyProductsStyle('Navbar-Items-Container-Link');
        setLicenseStatsStyle('Navbar-Items-Container-Link');

      }

    }, [location])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  'Pending' Status
    //------------------------------------------------------
    
      if(componentStatus === 'pending'){
        return (
          <div className='Navbar-Container'>
      
            {/* Logo */}
            <div className='Navbar-Logo'>
              <Link to="/">
                <img src={ITMLogo} alt="ITM Logo"></img>         
              </Link>
              <Link className='Navbar-Link' to="/">
                <b>IT</b> MARKETPLACE
              </Link>
            </div>

            {/* Sidebar Items */}
            <div className='Navbar-Items-Container'>
              Loading...
            </div>
      
          </div>
        )
      }

    //------------------------------------------------------
    //  'Success' Status
    //------------------------------------------------------

      else if (componentStatus === 'success'){
          return (
            <div className='Navbar-Container'>
        
              {/* Title & Logo */}
              <div className='Navbar-Logo'>
                <Link to="/">
                  <img src={ITMLogo} alt="ITM Logo"></img>         
                </Link>
                <Link className='Navbar-Link' to="/">
                  <b>IT</b> MARKETPLACE
                </Link>
              </div>
              
              {/* Sidebar Items */}
              <div className='Navbar-Items-Container'>

                {/* Marketplace */}
                <Link className={marketplaceStyle} to="/home">
                  <img className='Navbar-Item-Icon' src={MarketplaceLogo} alt="Marketplace Icon"></img>
                  Marketplace
                </Link>

                {/* My Subscriptions */}
                <Link className={mySubStyle} to="/subscriptions?view=pending">
                  <img className='Navbar-Item-Icon' src={MySubscriptions} alt="My Subscriptions Icon"></img>
                  My Subscriptions
                </Link>

                {/* Publish a Product */}
                <Link className={publishStyle} to="/publish">
                  <img className='Navbar-Item-Icon' src={PublishProduct} alt="Publish a Product Icon"></img>
                  Publish a Product
                </Link>

                {/* My Published Products */}
                <Link hidden={!isPublisher && !isAdmin} className={myProductsStyle} to="/products">
                  <img className='Navbar-Item-Icon' src={PublishedProducts} alt="My Published Products Icon"></img>
                  My Published Products
                </Link>

                {/* License Use */}
                <Link hidden={!isPublisher} className={licenseStatsStyle} to="/licensestatistics">
                  <img className='Navbar-Item-Icon' src={LicenseUse} alt="License Use Icon"></img>
                  License Use
                </Link>

                {/* Financial Statistics */}
                <Link hidden={!isPublisher} className={financialStatsStyle} to="/financialstatistics">
                  <img className='Navbar-Item-Icon' src={FinancialStatistics} alt="Financial Statistics Icon"></img>
                  Financial Statistics
                </Link>

                {/* Support */}
                <a className="Navbar-Items-Container-Link" href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noreferrer">
                  <img className='Navbar-Item-Icon' src={Support} alt="Support Icon"></img>
                  Support
                </a>
              </div>
            </div>
          )
      }

}
