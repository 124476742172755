//================================================================
//  Component: Terms and Conditions
//================================================================

//  Purpose: This is the market place publish Page
//  * This is a componenet to allow two async calls for storage content

//  Properties:
//    - None

//  Example:
//    <TermsConditions></TermsConditions>    

//================================================================


//Libraries
import React, {useState, useEffect, useContext} from 'react';

//Contexts
import {SetError} from '../../Library/GlobalContexts'

//Functions
import DownloadFile from '../../Library/DownloadFile'

//Components
import ErrorHandler from '../../Components/ErrorHandler/ErrorHandler'

//Images
import LoadingIcon from '../../Components/Images/Image_Loading_Ripple.svg'

//Styles
import './Publish.css'


export default function TermsConditions() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setError = useContext(SetError);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //API States > 'pending', 'success', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');
    const [content, setContent] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(()=>{

      //Get content content
      DownloadFile('resources/PublisherTermsandConditions.pdf').then((results) =>{

        setContent(results);
        setPageStatus('success');

      }).catch((error) =>{

        setError(error);
        setPageStatus('error-fatal');

      });

    }, [setError]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Pending
    //------------------------------------------------------

      if (pageStatus === 'pending'){
        return(
          <div className='TermsConditions-Container'>
            <img alt="loading-circle-icon" src={LoadingIcon}></img>
          </div>
        );
      }

    //------------------------------------------------------
    //  Success
    //------------------------------------------------------

      else if (pageStatus === 'success'){
        return(
          <div className='TermsConditions-Container'>
            <object title='ITM-Terms-And-Conditions' type="application/pdf" data={content}></object>
          </div>
        );
      }

    //------------------------------------------------------
    //  Error-Fatal
    //------------------------------------------------------

      else if (pageStatus === 'error-fatal'){
        return(
          <ErrorHandler requestType='error-fatal'></ErrorHandler>
        );
      };

  //------------------------------------------------------

}
